import { useContext, useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import AccountContext from '../../context/private/accountContext';
import PrivateContext from '../../context/private/privateContext';
import { Container, Flex } from '../../styles/Styles';
import Steps from './steps/steps';
import { Steps2 } from './steps/steps';
import Header from '../Header';
import Layout from '../layout/Index';
import { toast } from 'react-hot-toast';
import dictionary from '../../locals/my-account.json';
import settingService from '../../services/settingService';
import WAValidator from 'multicoin-address-validator';
import { AccountId } from '@hashgraph/sdk';

type Account = {
  id: number;
  user_id: number;
  account_name: string;
  account_number: string;
  recipient_address: string;
  bank_name: string;
  bank_address: string;
  institution_number: string;
  swift_code: string;
  iban: string;
  type: number;
  updated_at: string;
};

const FinancialAccountItem = ({
  account,
  selectedItem,
  setSelectedItem
}: {
  account: Account;
  selectedItem: number;
  setSelectedItem: (value: number) => void;
}) => {
  const {
    setDefaultAccountHandle,
    deleteAccountHandle,
    modalDelete,
    setModalDelete,
    setModalEdit,
    editableData,
    setEditableData,
    modalSelect,
    setModalSelect
  } = useContext(AccountContext);

  if (account.type === 1) {
    return (
      <div style={{ position: 'relative' }}>
        <div
          className={
            account.id === selectedItem
              ? 'prof-right prof-active'
              : 'prof-right'
          }
          onClick={() => {
            setModalSelect(true);
            setSelectedItem(account.id);
          }}
        >
          {/* <Flex x="between" y="center"> */}
          <div className="prof-active-header">
            <div className="prof-active-name">{account.account_name}</div>
            <div className="prof-active-add">{account.recipient_address}</div>
          </div>

          <div className="prof-active-bottom">
            <div className="prof-active-bttm">
              <div className="prof-active-type">
                {dictionary['MY-ACCOUNT-title63']}:
              </div>
              <div className="prof-active-type-name">
                {dictionary['MY-ACCOUNT-title64']}
              </div>
            </div>
            <img
              src="/images/purseLogo.svg"
              alt="purseLogo"
              className="prof-active-img"
            />
          </div>

          {/* </Flex> */}
        </div>

        <Flex
          gap={0.5}
          marginB={0.4}
          style={{
            width: 'auto',
            height: 'auto',
            top: 16,
            right: 18,
            position: 'absolute'
          }}
        >
          <button
            className="financial-account-edit-btn"
            onClick={() => {
              setModalEdit(true);
              setSelectedItem(account.id);
              setEditableData(account);
            }}
          >
            {dictionary['MY-ACCOUNT-title65']}
          </button>
          <button
            className="financial-account-delete-btn"
            onClick={() => {
              setModalDelete(true);
              setSelectedItem(account.id);
            }}
          >
            {dictionary['MY-ACCOUNT-title66']}
          </button>
        </Flex>
      </div>
    );
  }

  if (account.type === 0) {
    return (
      <div style={{ position: 'relative' }}>
        <div
          className={
            account.id === selectedItem
              ? 'prof-right prof-active'
              : 'prof-right'
          }
          onClick={() => {
            setModalSelect(true);
            setSelectedItem(account.id);
          }}
        >
          {/* <Flex x="between" y="center"> */}
          <div className="flex flex-col h-full justify-between">
            <div className="prof-active-header">
              <div className="prof-active-name">{account.account_name}</div>
              <div className="prof-active-add">{account.account_number}</div>
            </div>

            <div className="prof-active-bottom">
              <div className="prof-active-bottom-right">
                <div className="prof-right-body">
                  <div className="prof-right-type">
                    {dictionary['MY-ACCOUNT-title63']}:
                  </div>
                  <div className="prof-right-ans">
                    {dictionary['MY-ACCOUNT-title67']}
                  </div>
                </div>
                <div className="prof-right-body">
                  <div className="prof-right-type">
                    {dictionary['MY-ACCOUNT-title68']}:
                  </div>
                  <div className="prof-right-ans">{account.bank_name}</div>
                </div>
              </div>
              <img
                src="/images/bankLogo.svg"
                alt="bankLogo"
                className="prof-active-img"
              />
            </div>
          </div>
          {/* </Flex> */}
        </div>

        <Flex
          gap={0.5}
          marginB={0.4}
          style={{
            width: 'auto',
            height: 'auto',
            top: 16,
            right: 18,
            position: 'absolute'
          }}
        >
          <button
            className="financial-account-edit-btn"
            onClick={() => {
              setModalEdit(true);
              setSelectedItem(account.id);
              setEditableData(account);
            }}
          >
            {dictionary['MY-ACCOUNT-title65']}
          </button>
          <button
            className="financial-account-delete-btn"
            onClick={() => {
              setModalDelete(true);
              setSelectedItem(account.id);
            }}
          >
            {dictionary['MY-ACCOUNT-title66']}
          </button>
        </Flex>
      </div>
    );
  }

  return <></>;
};

const FinancialAccount = (): JSX.Element => {
  const { flowState } = useContext(PrivateContext);
  const navigate = useNavigate();

  const [selectedItem, setSelectedItem] = useState(0);
  const [currentStep, setCurrentStep] = useState(1);
  const [currentStepTronWallet, setCurrentStepTronWallet] = useState(1);
  const [currentStepMingoWallet, setCurrentStepMingoWallet] = useState(1);

  const {
    modal,
    setModal,
    modalTronWallet,
    modalMingoWallet,
    setModalTronWallet,
    setModalMingoWallet,
    modalSelect,
    setModalSelect,
    setDefaultAccountHandle,
    wireFormData,
    setWireFormData,
    ptiWalletFormData,
    setPtiWalletFormData,
    cryptoFormData,
    setCryptoFormData,
    createMingoAccountHandle,
    mingoWalletFormData,
    setMingoWalletFormData,
    createWireAccountHandle,
    createCryptoAccountHandle,
    createPTIAccountHandle,
    loadAccounts,
    allAccounts,
    modalDelete,
    setModalDelete,
    deleteAccountHandle,
    modalEdit,
    setModalEdit,
    setEditableData,
    editableData,
    editAccountHandle,
    userInfo
  } = useContext(AccountContext);
  const { is2faShow, check2FaStatus, setUpdateTimer } =
    useContext(PrivateContext);

  useEffect(() => {
    loadAccounts();

    check2FaStatus();
    window.scrollTo(0, 0);
  }, []);

  function validateHederaAddressWithSDK(address) {
    try {
      AccountId?.fromString(address);
      return true;
    } catch (error) {
      return false;
    }
  }

  const [accountType, setAccountType] = useState('1');
  const [walletType, setWalletType] = useState('');
  const [ultimoType, setUltimoType] = useState('');
  const [deleteToken, setDeleteToken] = useState('');

  useEffect(() => {
    setUpdateTimer(+new Date());
    window.scrollTo(0, 0);
  }, [modal, modalDelete, modalSelect, modalEdit]);

  const [ckeditorContent, setCkeditorContent] = useState(null);
  async function loadCkEditorByID() {
    await settingService
      .getCkEditorById(19)
      .then((data) => setCkeditorContent(data));
  }

  useEffect(() => {
    loadCkEditorByID();
  }, []);

  if (flowState?.step === 17)
    return (
      <>
        {modal && (
          <Flex x="center">
            <div className="mod">
              <div className="mod-border">
                <div className="mod-box">
                  <p className="mod-box-p">
                    {dictionary['MY-ACCOUNT-title69']}
                  </p>
                  <button
                    className="mod-box-btn"
                    onClick={() => setModal(false)}
                  >
                    X
                  </button>
                </div>
                <div style={{ marginRight: 10, marginLeft: 10 }}>
                  <Steps current={currentStep} />
                </div>
                <div className="mod-inside">
                  <div className="mod-inside-body">
                    {/* step 1 */}
                    {currentStep === 1 && (
                      <>
                        <label className="mod-inside-body-label">
                          {dictionary['MY-ACCOUNT-title63']}
                        </label>
                        <select
                          className="mod-inside-body-select"
                          onChange={(e) => {
                            setAccountType(e.target.value);
                          }}
                        >
                          <option value={1}>
                            {dictionary['MY-ACCOUNT-title70']}
                          </option>
                          <option value={0}>
                            {dictionary['MY-ACCOUNT-title71']}
                          </option>
                        </select>
                      </>
                    )}

                    {/* step 2 */}
                    {currentStep === 2 && (
                      <>
                        <label className="mod-inside-body-label">
                          {dictionary['MY-ACCOUNT-title72']}
                        </label>
                        <input
                          type="text"
                          placeholder="Name"
                          className="mod-inside-body-input"
                          onChange={(e) => {
                            setWireFormData({
                              ...wireFormData,
                              account_name: e.target.value
                            });
                            setCryptoFormData({
                              ...cryptoFormData,
                              account_name: e.target.value
                            });
                          }}
                        />

                        {accountType === '1' && (
                          <p
                            style={{
                              color: '#E74545',
                              fontSize: 13,
                              textAlign: 'left'
                            }}
                          >
                            <b>{dictionary['MY-ACCOUNT-title73']}:</b>{' '}
                            {dictionary['MY-ACCOUNT-title74']}
                          </p>
                        )}
                      </>
                    )}

                    {/* step 3 */}
                    {currentStep === 3 && accountType === '1' && (
                      <>
                        <select
                          className="mod-inside-body-select"
                          style={{ marginBottom: '1rem' }}
                          onChange={(e) => {
                            setWalletType(String(e.target.value));
                            setCryptoFormData({
                              ...cryptoFormData,
                              is_ultimo: e.target.value
                            });
                            e.target.value === '' &&
                              setCryptoFormData({
                                ...cryptoFormData,
                                ultimo_token: ''
                              });
                          }}
                        >
                          <option value={''} selected>
                            {dictionary['MY-ACCOUNT-title113']}
                          </option>
                          <option value={1}>
                            {dictionary['MY-ACCOUNT-title109']}
                          </option>
                          <option value={0}>
                            {dictionary['MY-ACCOUNT-title110']}
                          </option>
                        </select>

                        {Number(walletType) == 1 && (
                          <select
                            className="mod-inside-body-select"
                            style={{ marginBottom: '1rem' }}
                            onChange={(e) => {
                              setUltimoType(String(e.target.value));
                              setCryptoFormData({
                                ...cryptoFormData,
                                ultimo_token: e.target.value
                              });
                            }}
                          >
                            <option value={''} selected>
                              {dictionary['MY-ACCOUNT-title114']}
                            </option>
                            <option value={1}>
                              {dictionary['MY-ACCOUNT-title111']}
                            </option>
                            <option value={0}>
                              {dictionary['MY-ACCOUNT-title112']}
                            </option>
                          </select>
                        )}
                        <br />
                        <input
                          type="text"
                          placeholder="Recipient address"
                          className="mod-inside-body-input"
                          onChange={(e) =>
                            setCryptoFormData({
                              ...cryptoFormData,
                              recipient_address: e.target.value
                            })
                          }
                        />
                        {is2faShow === 1 && (
                          <>
                            <label
                              className="mod-inside-body-label"
                              style={{ marginTop: '15px' }}
                            >
                              {dictionary['MY-ACCOUNT-title5']}
                            </label>
                            <input
                              type="number"
                              placeholder="code"
                              className="mod-inside-body-input"
                              value={cryptoFormData?.token}
                              onChange={(e) =>
                                e.target.value.length <= 6 &&
                                setCryptoFormData({
                                  ...cryptoFormData,
                                  token: e.target.value
                                })
                              }
                            />
                          </>
                        )}
                        {accountType === '1' && (
                          <p
                            style={{
                              color: '#E74545',
                              fontSize: 13,
                              textAlign: 'left'
                            }}
                          >
                            <b>{dictionary['MY-ACCOUNT-title73']}:</b>{' '}
                            {dictionary['MY-ACCOUNT-title74']}
                          </p>
                        )}
                      </>
                    )}
                    {currentStep === 3 && accountType === '0' && (
                      <>
                        <label className="mod-inside-body-label">
                          {dictionary['MY-ACCOUNT-title75']}
                        </label>
                        <input
                          type="text"
                          placeholder="Account holder name"
                          className="mod-inside-body-input"
                          onChange={(e) =>
                            setWireFormData({
                              ...wireFormData,
                              account_holder_name: e.target.value
                            })
                          }
                        />
                        <input
                          type="text"
                          placeholder="Recipient address"
                          className="mod-inside-body-input"
                          onChange={(e) =>
                            setWireFormData({
                              ...wireFormData,
                              recipient_address: e.target.value
                            })
                          }
                        />
                        <label
                          className="mod-inside-body-label"
                          style={{ marginTop: '15px' }}
                        >
                          {dictionary['MY-ACCOUNT-title43']}
                        </label>
                        <input
                          type="text"
                          placeholder="Bank Name"
                          className="mod-inside-body-input"
                          onChange={(e) =>
                            setWireFormData({
                              ...wireFormData,
                              bank_name: e.target.value
                            })
                          }
                        />
                        <input
                          type="text"
                          placeholder="Bank Address"
                          className="mod-inside-body-input"
                          onChange={(e) =>
                            setWireFormData({
                              ...wireFormData,
                              bank_address: e.target.value
                            })
                          }
                        />
                        <input
                          type="text"
                          placeholder="Institution number"
                          className="mod-inside-body-input"
                          onChange={(e) =>
                            setWireFormData({
                              ...wireFormData,
                              institution_number: e.target.value
                            })
                          }
                        />
                        <input
                          type="number"
                          placeholder="Account number"
                          className="mod-inside-body-input"
                          onChange={(e) =>
                            setWireFormData({
                              ...wireFormData,
                              account_number: e.target.value
                            })
                          }
                        />
                        <input
                          type="text"
                          placeholder="Swift code"
                          className="mod-inside-body-input"
                          onChange={(e) =>
                            setWireFormData({
                              ...wireFormData,
                              swift_code: e.target.value
                            })
                          }
                        />
                        <input
                          type="text"
                          placeholder="IBAN"
                          className="mod-inside-body-input"
                          onChange={(e) =>
                            setWireFormData({
                              ...wireFormData,
                              iban: e.target.value
                            })
                          }
                        />

                        {is2faShow === 1 && (
                          <>
                            <label
                              className="mod-inside-body-label"
                              style={{ marginTop: '15px' }}
                            >
                              {dictionary['MY-ACCOUNT-title5']}
                            </label>
                            <input
                              type="number"
                              placeholder="code"
                              className="mod-inside-body-input"
                              value={wireFormData?.token}
                              onChange={(e) =>
                                e.target.value.length <= 6 &&
                                setWireFormData({
                                  ...wireFormData,
                                  token: e.target.value
                                })
                              }
                            />
                          </>
                        )}
                      </>
                    )}
                  </div>
                  {is2faShow === 1 && (
                    <button
                      className="mod-inside-body-btn pointer"
                      onClick={() => {
                        if (currentStep === 3) {
                          if (accountType === '1') {
                            if (cryptoFormData.token) {
                              if (
                                cryptoFormData?.recipient_address?.trim()
                                  ?.length > 41
                              ) {
                                if (
                                  cryptoFormData?.is_ultimo?.trim()?.length > 0
                                ) {
                                  if (
                                    cryptoFormData?.ultimo_token?.trim()
                                      ?.length > 0 ||
                                    cryptoFormData?.is_ultimo == 0
                                  ) {
                                    createCryptoAccountHandle();
                                  } else {
                                    toast.error(
                                      dictionary['MY-ACCOUNT-title116']
                                    );
                                  }
                                } else {
                                  toast.error(
                                    dictionary['MY-ACCOUNT-title115']
                                  );
                                }
                              } else {
                                toast.error(dictionary['MY-ACCOUNT-title76']);
                              }
                            } else {
                              toast.error(dictionary['MY-ACCOUNT-title77']);
                            }
                          } else if (accountType === '0') {
                            if (wireFormData.token) {
                              createWireAccountHandle();
                            } else {
                              toast.error(dictionary['MY-ACCOUNT-title77']);
                            }
                          }
                        } else if (currentStep === 2) {
                          accountType === '1' &&
                            cryptoFormData.account_name.trim().length > 0 &&
                            setCurrentStep(currentStep + 1);
                          accountType === '0' &&
                            wireFormData.account_name.trim().length > 0 &&
                            setCurrentStep(currentStep + 1);
                        } else {
                          setCurrentStep(currentStep + 1);
                        }
                      }}
                    >
                      {currentStep === 3
                        ? dictionary['MY-ACCOUNT-title78']
                        : dictionary['MY-ACCOUNT-title79']}
                    </button>
                  )}

                  {is2faShow === 0 && (
                    <div
                      className="warning-message-deposit"
                      style={{ marginTop: '1rem' }}
                    >
                      <p>{dictionary['MY-ACCOUNT-title80']}</p>
                      <p>
                        <b>{dictionary['MY-ACCOUNT-title81']}</b> {'->'}{' '}
                        <Link to="/Profile">
                          <b className="link">
                            {dictionary['MY-ACCOUNT-title82']}
                          </b>
                        </Link>
                      </p>
                    </div>
                  )}
                </div>
              </div>

              <div className="blur-bg" onClick={() => setModal(false)}></div>
            </div>
          </Flex>
        )}

        {/* tron wallet */}
        {modalTronWallet && (
          <Flex x="center">
            <div className="mod">
              <div className="mod-border">
                <div className="mod-box">
                  <p className="mod-box-p">
                    {dictionary['MY-ACCOUNT-title69-1']}
                  </p>
                  <button
                    className="mod-box-btn"
                    onClick={() => setModalTronWallet(false)}
                  >
                    X
                  </button>
                </div>
                <div className="mx-10">
                  <Steps2 current={currentStepTronWallet} />
                </div>
                <div className="mod-inside">
                  <div className="mod-inside-body">
                    {/* step 1 */}
                    {currentStepTronWallet === 1 && (
                      <>
                        <div className="text-[13px] text-left text-gray-700">
                          <p className="mb-4">
                            You are eligible to receive PTI tokens through our
                            distribution program. To participate, you MUST set
                            up a TRON wallet. This wallet is EXCLUSIVELY for
                            receiving PTI token distributions and should NOT be
                            used for any other purpose.
                          </p>
                        </div>
                        <div className="text-[13px] text-left mt-4">
                          <b className="mr-2 text-red-600">ATTENTION:</b>
                          <ul className="list-disc pl-5 space-y-2">
                            <li>
                              This wallet is ONLY for receiving PTI token
                              distributions.
                            </li>
                            <li>
                              Do NOT use this wallet for regular transactions or
                              withdrawals.
                            </li>
                            <li>
                              You MUST provide a valid TRON wallet address to
                              receive your tokens.
                            </li>
                          </ul>

                          <p className="mt-3">
                            For step-by-step instructions on creating a TRON
                            wallet, please refer to our detailed tutorials at:{' '}
                            <a
                              className="text-blue-600 hover:text-blue-800 underline"
                              href="https://mybos.blueoceansociety.club/crypto-tutorials"
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              https://mybos.blueoceansociety.club/crypto-tutorials
                            </a>
                          </p>
                        </div>
                        <div className="text-[13px] text-left mt-4 bg-yellow-100 p-3 rounded">
                          <b className="mr-2 text-yellow-700">IMPORTANT:</b>
                          <span className="text-yellow-800">
                            We will verify your TRON wallet address. Incorrect
                            addresses will forfeit your PTI tokens. This wallet
                            is for PTI token distribution ONLY - not for
                            withdrawals/deposits from Main Balance. It cannot be
                            adjusted later. If you've made a mistake, contact
                            support immediately. Double-check your address to
                            secure your tokens!
                          </span>
                        </div>
                        <div className="text-[13px] text-left mt-4 bg-blue-100 p-3 rounded">
                          <b className="mr-2 text-blue-700">Remember:</b>
                          <span className="text-blue-800">
                            This TRON wallet will not be available as a
                            withdrawals/deposits option for your main balance.
                            It is solely for the purpose of PTI token
                            distribution.
                          </span>
                        </div>
                        <p className="mt-4 text-gray-600 italic">
                          If you have any questions or need assistance, please
                          contact our support team.
                        </p>{' '}
                      </>
                    )}

                    {/* step 2 */}
                    {currentStepTronWallet === 2 && (
                      <>
                        <label className="mod-inside-body-label">
                          {dictionary['MY-ACCOUNT-title72']}
                        </label>
                        <input
                          type="text"
                          placeholder="Name"
                          className="mod-inside-body-input"
                          value={ptiWalletFormData.account_name}
                          onChange={(e) => {
                            setPtiWalletFormData({
                              ...ptiWalletFormData,
                              account_name: e.target.value
                            });
                          }}
                        />

                        <div className="text-[13px] text-left mt-4 bg-yellow-100 p-3 rounded">
                          <b className="mr-2 text-yellow-700">IMPORTANT:</b>
                          <span className="text-yellow-800">
                            BOS only accepts TRON wallet addresses for PTI token
                            distribution. This wallet is EXCLUSIVELY for
                            receiving PTI tokens. You CANNOT use this wallet for
                            deposits, withdrawals, or any other transactions.
                            Ensure you provide a valid TRON wallet address to
                            receive your PTI tokens.
                          </span>
                        </div>
                      </>
                    )}

                    {/* step 3 */}
                    {currentStepTronWallet === 3 && (
                      <>
                        <br />
                        <input
                          type="text"
                          placeholder="Recipient address"
                          className="mod-inside-body-input"
                          onChange={(e) =>
                            setPtiWalletFormData({
                              ...ptiWalletFormData,
                              recipient_address: e.target.value
                            })
                          }
                        />
                        {is2faShow === 1 && (
                          <>
                            <label
                              className="mod-inside-body-label"
                              style={{ marginTop: '15px' }}
                            >
                              {dictionary['MY-ACCOUNT-title5']}
                            </label>
                            <input
                              type="number"
                              placeholder="code"
                              className="mod-inside-body-input"
                              value={ptiWalletFormData?.token}
                              onChange={(e) =>
                                e.target.value.length <= 6 &&
                                setPtiWalletFormData({
                                  ...ptiWalletFormData,
                                  token: e.target.value
                                })
                              }
                            />
                          </>
                        )}
                        <div className="text-[13px] text-left mt-4 bg-yellow-100 p-3 rounded">
                          <b className="mr-2 text-yellow-700">IMPORTANT:</b>
                          <span className="text-yellow-800">
                            BOS only accepts TRON wallet addresses for PTI token
                            distribution. This wallet is EXCLUSIVELY for
                            receiving PTI tokens. You CANNOT use this wallet for
                            deposits, withdrawals, or any other transactions.
                            Ensure you provide a valid TRON wallet address to
                            receive your PTI tokens.
                          </span>
                        </div>
                      </>
                    )}
                  </div>
                  {is2faShow === 1 && (
                    <button
                      className="mod-inside-body-btn pointer"
                      onClick={() => {
                        if (currentStepTronWallet === 3) {
                          if (ptiWalletFormData.token) {
                            const currency = WAValidator.validate(
                              ptiWalletFormData?.recipient_address?.trim(),
                              'trx'
                            );
                            if (currency) {
                              createPTIAccountHandle();
                            } else {
                              toast.error(dictionary['MY-ACCOUNT-title76-1']);
                            }
                          } else {
                            toast.error(dictionary['MY-ACCOUNT-title77']);
                          }
                        } else if (currentStepTronWallet === 2) {
                          ptiWalletFormData.account_name.trim().length > 0 &&
                            setCurrentStepTronWallet(currentStepTronWallet + 1);
                        } else {
                          setCurrentStepTronWallet(currentStepTronWallet + 1);
                        }
                      }}
                    >
                      {currentStepTronWallet === 3
                        ? dictionary['MY-ACCOUNT-title78']
                        : dictionary['MY-ACCOUNT-title79']}
                    </button>
                  )}

                  {is2faShow === 0 && (
                    <div
                      className="warning-message-deposit"
                      style={{ marginTop: '1rem' }}
                    >
                      <p>{dictionary['MY-ACCOUNT-title80']}</p>
                      <p>
                        <b>{dictionary['MY-ACCOUNT-title81']}</b> {'->'}{' '}
                        <Link to="/Profile">
                          <b className="link">
                            {dictionary['MY-ACCOUNT-title82']}
                          </b>
                        </Link>
                      </p>
                    </div>
                  )}
                </div>
              </div>

              <div
                className="blur-bg"
                onClick={() => setModalTronWallet(false)}
              ></div>
            </div>
          </Flex>
        )}
        {modalMingoWallet && (
          <Flex x="center">
            <div className="mod">
              <div className="mod-border">
                <div className="mod-box">
                  <p className="mod-box-p">
                    <b className="mr-2">IMPORTANT:</b>
                    <span className="font-normal">
                      {dictionary['MY-ACCOUNT-title69-2']}
                    </span>
                  </p>
                  <button
                    className="mod-box-btn"
                    onClick={() => setModalMingoWallet(false)}
                  >
                    X
                  </button>
                </div>
                <div className="mx-10">
                  <Steps2 current={currentStepMingoWallet} />
                </div>
                <div className="mod-inside">
                  <div className="mod-inside-body">
                    {/* step 1 */}
                    {currentStepMingoWallet === 1 && (
                      <>
                        <div className="text-[13px] text-left text-gray-700">
                          <p className="mb-4">
                            You are eligible to receive Mingo tokens through our
                            distribution program. To participate, you MUST set
                            up a Hedera (HBAR) wallet. This wallet is
                            EXCLUSIVELY for receiving Mingo tokens and should
                            NOT be used for any other purpose.
                          </p>
                        </div>
                        <div className="text-[13px] text-left mt-4">
                          <b className="mr-2 text-red-600">ATTENTION:</b>
                          <ul className="list-disc pl-5 space-y-2">
                            <li>
                              This wallet is ONLY for receiving Mingo token
                              distributions.
                            </li>
                            <li>
                              Do NOT use this wallet for regular transactions or
                              withdrawals.
                            </li>
                            <li>
                              You MUST provide a valid Hedera wallet address to
                              receive your tokens.
                            </li>
                          </ul>

                          <p className="mt-3">
                            For step-by-step instructions on creating a
                            Mingo/Hedera wallet, please refer to the
                            instructions on the Mingo Island - Explore page.
                          </p>
                        </div>
                        <div className="text-[13px] text-left mt-4 bg-yellow-100 p-3 rounded">
                          <b className="mr-2 text-yellow-700">IMPORTANT:</b>
                          <span className="text-yellow-800">
                            We will verify that the wallet address you provide
                            is a legitimate Hedera wallet. Incorrect wallet
                            types or addresses will result in you not receiving
                            your Mingo tokens.
                          </span>
                        </div>
                        <div className="text-[13px] text-left mt-4 bg-blue-100 p-3 rounded">
                          <b className="mr-2 text-blue-700">Remember:</b>
                          <span className="text-blue-800">
                            This Hedera wallet will not be available as a
                            withdrawal option for your main balance. It is
                            solely for the purpose of Mingo token distribution.
                          </span>
                        </div>
                        <p className="mt-4 text-gray-600 italic">
                          If you have any questions or need assistance, please
                          contact our support team.
                        </p>{' '}
                      </>
                    )}

                    {/* step 2 */}
                    {currentStepMingoWallet === 2 && (
                      <>
                        <label className="mod-inside-body-label">
                          {dictionary['MY-ACCOUNT-title72']}
                        </label>
                        <input
                          type="text"
                          placeholder="Name"
                          className="mod-inside-body-input"
                          value={mingoWalletFormData.account_name}
                          onChange={(e) => {
                            setMingoWalletFormData({
                              ...mingoWalletFormData,
                              account_name: e.target.value
                            });
                          }}
                        />

                        <div className="text-[13px] text-left mt-4 bg-yellow-100 p-3 rounded">
                          <b className="mr-2 text-yellow-700">IMPORTANT:</b>
                          <span className="text-yellow-800">
                            We will verify that the wallet address you provide
                            is a legitimate Hedera wallet. Incorrect wallet
                            types or addresses will result in you not receiving
                            your Mingo tokens.
                          </span>
                        </div>
                      </>
                    )}

                    {/* step 3 */}
                    {currentStepMingoWallet === 3 && (
                      <>
                        <br />
                        <input
                          type="text"
                          placeholder="Recipient address"
                          className="mod-inside-body-input"
                          onChange={(e) =>
                            setMingoWalletFormData({
                              ...mingoWalletFormData,
                              recipient_address: e.target.value
                            })
                          }
                        />
                        {is2faShow === 1 && (
                          <>
                            <label
                              className="mod-inside-body-label"
                              style={{ marginTop: '15px' }}
                            >
                              {dictionary['MY-ACCOUNT-title5']}
                            </label>
                            <input
                              type="number"
                              placeholder="code"
                              className="mod-inside-body-input"
                              value={mingoWalletFormData?.token}
                              onChange={(e) =>
                                e.target.value.length <= 6 &&
                                setMingoWalletFormData({
                                  ...mingoWalletFormData,
                                  token: e.target.value
                                })
                              }
                            />
                          </>
                        )}
                        <div className="text-[13px] text-left mt-4 bg-yellow-100 p-3 rounded">
                          <b className="mr-2 text-yellow-700">IMPORTANT:</b>
                          <span className="text-yellow-800">
                            We will verify that the wallet address you provide
                            is a legitimate Hedera wallet. Incorrect wallet
                            types or addresses will result in you not receiving
                            your Mingo tokens.
                          </span>
                        </div>
                      </>
                    )}
                  </div>
                  {is2faShow === 1 && (
                    <button
                      className="mod-inside-body-btn pointer"
                      onClick={() => {
                        if (currentStepMingoWallet === 3) {
                          if (mingoWalletFormData.token) {
                            const currency = validateHederaAddressWithSDK(
                              mingoWalletFormData?.recipient_address?.trim()
                            );

                            if (currency) {
                              createMingoAccountHandle();
                            } else {
                              toast.error(dictionary['MY-ACCOUNT-title76-2']);
                            }
                          } else {
                            toast.error(dictionary['MY-ACCOUNT-title77']);
                          }
                        } else if (currentStepMingoWallet === 2) {
                          mingoWalletFormData.account_name.trim().length > 0 &&
                            setCurrentStepMingoWallet(
                              currentStepMingoWallet + 1
                            );
                        } else {
                          setCurrentStepMingoWallet(currentStepMingoWallet + 1);
                        }
                      }}
                    >
                      {currentStepMingoWallet === 3
                        ? dictionary['MY-ACCOUNT-title78']
                        : dictionary['MY-ACCOUNT-title79']}
                    </button>
                  )}

                  {is2faShow === 0 && (
                    <div
                      className="warning-message-deposit"
                      style={{ marginTop: '1rem' }}
                    >
                      <p>{dictionary['MY-ACCOUNT-title80']}</p>
                      <p>
                        <b>{dictionary['MY-ACCOUNT-title81']}</b> {'->'}{' '}
                        <Link to="/Profile">
                          <b className="link">
                            {dictionary['MY-ACCOUNT-title82']}
                          </b>
                        </Link>
                      </p>
                    </div>
                  )}
                </div>
              </div>

              <div
                className="blur-bg"
                onClick={() => setModalTronWallet(false)}
              ></div>
            </div>
          </Flex>
        )}

        {modalDelete && (
          <Flex x="center">
            <div className="mod">
              <div className="mod-border">
                <div className="mod-box">
                  <p className="mod-box-p">
                    {dictionary['MY-ACCOUNT-title83']}
                  </p>
                  <button
                    className="mod-box-btn"
                    onClick={() => setModalDelete(false)}
                  >
                    X
                  </button>
                </div>
                <div style={{ marginRight: 10, marginLeft: 10 }}></div>
                <div className="mod-inside">
                  <div className="mod-inside-body">
                    <b>{dictionary['MY-ACCOUNT-title84']}</b>

                    <label
                      className="mod-inside-body-label"
                      style={{ marginTop: '15px' }}
                    >
                      {dictionary['MY-ACCOUNT-title5']}:
                    </label>
                    <input
                      type="number"
                      placeholder="code"
                      className="mod-inside-body-input"
                      value={deleteToken}
                      onChange={(e) =>
                        e.target.value.length <= 6 &&
                        setDeleteToken(e.target.value)
                      }
                    />
                  </div>
                  <Flex gap={0.8} x="center">
                    <button
                      className="mod-inside-body-btn pointer"
                      onClick={() => setModalDelete(false)}
                    >
                      {dictionary['MY-ACCOUNT-title6']}
                    </button>
                    <button
                      className="mod-inside-body-btn pointer"
                      onClick={() => {
                        deleteAccountHandle(selectedItem, deleteToken);
                        setDeleteToken('');
                      }}
                    >
                      {dictionary['MY-ACCOUNT-title83']}
                    </button>
                  </Flex>
                </div>
              </div>

              <div
                className="blur-bg"
                onClick={() => setModalDelete(false)}
              ></div>
            </div>
          </Flex>
        )}

        {modalSelect && (
          <Flex x="center">
            <div className="mod">
              <div className="mod-border">
                <div className="mod-box">
                  <p className="mod-box-p">
                    {dictionary['MY-ACCOUNT-title85']}
                  </p>
                  <button
                    className="mod-box-btn"
                    onClick={() => setModalSelect(false)}
                  >
                    X
                  </button>
                </div>
                <div style={{ marginRight: 10, marginLeft: 10 }}></div>
                <div className="mod-inside">
                  <div className="mod-inside-body">
                    <b>{dictionary['MY-ACCOUNT-title86']}</b>
                  </div>
                  <Flex gap={0.8} x="center">
                    <button
                      className="mod-inside-body-btn pointer"
                      onClick={() => setModalSelect(false)}
                    >
                      {dictionary['MY-ACCOUNT-title6']}
                    </button>
                    <button
                      className="mod-inside-body-btn pointer"
                      onClick={() => setDefaultAccountHandle(selectedItem)}
                    >
                      {dictionary['MY-ACCOUNT-title87']}
                    </button>
                  </Flex>
                </div>
              </div>

              <div
                className="blur-bg"
                onClick={() => setModalSelect(false)}
              ></div>
            </div>
          </Flex>
        )}

        {modalEdit && (
          <Flex x="center">
            <div className="mod">
              <div className="mod-border">
                <div className="mod-box">
                  <p className="mod-box-p">
                    {dictionary['MY-ACCOUNT-title40']}
                  </p>
                  <button
                    className="mod-box-btn"
                    onClick={() => setModalEdit(false)}
                  >
                    X
                  </button>
                </div>
                <div className="mod-inside">
                  <div className="mod-inside-body">
                    {editableData.type === 0 && (
                      <>
                        <label
                          className="mod-inside-body-label"
                          style={{ marginBottom: '0' }}
                        >
                          {dictionary['MY-ACCOUNT-title88']}
                        </label>
                        <input
                          type="text"
                          placeholder="Account name"
                          className="mod-inside-body-input"
                          value={editableData.account_name}
                          onChange={(e) =>
                            setEditableData({
                              ...editableData,
                              account_name: e.target.value
                            })
                          }
                        />
                        {/* info */}
                        <>
                          <label
                            className="mod-inside-body-label"
                            style={{
                              marginBottom: '0',
                              fontWeight: '400',
                              fontSize: '14px'
                            }}
                          >
                            {dictionary['MY-ACCOUNT-title89']}
                          </label>
                          <input
                            type="text"
                            placeholder="Account Holder Name"
                            className="mod-inside-body-input"
                            value={editableData.account_holder_name}
                            disabled
                            // onChange={(e) =>
                            //   setEditableData({
                            //     ...editableData,
                            //     account_holder_name: e.target.value
                            //   })
                            // }
                          />
                          <label
                            className="mod-inside-body-label"
                            style={{
                              marginBottom: '0',
                              fontWeight: '400',
                              fontSize: '14px'
                            }}
                          >
                            {dictionary['MY-ACCOUNT-title90']}
                          </label>
                          <input
                            type="text"
                            placeholder="Recipient address"
                            className="mod-inside-body-input"
                            value={editableData.recipient_address}
                            disabled
                            // onChange={(e) =>
                            //   setEditableData({
                            //     ...editableData,
                            //     recipient_address: e.target.value
                            //   })
                            // }
                          />
                          <label
                            className="mod-inside-body-label"
                            style={{
                              marginBottom: '0',
                              fontWeight: '400',
                              fontSize: '14px'
                            }}
                          >
                            {dictionary['MY-ACCOUNT-title91']}
                          </label>
                          <input
                            type="text"
                            placeholder="Bank name"
                            className="mod-inside-body-input"
                            value={editableData.bank_name}
                            disabled
                            // onChange={(e) =>
                            //   setEditableData({
                            //     ...editableData,
                            //     bank_name: e.target.value
                            //   })
                            // }
                          />
                          <label
                            className="mod-inside-body-label"
                            style={{
                              marginBottom: '0',
                              fontWeight: '400',
                              fontSize: '14px'
                            }}
                          >
                            {dictionary['MY-ACCOUNT-title92']}
                          </label>
                          <input
                            type="text"
                            placeholder="Bank address"
                            className="mod-inside-body-input"
                            value={editableData.bank_address}
                            disabled
                            // onChange={(e) =>
                            //   setEditableData({
                            //     ...editableData,
                            //     bank_address: e.target.value
                            //   })
                            // }
                          />
                          <label
                            className="mod-inside-body-label"
                            style={{
                              marginBottom: '0',
                              fontWeight: '400',
                              fontSize: '14px'
                            }}
                          >
                            {dictionary['MY-ACCOUNT-title93']}
                          </label>
                          <input
                            type="text"
                            placeholder="Institution number"
                            className="mod-inside-body-input"
                            value={editableData.institution_number}
                            disabled
                            // onChange={(e) =>
                            //   setEditableData({
                            //     ...editableData,
                            //     institution_number: e.target.value
                            //   })
                            // }
                          />
                          <label
                            className="mod-inside-body-label"
                            style={{
                              marginBottom: '0',
                              fontWeight: '400',
                              fontSize: '14px'
                            }}
                          >
                            {dictionary['MY-ACCOUNT-title94']}
                          </label>
                          <input
                            type="number"
                            placeholder="Account number"
                            className="mod-inside-body-input"
                            value={editableData.account_number}
                            disabled
                            // onChange={(e) =>
                            //   setEditableData({
                            //     ...editableData,
                            //     account_number: e.target.value
                            //   })
                            // }
                          />
                          <label
                            className="mod-inside-body-label"
                            style={{
                              marginBottom: '0',
                              fontWeight: '400',
                              fontSize: '14px'
                            }}
                          >
                            {dictionary['MY-ACCOUNT-title95']}
                          </label>
                          <input
                            type="text"
                            placeholder="Swift code"
                            className="mod-inside-body-input"
                            value={editableData.swift_code}
                            disabled
                            // onChange={(e) =>
                            //   setEditableData({
                            //     ...editableData,
                            //     swift_code: e.target.value
                            //   })
                            // }
                          />
                          <label
                            className="mod-inside-body-label"
                            style={{
                              marginBottom: '0',
                              fontWeight: '400',
                              fontSize: '14px'
                            }}
                          >
                            {dictionary['MY-ACCOUNT-title96']}
                          </label>
                          <input
                            type="text"
                            placeholder="IBAN"
                            className="mod-inside-body-input"
                            value={editableData.iban}
                            disabled
                            // onChange={(e) =>
                            //   setEditableData({
                            //     ...editableData,
                            //     iban: e.target.value
                            //   })
                            // }
                          />
                        </>
                      </>
                    )}

                    {editableData.type === 1 && (
                      <>
                        <label
                          className="mod-inside-body-label"
                          style={{ marginBottom: '0' }}
                        >
                          {dictionary['MY-ACCOUNT-title88']}
                        </label>
                        <input
                          type="text"
                          placeholder="Account name"
                          className="mod-inside-body-input"
                          value={editableData.account_name}
                          onChange={(e) =>
                            setEditableData({
                              ...editableData,
                              account_name: e.target.value
                            })
                          }
                        />
                        <label
                          className="mod-inside-body-label"
                          style={{
                            marginBottom: '0',
                            fontWeight: '400',
                            fontSize: '14px'
                          }}
                        >
                          {dictionary['MY-ACCOUNT-title90']}
                        </label>
                        <input
                          type="text"
                          placeholder="Recipient address"
                          className="mod-inside-body-input"
                          style={{ fontSize: '14px' }}
                          value={editableData.recipient_address}
                          disabled
                          onChange={(e) =>
                            setEditableData({
                              ...editableData,
                              recipient_address: e.target.value
                            })
                          }
                        />
                      </>
                    )}

                    <label
                      className="mod-inside-body-label"
                      style={{ marginTop: '15px' }}
                    >
                      {dictionary['MY-ACCOUNT-title5']}
                    </label>
                    <input
                      type="number"
                      placeholder="code"
                      className="mod-inside-body-input"
                      value={editableData.token}
                      onChange={(e) =>
                        setEditableData({
                          ...editableData,
                          token: e.target.value
                        })
                      }
                    />
                  </div>
                  <Flex gap={0.8} x="center">
                    <button
                      className="mod-inside-body-btn pointer"
                      onClick={() => setModalEdit(false)}
                    >
                      {dictionary['MY-ACCOUNT-title6']}
                    </button>
                    <button
                      className="mod-inside-body-btn pointer"
                      onClick={() => editAccountHandle(selectedItem)}
                    >
                      {dictionary['MY-ACCOUNT-title40']}
                    </button>
                  </Flex>
                </div>
              </div>
              <div
                className="blur-bg"
                onClick={() => setModalEdit(false)}
              ></div>
            </div>
          </Flex>
        )}
        <Layout className="prof">
          <Container x={'center'} marginT={1} className="container">
            <Flex direction={'col'} gap={1.7} x={'stretch'} y={'start'}>
              <Header title={dictionary['PAGE-title2']} />

              {allAccounts?.pti_status === true ? (
                <div className="w-full">
                  {allAccounts?.tron_wallet?.recipient_address &&
                  allAccounts?.tron_wallet?.recipient_address?.length > 0 ? (
                    <div className="w-full bg-white rounded-3xl p-8 flex justify-between  items-center">
                      <div className="w-full flex flex-col h-full justify-between">
                        <div className="prof-active-header">
                          <div className="prof-active-name">
                            {allAccounts?.tron_wallet?.account_name}
                          </div>
                          <div className="prof-active-add">
                            {allAccounts?.tron_wallet?.recipient_address}
                          </div>
                        </div>
                        <div className="prof-active-bottom">
                          <div className="prof-active-bottom-right">
                            <div className="prof-right-body">
                              <div className="prof-right-type">Type:</div>
                              <div className="prof-right-ans">
                                Crypto (Tron Wallet)
                              </div>
                            </div>
                          </div>
                          <img
                            src="/images/tron-wallet.png"
                            alt="bankLogo"
                            className="xl:hidden block prof-active-img"
                          />
                        </div>
                      </div>
                      <img
                        src="/images/tron-wallet.png"
                        alt="bankLogo"
                        className="xl:w-[101px] xl:h-[101px] hidden xl:block"
                      />
                    </div>
                  ) : (
                    <div className="w-full">
                      <div
                        className="bg-white rounded-3xl pointer flex justify-center items-center"
                        onClick={() => {
                          setPtiWalletFormData({
                            account_name: 'PTI Wallet',
                            recipient_address: '',
                            token: ''
                          });

                          setModalTronWallet(true);
                          setCurrentStepTronWallet(1);
                        }}
                      >
                        <button className="prof-r-btn-new">
                          <img
                            className="pointer"
                            src="/images/addFinancialAccountMINI.svg"
                            alt="addFinancialAccountMINI"
                          />
                        </button>
                        <div className="prof-r-txt">
                          {dictionary['MY-ACCOUNT-title97-1']}
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              ) : null}
              {allAccounts?.hedera_status === true ? (
                <div className="w-full">
                  {allAccounts?.hedera_wallet?.recipient_address &&
                  allAccounts?.hedera_wallet?.recipient_address?.length > 0 ? (
                    <div className="w-full bg-white rounded-3xl p-8 flex justify-between  items-center">
                      <div className="w-full flex flex-col h-full justify-between">
                        <div className="prof-active-header">
                          <div className="prof-active-name">
                            {allAccounts?.hedera_wallet?.account_name}
                          </div>
                          <div className="prof-active-add">
                            {allAccounts?.hedera_wallet?.recipient_address}
                          </div>
                        </div>
                        <div className="prof-active-bottom">
                          <div className="prof-active-bottom-right">
                            <div className="prof-right-body">
                              <div className="prof-right-type">Type:</div>
                              <div className="prof-right-ans">
                                Crypto (Hedera Wallet)
                              </div>
                            </div>
                          </div>
                          <img
                            src="/images/hedera-wallet.png"
                            alt="bankLogo"
                            className="xl:hidden block prof-active-img"
                          />
                        </div>
                      </div>
                      <img
                        src="/images/hedera-wallet.png"
                        alt="bankLogo"
                        className="xl:w-[101px] xl:h-[101px] hidden xl:block"
                      />
                    </div>
                  ) : (
                    <div className="w-full">
                      <div
                        className="bg-white rounded-3xl pointer flex justify-center items-center"
                        onClick={() => {
                          setMingoWalletFormData({
                            account_name: 'Mingo Wallet',
                            recipient_address: '',
                            token: ''
                          });

                          setModalMingoWallet(true);
                          setCurrentStepMingoWallet(1);
                        }}
                      >
                        <button className="prof-r-btn-new">
                          <img
                            className="pointer"
                            src="/images/addFinancialAccountMINI.svg"
                            alt="addFinancialAccountMINI"
                          />
                        </button>
                        <div className="prof-r-txt">
                          {dictionary['MY-ACCOUNT-title97-2']}
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              ) : null}

              <div className="prof-row">
                {allAccounts ? (
                  allAccounts?.data?.map((item: any, index: number) => (
                    <FinancialAccountItem
                      key={index}
                      account={item}
                      selectedItem={allAccounts?.default}
                      setSelectedItem={setSelectedItem}
                    />
                  ))
                ) : (
                  <>
                    <div className="prof-right">
                      <div className="prof-active-header">
                        <div
                          className="prof-active-name skeleton-box"
                          style={{ width: '30%' }}
                        ></div>
                        <div
                          className="prof-active-add skeleton-box"
                          style={{ width: '15%' }}
                        ></div>
                      </div>
                      <div className="prof-active-bottom">
                        <div
                          className="prof-active-bttm skeleton-box"
                          style={{ width: '20%' }}
                        ></div>
                        <div
                          className="prof-active-img skeleton-box"
                          style={{ width: '20%', height: '60px' }}
                        ></div>
                      </div>
                    </div>

                    <div className="prof-right">
                      <div className="prof-active-header">
                        <div
                          className="prof-active-name skeleton-box"
                          style={{ width: '30%' }}
                        ></div>
                        <div
                          className="prof-active-add skeleton-box"
                          style={{ width: '15%' }}
                        ></div>
                      </div>
                      <div className="prof-active-bottom">
                        <div
                          className="prof-active-bttm skeleton-box"
                          style={{ width: '20%' }}
                        ></div>
                        <div
                          className="prof-active-img skeleton-box"
                          style={{ width: '20%', height: '60px' }}
                        ></div>
                      </div>
                    </div>
                  </>
                )}

                <div className="prof-r">
                  <div
                    className="prof-r-border-new pointer"
                    onClick={() => {
                      setCryptoFormData({
                        account_name: '',
                        account_number: '',
                        recipient_address: '',
                        token: '',
                        is_ultimo: '',
                        ultimo_token: ''
                      });
                      setWireFormData({
                        account_name: '',
                        account_number: '',
                        bank_name: '',
                        institution_number: '',
                        swift_code: '',
                        iban: '',
                        bank_address: '',
                        recipient_address: '',
                        token: ''
                      });

                      setAccountType('1');
                      setModal(true);
                      setCurrentStep(1);
                    }}
                  >
                    <button className="prof-r-btn-new">
                      <img
                        className="pointer"
                        src="/images/addFinancialAccountMINI.svg"
                        alt="addFinancialAccountMINI"
                      />
                    </button>
                    <div className="prof-r-txt">
                      {dictionary['MY-ACCOUNT-title97']}
                    </div>
                  </div>
                </div>
              </div>
              <div style={{ position: 'relative' }}>
                <div className={'prof-description'}>
                  {/* <Flex x="between" y="center"> */}
                  <div className="prof-active-name">
                    {dictionary['MY-ACCOUNT-title98']}
                  </div>
                  <div className="prof-active-bottom">
                    <div className="prof-active-bttm">
                      <div
                        className="prof-active-type-name"
                        dangerouslySetInnerHTML={{ __html: ckeditorContent }}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </Flex>
          </Container>
        </Layout>
      </>
    );
  else return <>{navigate('/')}</>;
};

export default FinancialAccount;
