import React, { useContext, useEffect, useState } from 'react';
import toast from 'react-hot-toast';
import { IoIosClose } from 'react-icons/io';
import { Link } from 'react-router-dom';
import { numberWithCommas } from '../utils/tools';
import TreasuryContext from '../context/private/treasuryContext';
import BosContext from '../context/private/bosContext';
import AccountContext from '../context/private/accountContext';
import AuthContext from '../context/public/loginContext';
import PublicContext from '../context/public/publicContext';

type TModal = {
  onChange?: (e: any) => void;
  onClick?: () => void;
  min?: number;
  max?: number | string;
  image?: string;
  title?: string;
  isOpen?: boolean;
  setIsOpen?: (e: boolean) => void;
  value?: any;
  setValue?: any;
  balance?: any;
  balancePure?: any;
  islandId?: any;
};

const ModalSpecificNotification: React.FC<TModal> = ({
  isOpen,
  setIsOpen,
  title
}: any) => {
  const { isOpenOnboardingCallCompleted, setIsOpenOnboardingCallCompleted } =
    useContext<any>(PublicContext);

  const { bosOnBoardingModalData, loadBosOnBoardingModal } =
    useContext(BosContext);
  const { userInfo } = useContext(AccountContext);

  useEffect(() => {
    userInfo?.is_onboarding_call_completed === 0 &&
      isOpenOnboardingCallCompleted &&
      setIsOpen(true);
  }, [userInfo, isOpenOnboardingCallCompleted]);

  useEffect(() => {
    loadBosOnBoardingModal();
  }, []);

  return (
    <>
      <div
        onClick={() =>
          setIsOpenOnboardingCallCompleted(false) && setIsOpen(false)
        }
        className={` bg-black bg-opacity-[0.65] inset-0 z-[150] fixed w-full h-full transition duration-300 backdrop-blur-[3px] ${
          isOpen && isOpenOnboardingCallCompleted
            ? 'visible opacity-100'
            : 'hidden invisible opacity-0'
        }`}
      ></div>
      <div
        className={`mt-40 bg-white absolute left-1/2 w-full p-4 lg:p-8 rounded-3xl max-w-[1037px] h-fit transition duration-300 -translate-x-1/2 z-[155] ${
          isOpen && isOpenOnboardingCallCompleted
            ? 'visible opacity-100'
            : 'invisible opacity-0'
        }`}
      >
        <div className="flex justify-between items-center mb-5 border-b border-gray-400 pb-5">
          <span className="font-bold text-lg lg:text-[22px] ">
            Onboarding Call
          </span>
          <div
            onClick={() =>
              setIsOpenOnboardingCallCompleted(false) && setIsOpen(false)
            }
            className="p-1 cursor-pointer rounded-full bg-[#e9f1f599] hover:rotate-360 transition"
          >
            <IoIosClose className="text-5xl opacity-50" />
          </div>
        </div>
        <div className="boscrew-t-row">
          <div
            className="boscrew-t-cap"
            dangerouslySetInnerHTML={{
              __html: bosOnBoardingModalData
            }}
          ></div>
        </div>
      </div>
    </>
  );
};

export default ModalSpecificNotification;
