import { useContext, useEffect, useState } from 'react';
import Faq from 'react-faq-component';
import PublicContext from '../context/public/publicContext';
import { Container, Flex } from '../styles/Styles';
import Header from './Header';
import Loader from './Loader';
import Layout from './layout/Index';
import { MdOutlineQuestionAnswer } from 'react-icons/md';
import { TbMessage2Question } from 'react-icons/tb';
import { Link, useLocation } from 'react-router-dom';
import NotFoundImage from './../assets/images/not-found.png';
import { AsideItems, SearchBox, SearchWrapper } from './FaqsList';

const NotFoundTopic = () => {
  const { faqsQuestionData } = useContext(PublicContext);
  return (
    <div className="faq-list-content">
      <div className="faq-question-list">
        <h2 className="faq-question-list-title">
          {faqsQuestionData?.Topics?.[0]?.title}
        </h2>
        <p className="mb-2">
          {faqsQuestionData?.Topics?.[0]?.short_description}
        </p>
        <div className="faq-question-list-content">
          <Flex direction={'col'} x="center">
            <img className="not-found-faq" src={NotFoundImage} />
            <h2>No question found!</h2>
          </Flex>
        </div>
      </div>
    </div>
  );
};

const FaqsQuestions = () => {
  const {
    loadFaq,
    faqsData,
    faqsSectionData,
    loadFaqSection,
    faqsTopicData,
    loadFaqTopic,
    faqsQuestionData,
    loadFaqQuestion,
    popularQuestionsData,
    loadPopularQuestions,
    searchQuery
  } = useContext(PublicContext);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const [data, setData] = useState({
    title: 'FAQs',
    rows: []
  });

  useEffect(() => {
    if (faqsData) {
      data.rows = faqsData;
      setData({
        ...data,
        rows: faqsData
      });
    }
  }, [faqsData]);

  const [topicID, setTopicID] = useState(0);
  const location = useLocation();
  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const targetParam = urlParams.get('id');
    const targetParam2 = urlParams.get('topic-id');
    setTopicID(targetParam2);

    if (Boolean(searchQuery && searchQuery.length > 0)) {
    } else {
      if (targetParam) {
        console.log(targetParam, targetParam2);
        loadFaqQuestion(targetParam, targetParam2);
      } else {
        loadFaqQuestion();
      }
    }
  }, [location.search, searchQuery]);

  useEffect(() => {
    loadPopularQuestions();
  }, []);

  return (
    <Layout className="parent">
      <Container
        x={'center'}
        marginT={1}
        className="container container-boscrew"
      >
        <Flex gap={1.7} x={'center'} y={'start'} className="flex-boscrew">
          <div className="boscrew" style={{ width: '100%' }}>
            <Header title="BOS Help Desk - FAQ" />

            <SearchBox />

            <div className="faq-system-wrapper">
              <SearchWrapper>
                {faqsQuestionData ? (
                  <>
                    {!faqsQuestionData?.FAQ ||
                    faqsQuestionData?.FAQ?.length === 0 ? (
                      <NotFoundTopic />
                    ) : (
                      <div className="faq-list-content">
                        {[''].map((item) => (
                          <div className="faq-question-list">
                            <h2 className="faq-question-list-title">
                              {faqsQuestionData?.Topics?.[0]?.title}
                            </h2>
                            <p className="mb-2">
                              {faqsQuestionData?.Topics?.[0]?.short_description}
                            </p>
                            <div className="faq-question-list-content">
                              {faqsQuestionData?.FAQ?.map((item) => (
                                <div className="faq-question-item pointer">
                                  <MdOutlineQuestionAnswer
                                    className="faq-question-item-icon"
                                    color="#153458"
                                    size={'1.2em'}
                                  />
                                  <Link
                                    to={`/faq-system/questions/question?id=${item?.id}&topic-id=${faqsQuestionData?.Topics?.[0]?.id}`}
                                  >
                                    {item?.question}
                                  </Link>
                                </div>
                              ))}
                            </div>
                          </div>
                        ))}
                      </div>
                    )}
                  </>
                ) : (
                  <Loader />
                )}
              </SearchWrapper>
              <AsideItems />
            </div>
          </div>
        </Flex>
      </Container>
    </Layout>
  );
};

export default FaqsQuestions;
