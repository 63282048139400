import { useContext, useEffect, useState } from 'react';
import Pagination from 'react-responsive-pagination';
import { Link } from 'react-router-dom';
import borderGray from '../../src/assets/images/border-gradiant-gray.svg';
import borderOrange from '../../src/assets/images/border-gradiant-orange.svg';
import borderPurple from '../../src/assets/images/border-gradiant-purple.svg';
import MapContext from '../context/private/mapContext';
import PrivateContext from '../context/private/privateContext';
import { Container, Flex } from '../styles/Styles';
import { numberWithCommas, timeToYYYYMMDD } from '../utils/tools';
import NotFoundImage from './../assets/images/not-found.png';
import { BalanceChartDaily } from './BalanceChartDaily';
import { BalanceChartMonthly } from './BalanceChartMonthly';
import Header from './Header';
import { LoaderMini } from './Loader';
import { ProfitChartDaily } from './ProfitChartDaily';
import { ProfitChartMonthly } from './ProfitChartMonthly';
import TooltipInfo from './TooltipInfo';
import { AiOutlineInfoCircle } from 'react-icons/ai';
import dictionary from '../locals/my-map.json';
import AccountContext from '../context/private/accountContext';
import ModalSpecificNotification from './ModalSpecificNotification';

const MyMap = (): JSX.Element => {
  const { setUpdateTimer } = useContext(PrivateContext);
  const {
    balanceChartDailyData,
    balanceChartMonthlyData,
    mapData,
    loadMyMap,
    logData,
    loadLogs,
    loadProfitChart,
    loadBalanceChart,
    profitChartDailyData,
    profitChartMonthlyData
  } = useContext(MapContext);

  const { agreementModalData, submitAgreementForm, loadAgreementModal } =
    useContext(PrivateContext);

  const [balanceChart, setBalanceChart] = useState('yearly');
  const [profitChart, setProfitChart] = useState('yearly');

  const { flowState } = useContext(PrivateContext);
  const { userInfo } = useContext(AccountContext);

  useEffect(() => {
    if (flowState.step === 17) {
      loadMyMap();
      loadLogs(1);
      loadProfitChart('yearly');
      loadBalanceChart('yearly');
    }
  }, [flowState]);

  useEffect(() => {
    if (profitChart === 'monthly') {
      loadProfitChart('monthly');
    }
  }, [profitChart]);

  useEffect(() => {
    if (balanceChart === 'monthly') {
      loadBalanceChart('monthly');
    }
  }, [balanceChart]);

  const [currentPage1, setCurrentPage1] = useState(0);
  useEffect(() => {
    loadLogs(currentPage1);
  }, [currentPage1]);

  useEffect(() => {
    setUpdateTimer(+new Date());
  }, [currentPage1, balanceChart, profitChart]);

  const [isOpenOnboardingCallCompleted, setIsOpenOnboardingCallCompleted] =
    useState(false);

  return (
    <Container
      x={'center'}
      marginT={1}
      style={{
        display: 'table !important'
      }}
    >
      <Flex className="flex-mymap" h="unset" gap={1.7} x={'center'} y={'start'}>
        <Header title={dictionary['PAGE-title']} />

        <ModalSpecificNotification
          title="Title"
          isOpen={isOpenOnboardingCallCompleted}
          setIsOpen={setIsOpenOnboardingCallCompleted}
        />

        <div className="mymap-f-r">
          <div className="mymap-f-box">
            <TooltipInfo text={dictionary['BOX-1-bubble']} />
            <div className="mymap-f-box-inside">
              <div className="mymap-f-box-h">{dictionary['BOX-1-top']}</div>
              <div className="mymap-f-box-cap">
                {dictionary['BOX-1-bottom']}
              </div>
            </div>
            <div className="mymap-f-box-amount">
              {mapData ? (
                mapData?.totalNumbers?.total_deposit ? (
                  `$${numberWithCommas(
                    mapData?.totalNumbers?.total_deposit,
                    2
                  )}`
                ) : (
                  '$0'
                )
              ) : (
                <div className="skeleton-element-item skeleton-box"></div>
              )}
            </div>
          </div>

          <div className="mymap-f-box">
            <TooltipInfo text={dictionary['BOX-2-bubble']} />
            <div className="mymap-f-box-inside">
              <div className="mymap-f-box-h">{dictionary['BOX-2-top']}</div>
              <div className="mymap-f-box-cap">
                {dictionary['BOX-2-bottom']}
              </div>
            </div>
            <div className="mymap-f-box-amount">
              {mapData ? (
                mapData?.totalNumbers?.payment_withdraw ? (
                  `$${numberWithCommas(
                    mapData?.totalNumbers?.payment_withdraw,
                    2
                  )}`
                ) : (
                  '$0'
                )
              ) : (
                <div className="skeleton-element-item skeleton-box"></div>
              )}
            </div>
          </div>

          <div className="mymap-f-box">
            <TooltipInfo text={dictionary['BOX-3-bubble']} />
            <div className="mymap-f-box-inside">
              <div className="mymap-f-box-h">{dictionary['BOX-3-top']}</div>
              <div className="mymap-f-box-cap">
                {dictionary['BOX-3-bottom']}
              </div>
            </div>
            <div className="mymap-f-box-amount">
              {mapData ? (
                mapData?.totalNumbers?.activated_refund ? (
                  `$${numberWithCommas(
                    mapData?.totalNumbers?.activated_refund,
                    2
                  )}`
                ) : (
                  '$0'
                )
              ) : (
                <div className="skeleton-element-item skeleton-box"></div>
              )}
            </div>
          </div>

          <div className="mymap-f-box">
            <TooltipInfo text={dictionary['BOX-4-bubble']} />
            <div className="mymap-f-box-inside">
              <div className="mymap-f-box-h">{dictionary['BOX-4-top']}</div>
              <div className="mymap-f-box-cap">
                {dictionary['BOX-4-bottom']}
              </div>
            </div>
            <div className="mymap-f-box-inside mymap-f-box-row">
              <div className="mymap-f-box-inside-amount">
                {mapData ? (
                  mapData?.totalNumbers?.total_balance ? (
                    `$${numberWithCommas(
                      mapData?.totalNumbers?.total_balance,
                      2
                    )}`
                  ) : (
                    '$0'
                  )
                ) : (
                  <div className="skeleton-element-item skeleton-box"></div>
                )}
              </div>
            </div>
          </div>
          <div className="mymap-f-box">
            <TooltipInfo text={dictionary['BOX-6-bubble']} />
            <div className="mymap-f-box-inside">
              <div className="mymap-f-box-h">{dictionary['BOX-6-top']}</div>
              <div className="mymap-f-box-cap">
                {dictionary['BOX-6-bottom']}
              </div>
            </div>
            <div className="mymap-f-box-inside mymap-f-box-row">
              <div className="mymap-f-box-inside-amount">
                {mapData ? (
                  mapData?.totalNumbers?.treasury_balance ? (
                    `$${numberWithCommas(
                      mapData?.totalNumbers?.treasury_balance,
                      2
                    )}`
                  ) : (
                    '$0'
                  )
                ) : (
                  <div className="skeleton-element-item skeleton-box"></div>
                )}
              </div>
            </div>
          </div>
          <div className="mymap-f-box">
            <TooltipInfo text={dictionary['BOX-5-bubble']} />
            <div className="mymap-f-box-inside">
              <div className="mymap-f-box-h">{dictionary['BOX-5-top']}</div>
              <div className="mymap-f-box-cap">
                {dictionary['BOX-5-bottom']}
              </div>
            </div>
            <div className="mymap-f-box-inside mymap-f-box-row">
              <div className="mymap-f-box-inside-amount">
                {mapData ? (
                  mapData?.totalNumbers?.gratitute_balance ? (
                    `$${numberWithCommas(
                      mapData?.totalNumbers?.gratitute_balance,
                      2
                    )}`
                  ) : (
                    '$0'
                  )
                ) : (
                  <div className="skeleton-element-item skeleton-box"></div>
                )}
              </div>
            </div>
          </div>

          {/* <div className="mymap-f-box">
            <div className="mymap-f-box-inside">
              <div className="mymap-f-box-h">Total</div>
              <div className="mymap-f-box-cap">Transactions</div>
            </div>
            <div className="mymap-f-box-amount">{mapData?.extraData?.total_transaction ? `$${numberWithCommas(mapData?.extraData?.total_transaction)}` : '$0'}</div>
          </div> */}
        </div>

        <div className="mymap-s-r">
          <div className="mymap-s chart-section ">
            <div className="mymap-s-box">
              <div className="mymap-s-row">
                <Flex y="center" gap={0.5}>
                  <div className="mymap-s-h">
                    {dictionary['CHART-PROFIT-title']}
                  </div>
                  <div
                    className="tooltip-info tooltip-info-normal tooltip-info-normal-inside"
                    style={{ right: 0, top: 0 }}
                  >
                    <AiOutlineInfoCircle className="tooltip-info-icon" />
                    <div className="tooltip-info-text">
                      <span>{dictionary['CHART-PROFIT-bubble']}</span>
                    </div>
                  </div>
                </Flex>
              </div>

              <select
                className="mymap-s-selector"
                value={profitChart}
                onChange={(e) => setProfitChart(e.target.value)}
              >
                <option value="monthly">
                  {dictionary['CHART-PROFIT-daily']}
                </option>
                <option value="yearly">
                  {dictionary['CHART-PROFIT-monthly']}
                </option>
              </select>
            </div>
            {profitChart === 'monthly' &&
              (profitChartDailyData ? (
                <ProfitChartDaily data={profitChartDailyData} mode="Profit" />
              ) : (
                <div className="chart-skeleton-container">
                  <div className="skeleton-element-item chart skeleton-box skeleton-chart-fix"></div>
                </div>
              ))}
            {profitChart === 'yearly' &&
              (profitChartMonthlyData ? (
                <ProfitChartMonthly
                  data={profitChartMonthlyData}
                  mode="Profit"
                />
              ) : (
                <div className="chart-skeleton-container">
                  <div className="skeleton-element-item chart skeleton-box skeleton-chart-fix"></div>
                </div>
              ))}
          </div>
          <div className="mymap-s chart-section ">
            <div className="mymap-s-box">
              <div className="mymap-s-row">
                <Flex y="center" gap={0.5}>
                  <div className="mymap-s-h">
                    {dictionary['CHART-BALANCE-title']}
                  </div>
                  <div
                    className="tooltip-info tooltip-info-normal tooltip-info-normal-inside"
                    style={{ right: 0, top: 0 }}
                  >
                    <AiOutlineInfoCircle className="tooltip-info-icon" />
                    <div className="tooltip-info-text">
                      <span>{dictionary['CHART-BALANCE-bubble']}</span>
                    </div>
                  </div>
                </Flex>
              </div>

              <select
                className="mymap-s-selector"
                value={balanceChart}
                onChange={(e) => setBalanceChart(e.target.value)}
              >
                <option value="monthly">
                  {dictionary['CHART-BALANCE-daily']}
                </option>
                <option value="yearly">
                  {dictionary['CHART-BALANCE-monthly']}
                </option>
              </select>
            </div>

            {balanceChart === 'yearly' &&
              (balanceChartMonthlyData ? (
                <BalanceChartDaily data={balanceChartMonthlyData} />
              ) : (
                <div className="chart-skeleton-container">
                  <div className="skeleton-element-item chart skeleton-box skeleton-chart-fix"></div>
                </div>
              ))}
            {balanceChart === 'monthly' &&
              (balanceChartDailyData ? (
                <BalanceChartMonthly data={balanceChartDailyData} />
              ) : (
                <div className="chart-skeleton-container">
                  <div className="skeleton-element-item chart skeleton-box skeleton-chart-fix"></div>
                </div>
              ))}
          </div>
        </div>

        <div className="mymap-t-r">
          <div className="mymap-t-boxes mymap-t-boxes-left">
            <div className="mymap-t-box-f">
              <div className="mymap-t-box-f-row">
                <div className="mymap-t-box-f-p">
                  <Flex y="center" gap={0.5}>
                    <div className="mymap-t-box-f-h">
                      {dictionary['BOX-7-top']}
                    </div>
                    <div
                      className="tooltip-info tooltip-info-normal tooltip-info-normal-inside"
                      style={{ right: 0, top: 0 }}
                    >
                      <AiOutlineInfoCircle className="tooltip-info-icon" />
                      <div className="tooltip-info-text">
                        <span>{dictionary['BOX-7-bubble']}</span>
                      </div>
                    </div>
                  </Flex>

                  <div className="mymap-t-box-f-mmber">
                    {mapData ? (
                      mapData?.extraData?.total_crew ? (
                        `${numberWithCommas(mapData?.extraData?.total_crew)}`
                      ) : (
                        '0'
                      )
                    ) : (
                      <div className="skeleton-element-item crew-fees-1 skeleton-box"></div>
                    )}
                  </div>
                </div>
                <div className="mymap-t-box-f-p">
                  <div className="mymap-t-box-f-middle-cap">
                    {dictionary['BOX-7-bottom']}
                  </div>
                  <div className="mymap-t-box-f-middle-amount">
                    {mapData ? (
                      mapData?.extraData?.total_commission ? (
                        `$${numberWithCommas(
                          mapData?.extraData?.total_commission
                        )}`
                      ) : (
                        '$0'
                      )
                    ) : (
                      <div className="skeleton-element-item crew-fees-2 skeleton-box"></div>
                    )}
                  </div>
                </div>
              </div>
              <Link to={'/bos-crew'}>
                <div className="mymap-t-box-f-middle">
                  <img src="/images/rightArrow.svg" alt="rightArrow" />
                </div>
              </Link>
            </div>
            <div className="mymap-t-grid-box">
              <TooltipInfo text={dictionary['BOX-8-bubble']} />
              <div className="mymap-t-grid-border">
                <div className="mymap-t-grid-border-num">
                  {mapData ? (
                    mapData?.extraData?.active_island ? (
                      `${numberWithCommas(mapData?.extraData?.active_island)}`
                    ) : (
                      '0'
                    )
                  ) : (
                    <LoaderMini />
                  )}
                </div>
                <img
                  className="mymap-t-grid-border-image"
                  src={borderGray}
                  alt="border gray"
                />
              </div>
              <div>
                <div
                  className="mymap-t-grid-cap"
                  style={{ marginBottom: 0, marginTop: 5 }}
                >
                  {dictionary['BOX-8-top']}
                </div>
                <div className="mymap-t-grid-info">
                  {dictionary['BOX-8-bottom']}
                </div>
              </div>
            </div>
          </div>
          <div className="mymap-t-boxes">
            <div className="mymap-t-grid">
              <div className="mymap-t-grid-box">
                {dictionary['BOX-9-bubble'].length > 0 ? (
                  <TooltipInfo text={dictionary['BOX-9-bubble']} />
                ) : null}
                <div className="mymap-t-grid-border">
                  <div className="mymap-t-grid-border-num">
                    {mapData ? (
                      mapData?.extraData?.pending_participate ? (
                        `${numberWithCommas(
                          mapData?.extraData?.pending_participate
                        )}`
                      ) : (
                        '0'
                      )
                    ) : (
                      <LoaderMini />
                    )}
                  </div>
                  <img
                    className="mymap-t-grid-border-image"
                    src={borderPurple}
                    alt="border gray"
                  />
                </div>
                <div>
                  <div className="mymap-t-grid-cap">
                    {dictionary['BOX-9-top']}
                  </div>
                  <div className="mymap-t-grid-info">
                    {dictionary['BOX-9-bottom']}
                  </div>
                </div>
              </div>
            </div>
            <div className="mymap-t-grid">
              <div className="mymap-t-grid-box">
                {dictionary['BOX-10-bubble'].length > 0 ? (
                  <TooltipInfo text={dictionary['BOX-10-bubble']} />
                ) : null}
                <div className="mymap-t-grid-border">
                  <div className="mymap-t-grid-border-num">
                    {mapData ? (
                      mapData?.extraData?.pending_Withdraw_count ? (
                        `${numberWithCommas(
                          mapData?.extraData?.pending_Withdraw_count
                        )}`
                      ) : (
                        '0'
                      )
                    ) : (
                      <LoaderMini />
                    )}
                  </div>
                  <img
                    className="mymap-t-grid-border-image"
                    src={borderOrange}
                    alt="border purple"
                  />
                </div>
                <div>
                  <div className="mymap-t-grid-cap">
                    {dictionary['BOX-10-top']}
                  </div>
                  <div className="mymap-t-grid-info">
                    {dictionary['BOX-10-bottom']}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div
          className={
            logData ? 'mymap-f-row main log-section' : 'mymap-f-row log-section'
          }
        >
          <div className="mymap-f-row-p">
            <div className="mymap-f-row-b">
              <Flex y="center" gap={0.5}>
                <button className="mymap-f-row-btn">
                  {dictionary['TX-LOG-title']}
                </button>
                <div
                  className="tooltip-info tooltip-info-normal tooltip-info-normal-inside"
                  style={{
                    left: 0,
                    width: 20,
                    margin: 'auto 5px 9px'
                  }}
                >
                  <AiOutlineInfoCircle className="tooltip-info-icon" />
                  <div className="tooltip-info-text">
                    <span>{dictionary['TX-LOG-bubble']}</span>
                  </div>
                </div>
              </Flex>
            </div>

            <div className="mymap-f-row-right">
              <div className="mymap-f-row-right-filter"></div>
            </div>
          </div>

          {logData ? (
            logData?.msg?.length > 0 ? (
              logData?.msg?.map((log: any, index: number) => {
                return (
                  <div key={index} className="mymap-f-row-bottom">
                    <div className="mymap-f-row-bottom-cap">{log?.title}</div>
                    <div className="mymap-f-row-bottom-right">
                      <div className="mymap-f-row-bottom-right-td">
                        <div className="mymap-f-row-bottom-right-detail">
                          {dictionary['TX-LOG-ROW-DATA-column-1']}
                        </div>
                        <div className="mymap-f-row-bottom-right-financial">
                          {log?.related}
                        </div>
                      </div>
                      <div className="mymap-f-row-bottom-right-td">
                        <div className="mymap-f-row-bottom-right-detail">
                          {dictionary['TX-LOG-ROW-DATA-column-2']}
                        </div>
                        <div className="mymap-f-row-bottom-right-financial">
                          {timeToYYYYMMDD(log?.timestamp)}
                        </div>
                      </div>
                    </div>
                  </div>
                );
              })
            ) : (
              <Flex y="center" x="center">
                <img src={NotFoundImage} />
              </Flex>
            )
          ) : (
            ['', '', '', '', '', '', '', '', '', ''].map((item, index) => {
              return (
                <div key={index} className="mymap-f-row-bottom">
                  <div className="mymap-f-row-bottom-cap">
                    {' '}
                    <div className="skeleton-element-item skeleton-box"></div>
                  </div>
                  <div className="mymap-f-row-bottom-right">
                    <div className="mymap-f-row-bottom-right-td">
                      <div className="mymap-f-row-bottom-right-detail">
                        {dictionary['TX-LOG-ROW-DATA-column-1']}
                      </div>
                      <div className="mymap-f-row-bottom-right-financial">
                        <div className="skeleton-element-item related skeleton-box"></div>
                      </div>
                    </div>
                    <div className="mymap-f-row-bottom-right-td">
                      <div className="mymap-f-row-bottom-right-detail">
                        {dictionary['TX-LOG-ROW-DATA-column-2']}
                      </div>
                      <div className="mymap-f-row-bottom-right-financial">
                        <div className="skeleton-element-item date skeleton-box"></div>
                      </div>
                    </div>
                  </div>
                </div>
              );
            })
          )}

          {logData?.total_page && (
            <Pagination
              className="pagination"
              total={logData?.total_page}
              current={logData?.current_page}
              maxWidth={300}
              a11yActiveLabel=""
              previousLabel=" "
              nextLabel=" "
              renderNav={false}
              ariaPreviousLabel={dictionary['TX-LOG-PAGINATION-prev-lable']}
              ariaNextLabel={dictionary['TX-LOG-PAGINATION-next-lable']}
              onPageChange={(page: any) => setCurrentPage1(page)}
            />
          )}
        </div>
      </Flex>
    </Container>
  );
};

export default MyMap;
