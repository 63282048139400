import { useContext } from "react"
import { Flex } from "../styles/Styles"
import WizardContext from "../context/private/wizardContext"
import Modal from "./AccountSetup/modal"

const MembershipYearly = () => {
    const { setModal } = useContext(WizardContext)
    return (
        <>
            <Modal type="yearly" />

            <Flex y="center">
                <div className="set-fin">
                    <div className="set-border">
                        <img src="/images/IDCard.svg" alt="IDCard" className="set-img" />
                        <div className="set-title">Membership Required</div>
                        <div className="set-info"><p style={{fontSize: 16 }}>
                        Your current membership is up for renewal. On the next screen you will have different payment options. Please choose a payment option that suits you to retain access to the BOS Dashboard. 
                        <br /><br />
                        If you have any more questions, don't hesitate to reach out to us at <a href="mailto:hello@blueoceansociety.club">hello@blueoceansociety.club</a>.
                        <br /><br />
                        Kindly use the provided link to view the payment options, and submit your deposit notification form after initiating your transaction.</p>
                        </div>
                        <button className="set-mem-mid-btn pointer" onClick={() => setModal(true)}>Payment Details</button>
                    </div>
                </div>
            </Flex>
        </>
    )
}

export default MembershipYearly