import { createContext, useEffect, useState } from 'react';
import profileService from '../../services/profileService';
import publicService from '../../services/publicService';
import authService from '../../services/authService';
import config from '../../config';
import wizardService from '../../services/wizardService';
import { useLocation, useNavigate } from 'react-router-dom';
import toast from 'react-hot-toast';
const PublicContext = createContext<any>({});

export const PublicContextProvider = ({
  setMaintenanceStatus,
  children
}: JSX.Element | any) => {
  async function getUserIP() {
    try {
      // const response = await fetch('https://api.ipify.org?format=json');
      // const data = await response.json();
      // sessionStorage.setItem('ip', data.ip)
    } catch (error) {
      // sessionStorage.setItem('ip', JSON.stringify(error))
    }
  }

  const [privacyPolicy, setPrivacyPolicy] = useState<any>(null);
  async function loadPrivacyPolicy() {
    await publicService
      .getPrivacyPolicy()
      .then((data) => setPrivacyPolicy(data?.[0]));
  }

  const [faqsData, setFaqsData] = useState<any>(null);
  async function loadFaq() {
    await publicService.getFaq().then((data) => setFaqsData(data));
  }

  const [faqsSectionData, setFaqsSectionData] = useState<any>(null);
  async function loadFaqSection() {
    setFaqsSectionData(null);
    await publicService
      .getFaqSection()
      .then((data) => setFaqsSectionData(data));
  }

  const [faqsTopicData, setFaqsTopicData] = useState<any>(null);
  async function loadFaqTopic(id: any) {
    setFaqsTopicData(null);
    await publicService.getFaqTopic(id).then((data) => setFaqsTopicData(data));
  }

  const [faqsQuestionData, setFaqsQuestionData] = useState<any>(null);
  async function loadFaqQuestion(id: any, searchQuery: string) {
    setFaqsQuestionData(null);
    await publicService
      .getFaqQuestion(id)
      .then((data) => setFaqsQuestionData(data));
  }

  const [searchQuery, setSearchQuery] = useState('');
  const [faqsQuestionSearchedData, setFaqsQuestionSearchedData] =
    useState<any>(null);
  async function searchFaqHandle(searchQuery: string) {
    setFaqsQuestionData(null);
    await publicService
      .searchFaqQuestion(searchQuery)
      .then((data) => setFaqsQuestionSearchedData(data));
  }

  const [faqsQuestionByIdData, setFaqsQuestionByIdData] = useState<any>(null);
  async function loadFaqQuestionById(id: any, topicId: any) {
    setFaqsQuestionByIdData(null);
    await publicService
      .getFaqQuestionById(id, topicId)
      .then((data) => setFaqsQuestionByIdData(data));
  }

  async function voteHandle(vote: number, questionId: number) {
    toast.promise(
      publicService.submitVote(vote, questionId).then(),
      {
        loading: 'Submitting...',
        success: (data) => {
          return `Your vote means a lot to us, thank you.`;
        },
        error: (err) => {
          return `${err.message.toString()}`;
        }
      },
      {
        success: {
          duration: 6000,
          icon: (
            <img
              className="toast-logo"
              src={`${window.location.protocol}//${window.location.host}/images/basic-logo.png`}
            />
          )
        }
      }
    );
  }

  const [popularQuestionsData, setPopularQuestionsData] = useState<any>(null);
  async function loadPopularQuestions() {
    setFaqsQuestionData(null);
    await publicService
      .getPopularQuestions()
      .then((data) => setPopularQuestionsData(data));
  }

  const [islandQuestionsData, setIslandQuestionsData] = useState<any>(null);
  async function loadIslandQuestions(id: number) {
    setFaqsQuestionData(null);
    await publicService
      .getFaqQuestionsByIslandId(id)
      .then((data) => setIslandQuestionsData(data));
  }

  const [isOpenOnboardingCallCompleted, setIsOpenOnboardingCallCompleted] =
    useState(false);
  const [settings, setSettings] = useState<any>(null);
  async function loadSettings() {
    await profileService
      .getSettings()
      .then((data: any) => setSettings(data?.[0]));
  }

  useEffect(() => {
    getUserIP();
  }, []);

  const navigate = useNavigate();
  const location = useLocation();

  const [isFlowOpen, setFlowOpen] = useState(false);
  const [flowState, setFlowState] = useState<{
    step: number;
    menu: number;
    status: number;
  }>({
    step: 0,
    menu: 0,
    status: 0
  });

  const loadUserSteps = async () => {
    // const response = await wizardService.getStep()
    // response && setFlowState(response)
    // response && setFlowOpen(true)
  };

  const [hasAccess, setHasAccess] = useState<boolean>(false);
  const [currentPath, setCurrentAccess] = useState<string>(location.pathname);

  const getToken = (): string | null =>
    localStorage.getItem(config.service.tokenKey);

  useEffect(() => {
    const currentToken = getToken();
    currentPath !== location.pathname && setCurrentAccess(location.pathname);

    if (currentToken) {
      setHasAccess(true);
    } else {
      location.pathname.toLowerCase() !== '/login' &&
        location.pathname.toLowerCase() !== '/register' &&
        location.pathname.toLowerCase() !== '/forget-password' &&
        location.pathname.toLowerCase() !== '/new-password' &&
        navigate('/login');
      setHasAccess(false);
    }
  });

  useEffect(() => {
    const currentToken = getToken();

    return () => {
      if (currentToken) {
        authService.isLogin().then((key) => {
          if (key) {
            setHasAccess(true);
          } else {
            location.pathname.toLowerCase() !== '/login' &&
              location.pathname.toLowerCase() !== '/register' &&
              location.pathname.toLowerCase() !== '/forget-password' &&
              location.pathname.toLowerCase() !== '/new-password' &&
              navigate('/login');
            setHasAccess(false);
          }
        });
      } else {
        location.pathname.toLowerCase() !== '/login' &&
          location.pathname.toLowerCase() !== '/register' &&
          location.pathname.toLowerCase() !== '/forget-password' &&
          location.pathname.toLowerCase() !== '/new-password' &&
          navigate('/login');
        setHasAccess(false);
      }
    };
  }, [currentPath]);

  const context = {
    loadPrivacyPolicy,
    privacyPolicy,

    loadFaq,
    faqsData,

    settings,
    loadSettings,
    setMaintenanceStatus,
    faqsSectionData,
    loadFaqSection,
    isOpenOnboardingCallCompleted,
    setIsOpenOnboardingCallCompleted,
    faqsTopicData,
    loadFaqTopic,
    faqsQuestionData,
    loadFaqQuestion,
    popularQuestionsData,
    loadPopularQuestions,
    faqsQuestionByIdData,
    loadFaqQuestionById,
    voteHandle,
    searchFaqHandle,
    faqsQuestionSearchedData,
    setFaqsQuestionSearchedData,
    searchQuery,
    setSearchQuery,
    islandQuestionsData,
    setIslandQuestionsData,
    loadIslandQuestions
  };

  return (
    <PublicContext.Provider value={context}>{children}</PublicContext.Provider>
  );
};

export default PublicContext;
