import { createContext, useEffect, useState } from 'react';
import toast from 'react-hot-toast';
import accountService from '../../services/accountService';
import walletService from '../../services/walletService';
const AccountContext = createContext<any>({});

import Intercom from '@intercom/messenger-js-sdk';

export const AccountContextProvider = ({ children }: JSX.Element | any) => {
  const [modalDelete, setModalDelete] = useState(false);
  const [modalEdit, setModalEdit] = useState(false);
  const [modalSelect, setModalSelect] = useState(false);
  const [editableData, setEditableData] = useState(null);
  const [allAccounts, setAllAccounts] = useState(null);

  async function loadAccounts() {
    await accountService.getAll().then((account) => setAllAccounts(account));
  }

  const [userInfo, setUserInfo] = useState(null);

  async function loadUserInfo() {
    await accountService.getUserInfo().then((info) => setUserInfo(info?.[0]));
  }

  // useEffect(() => {
  //   if (userInfo) {
  //     // Intercom({
  //     //   app_id: `${process.env.REACT_APP_INTERCOM_APP_ID}`,
  //     //   // user_id: userInfo.user_id,
  //     //   // name: userInfo.username,
  //     //   email: userInfo.user_email,
  //     //   // created_at: userInfo.created_at,
  //     //   user_hash: userInfo.user_hash
  //     // });
  //   }
  // }, [userInfo]);

  const [modalTronWallet, setModalTronWallet] = useState(false);
  const [modalMingoWallet, setModalMingoWallet] = useState(false);

  const [modal, setModal] = useState(false);
  const [locker, setLocker] = useState(false);
  const [wireFormData, setWireFormData] = useState({
    account_name: '',
    account_number: '',
    bank_name: '',
    institution_number: '',
    swift_code: '',
    iban: '',
    bank_address: '',
    recipient_address: '',
    token: ''
  });

  const [ptiWalletFormData, setPtiWalletFormData] = useState({
    account_name: 'PTI Wallet',
    recipient_address: '',
    token: ''
  });
  const [mingoWalletFormData, setMingoWalletFormData] = useState({
    account_name: 'Mingo Wallet',
    recipient_address: '',
    token: ''
  });

  async function setDefaultAccountHandle(id: string) {
    if (!locker) {
      setLocker(true);
      toast.promise(
        accountService.setCurrentAccount(id),
        {
          loading: 'Changing...',
          success: (data: any) => {
            setLocker(false);
            loadAccounts();
            setModalSelect(false);

            return `Changed Successfully.`;
          },
          error: (err: any) => {
            setLocker(false);
            return `${err.message.toString()}`;
          }
        },
        {
          success: {
            duration: 6000,
            icon: (
              <img
                className="toast-logo"
                src={`${window.location.protocol}//${window.location.host}/images/basic-logo.png`}
              />
            )
          }
        }
      );
    }
  }
  async function createWireAccountHandle() {
    if (wireFormData.recipient_address.trim().length === 0) {
      toast.error('Recipient address is mandatory');
      return;
    }

    if (!locker) {
      setLocker(true);
      toast.promise(
        accountService.createNewWire(wireFormData),
        {
          loading: 'Creating...',
          success: (data: any) => {
            setLocker(false);
            setModal(false);

            loadAccounts();

            return `Success.`;
          },
          error: (err: any) => {
            setLocker(false);
            return `${err.message.toString()}`;
          }
        },
        {
          success: {
            duration: 6000,
            icon: (
              <img
                className="toast-logo"
                src={`${window.location.protocol}//${window.location.host}/images/basic-logo.png`}
              />
            )
          }
        }
      );
    }
  }

  const [cryptoFormData, setCryptoFormData] = useState({
    account_name: '',
    account_number: '',
    recipient_address: '',
    token: '',
    is_ultimo: '',
    ultimo_token: ''
  });

  async function createCryptoAccountHandle() {
    if (!locker) {
      setLocker(true);
      toast.promise(
        accountService.createNewCrypto(cryptoFormData),
        {
          loading: 'Creating...',
          success: (data: any) => {
            setLocker(false);
            setModal(false);

            loadAccounts();

            return `Success.`;
          },
          error: (err: any) => {
            setLocker(false);
            return `${err.message.toString()}`;
          }
        },
        {
          success: {
            duration: 6000,
            icon: (
              <img
                className="toast-logo"
                src={`${window.location.protocol}//${window.location.host}/images/basic-logo.png`}
              />
            )
          }
        }
      );
    }
  }

  async function createPTIAccountHandle() {
    if (!locker) {
      setLocker(true);
      toast.promise(
        accountService.createPTIWallet(ptiWalletFormData),
        {
          loading: 'Creating...',
          success: (data: any) => {
            setLocker(false);
            setModalTronWallet(false);

            loadAccounts();

            return `Success.`;
          },
          error: (err: any) => {
            setLocker(false);
            return `${err.message.toString()}`;
          }
        },
        {
          success: {
            duration: 6000,
            icon: (
              <img
                className="toast-logo"
                src={`${window.location.protocol}//${window.location.host}/images/basic-logo.png`}
              />
            )
          }
        }
      );
    }
  }

  async function createMingoAccountHandle() {
    if (!locker) {
      setLocker(true);
      toast.promise(
        accountService.createMingoWallet(mingoWalletFormData),
        {
          loading: 'Creating...',
          success: (data: any) => {
            setLocker(false);
            setModalMingoWallet(false);

            loadAccounts();

            return `Success.`;
          },
          error: (err: any) => {
            setLocker(false);
            return `${err.message.toString()}`;
          }
        },
        {
          success: {
            duration: 6000,
            icon: (
              <img
                className="toast-logo"
                src={`${window.location.protocol}//${window.location.host}/images/basic-logo.png`}
              />
            )
          }
        }
      );
    }
  }

  async function deleteAccountHandle(id: string, token: string) {
    if (!locker) {
      setLocker(true);
      toast.promise(
        accountService.delete(id, token),
        {
          loading: 'Waiting...',
          success: (data: any) => {
            setLocker(false);
            setModalDelete(false);

            loadAccounts();

            return `Success.`;
          },
          error: (err: any) => {
            setLocker(false);
            return `${err.message.toString()}`;
          }
        },
        {
          success: {
            duration: 6000,
            icon: (
              <img
                className="toast-logo"
                src={`${window.location.protocol}//${window.location.host}/images/basic-logo.png`}
              />
            )
          }
        }
      );
    }
  }

  async function editAccountHandle(id: string) {
    // if (!locker) {
    setLocker(true);
    toast.promise(
      accountService.edit(id, editableData),
      {
        loading: 'Waiting...',
        success: (data: any) => {
          setLocker(false);
          setModalEdit(false);

          loadAccounts();

          return `Success.`;
        },
        error: (err: any) => {
          setLocker(false);
          return `${err.message.toString()}`;
        }
      },
      {
        success: {
          duration: 6000,
          icon: (
            <img
              className="toast-logo"
              src={`${window.location.protocol}//${window.location.host}/images/basic-logo.png`}
            />
          )
        }
      }
    );
    // }
  }

  async function acceptHandle() {
    if (!locker) {
      setLocker(true);
      toast.promise(
        walletService.agreeTerms(),
        {
          loading: 'Checking terms...',
          success: (data) => {
            setLocker(false);
            loadUserInfo();
            return `Success.`;
          },
          error: (err) => {
            setLocker(false);
            return `${err.message.toString()}`;
          }
        },
        {
          success: {
            duration: 6000,
            icon: (
              <img
                className="toast-logo"
                src={`${window.location.protocol}//${window.location.host}/images/basic-logo.png`}
              />
            )
          }
        }
      );
    }
  }

  const context = {
    allAccounts,
    loadAccounts,
    acceptHandle,

    userInfo,
    loadUserInfo,

    setDefaultAccountHandle,

    wireFormData,
    setWireFormData,
    createWireAccountHandle,

    cryptoFormData,
    setCryptoFormData,
    createCryptoAccountHandle,
    createPTIAccountHandle,
    deleteAccountHandle,
    modalDelete,
    setModalDelete,
    createMingoAccountHandle,
    modalEdit,
    setModalEdit,
    editableData,
    setEditableData,
    editAccountHandle,
    modalSelect,
    setModalSelect,
    modal,
    setModal,
    modalTronWallet,
    setModalTronWallet,
    ptiWalletFormData,
    setPtiWalletFormData,
    mingoWalletFormData,
    setMingoWalletFormData,
    setModalMingoWallet,
    modalMingoWallet
  };

  return (
    <AccountContext.Provider value={context}>
      {children}
    </AccountContext.Provider>
  );
};

export default AccountContext;
