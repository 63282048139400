import { createContext, useContext, useEffect, useState } from 'react';
import toast from 'react-hot-toast';
import authService from '../../services/authService';
import membershipService from '../../services/membershipService';
import sumsubService from '../../services/sumsubService';
import wizardService from '../../services/wizardService';
import { ApplicationForm, WatchVideoForm } from '../../types/wizardTypes';
import wazardValidation from '../../validations/wazardValidation';
import PrivateContext from './privateContext';
import walletService from '../../services/walletService';
import AccountContext from './accountContext';
import { useNavigate } from 'react-router-dom';
const WizardContext = createContext<any>({});

export const WizardContextProvider = ({ children }: JSX.Element | any) => {
  const [locker, setLocker] = useState(false);
  const { flowState, setFlowState, setUserInfo, setUpdateTimer } =
    useContext(PrivateContext);

  const [datePickerRegisterPending, setDatePickerRegisterPending] = useState(
    new Date()
  );
  const [datePickerWireTransfer, setDatePickerWireTransfer] = useState(
    new Date()
  );
  const [datePickerWireExp, setDatePickerWireExp] = useState(new Date());
  const [datePickerCryptoTransfer, setDatePickerCryptoTransfer] = useState(
    new Date()
  );

  const [isVerificationCodeSentToEmail, setIsVerificationCodeSentToEmail] =
    useState(false);
  const [isVerificationCodeSentToPhone, setIsVerificationCodeSentToPhone] =
    useState(false);
  const [isVerificationCodeSentToUpline, setIsVerificationCodeSentToUpline] =
    useState(false);

  const [modal, setModal] = useState(false);

  const [questions, setQuestion] = useState<any>([]);
  const [answers, setAnswers] = useState([]);
  const [finalAnswers, setFinalAnswers] = useState([]);
  const [isAllAnswersCurrect, setIsAllAnswersCurrect] = useState(false);

  const [accountTypes, setAccountTypes] = useState<
    [{ id: number; name: string }] | null | undefined
  >(null);
  const [paymentInfo, setPaymentInfo] = useState<
    { wire: [{}]; crypto: [{}] } | null | undefined
  >(null);
  const [paymentCheckUser, setPaymentCheckUser] = useState<any>(null);
  const [countriesList, setCountriesList] = useState<
    | [
      {
        id: number;
        name: string;
        logo: string;
        suffixes: string;
        root: string;
      }
    ]
    | null
    | undefined
  >(null);
  const [accountDescription, setAccountDescription] = useState<
    string | null | undefined
  >('');

  const [applicationFormData, setApplicationFormData] = useState({
    first_name: '',
    middle_name: '',
    last_name: '',
    home_address: '',
    zip_code: '',
    street_address: '',
    telegram_username: '',
    social_x_link: '',
    social_linkdin_link: '',
    social_facebook_link: '',
    country_id: 'null',
    street_address2: '',
    city: '',
    user_type: null,
    birthday: '',
    state_provence: '',
    organization_name: '',
    organization_identification: '',
    beneficiary_name: '',
    beneficiary_email: '',
    front: null,
    back: null,
    proof: null,
    second_register: false
  });

  const [membershipFormDataCrypto, setMembershipFormDataCrypto] = useState({
    transfer_date: '',
    type: 3,
    token: '',
    txHash: ''
  });
  const [membershipFormDataCryptoRenew, setMembershipFormDataCryptoRenew] = useState({
    account_id: '',
    transfer_date: '',
    type: 3,
    token: '',
    txHash: ''
  });
  const [membershipFormDataBosBalanceRenew, setMembershipFormDataBosBalanceRenew] = useState({
    type: 4
  });

  useEffect(() => {
    setMembershipFormDataCrypto({
      ...membershipFormDataCrypto,
      transfer_date: `${+new Date(datePickerCryptoTransfer)}`
    });
  }, [datePickerCryptoTransfer]);

  const [membershipFormDataWire, setMembershipFormDataWire] = useState({
    account_name: '',
    account_number: '',
    transfer_date: '',
    bank_name: '',
    expected_date: '',
    type: 2,
    transaction_document: '',
    transaction_document_2: '',
    transaction_document_3: '',

    txHash: ''
  });

  const [membershipFormDataWireRenew, setMembershipFormDataWireRenew] = useState({
    transaction_document: '',
    transaction_document_2: '',
    transaction_document_3: '',
    account_id: '',
    transfer_date: '',
    expected_date: '',
    type: 2,
    bank_type: 6,

    txHash: '',
    token: ''
  });

  // TODO: fix type
  const [expireTimeVCToEmail, setExpireTimeVCToEmail] = useState<
    number | null | undefined
  >(null);
  const [expireTimeVCToPhone, setExpireTimeVCToPhone] = useState<
    number | null | undefined
  >(null);
  const [expireTimeVCToUpline, setExpireTimeVCToUpline] = useState<
    number | null | undefined
  >(null);

  const [verificationData, setVerificationData] = useState({
    email: -1,
    phone: -1,
    upline: -1
  });

  const [isError, setIsError] = useState('');

  const [userType, setUserType] = useState<number | null | undefined>(null);
  async function loadUserType() {
    await wizardService.getUserType().then((data) => {
      setUserType(data?.user_type);
    });
  }
  async function loadVerificationData() {
    await wizardService.getVerificationData().then((data) => {
      setVerificationData({
        ...verificationData,
        email: data?.email,
        phone: data?.phone_number,
        upline: data?.upline
      });
    });
  }

  async function skipConnectorVerificationHandle() {
    if (!locker) {
      setLocker(true);

      toast.promise(
        authService
          .skipConnectorVerification()
          .then((response) =>
            setFlowState({ ...flowState, step: response?.flow?.step })
          ),
        {
          loading: 'Preparing...',
          success: (data: number | null | undefined) => {
            setLocker(false);
            return `Sent.`;
          },
          error: (err) => {
            setLocker(false);
            return `${err.message.toString()}`;
          }
        },
        {
          success: {
            duration: 6000,
            icon: (
              <img
                className="toast-logo"
                src={`${window.location.protocol}//${window.location.host}/images/basic-logo.png`}
              />
            )
          }
        }
      );
    }
  }
  async function emailOTPHandle() {
    if (!locker) {
      setLocker(true);
      toast.promise(
        wizardService.sendEmailVerification().then(),
        {
          loading: 'Your verification code is on its way to your inbox...',
          success: (data: number | null | undefined) => {
            loadVerificationData();
            setExpireTimeVCToEmail(data);
            setLocker(false);
            return `Sent.`;
          },
          error: (err) => {
            setLocker(false);
            return `${err.message.toString()}`;
          }
        },
        {
          success: {
            duration: 6000,
            icon: (
              <img
                className="toast-logo"
                src={`${window.location.protocol}//${window.location.host}/images/basic-logo.png`}
              />
            )
          }
        }
      );
    }
  }
  async function phoneOTPHandle() {
    if (!locker) {
      setLocker(true);
      toast.promise(
        wizardService.sendPhoneVerification().then(),
        {
          loading: 'Your verification code is on its way to your phone...',
          success: (data: number | null | undefined) => {
            loadVerificationData();
            setExpireTimeVCToPhone(data);
            // localStorage.setItem("expireTimeVCToPhone", JSON.stringify(data))
            // setIsVerificationCodeSentToPhone(true)
            setLocker(false);
            return `Sent.`;
          },
          error: (err) => {
            setLocker(false);
            return `${err.message.toString()}`;
          }
        },
        {
          success: {
            duration: 6000,
            icon: (
              <img
                className="toast-logo"
                src={`${window.location.protocol}//${window.location.host}/images/basic-logo.png`}
              />
            )
          }
        }
      );
    }
  }
  async function uplineOTPHandle() {
    if (!locker) {
      setLocker(true);
      toast.promise(
        wizardService.sendUplineVerification().then(),
        {
          loading: `A verification code is on its way to your Connector’s inbox...`,
          success: (data: number | null | undefined) => {
            loadVerificationData();
            setExpireTimeVCToUpline(data);
            // localStorage.setItem("expireTimeVCToUpline", JSON.stringify(data))
            // setIsVerificationCodeSentToUpline(true)
            setLocker(false);
            return `Sent.`;
          },
          error: (err) => {
            setLocker(false);
            return `${err.message.toString()}`;
          }
        },
        {
          success: {
            duration: 6000,
            icon: (
              <img
                className="toast-logo"
                src={`${window.location.protocol}//${window.location.host}/images/basic-logo.png`}
              />
            )
          }
        }
      );
    }
  }
  async function emailVerifyOTPHandle(
    code: string,
    setState?: (value: boolean) => void
  ) {
    setIsError('');
    const finalCode = code.toString().replaceAll(',', '');
    if (!locker) {
      setLocker(true);
      toast.promise(
        wizardService
          .checkEmailVerificationCode(finalCode)
          .then((response) =>
            setFlowState({ ...flowState, step: response?.flow?.step })
          ),
        {
          loading: 'Checking Verification Code...',
          success: (data: number | null | undefined) => {
            setIsVerificationCodeSentToEmail(true);
            setLocker(false);
            return `Your Email is Verified.`;
          },
          error: (err) => {
            setLocker(false);
            setIsError('Error');
            return `${err.message.toString()}`;
          }
        },
        {
          success: {
            duration: 6000,
            icon: (
              <img
                className="toast-logo"
                src={`${window.location.protocol}//${window.location.host}/images/basic-logo.png`}
              />
            )
          }
        }
      );
    }
  }
  async function phoneVerifyOTPHandle(code: string) {
    const finalCode = code.toString().replaceAll(',', '');

    if (!locker) {
      setLocker(true);
      toast.promise(
        wizardService
          .checkPhoneVerificationCode(finalCode)
          .then((response) =>
            setFlowState({ ...flowState, step: response?.flow?.step })
          ),
        {
          loading: 'Checking Verification Code...',
          success: (data: number | null | undefined) => {
            setIsVerificationCodeSentToEmail(true);
            setLocker(false);
            return `Your Phone is Verified.`;
          },
          error: (err) => {
            setLocker(false);
            return `${err.message.toString()}`;
          }
        },
        {
          success: {
            duration: 6000,
            icon: (
              <img
                className="toast-logo"
                src={`${window.location.protocol}//${window.location.host}/images/basic-logo.png`}
              />
            )
          }
        }
      );
    }
  }
  async function uplineVerifyOTPHandle(code: string) {
    const finalCode = code.toString().replaceAll(',', '');

    if (!locker) {
      setLocker(true);
      toast.promise(
        wizardService
          .checkUplineVerificationCode(finalCode)
          .then((response) =>
            setFlowState({ ...flowState, step: response?.flow?.step })
          ),
        {
          loading: 'Checking Verification Code...',
          success: (data: number | null | undefined) => {
            setIsVerificationCodeSentToUpline(true);
            setLocker(false);
            return `Your Connector is Verified.`;
          },
          error: (err) => {
            setLocker(false);
            return `${err.message.toString()}`;
          }
        },
        {
          success: {
            duration: 6000,
            icon: (
              <img
                className="toast-logo"
                src={`${window.location.protocol}//${window.location.host}/images/basic-logo.png`}
              />
            )
          }
        }
      );
    }
  }
  async function finishVerificationWithOTP() {
    toast.promise(
      wizardService
        .updateStep(4)
        .then(() => setFlowState({ ...flowState, step: 4, menu: 1 })),
      {
        loading: 'Preparing...',
        success: (data: number | null | undefined) => {
          return `Get Started`;
        },
        error: (err) => {
          return `${err.message.toString()}`;
        }
      },
      {
        success: {
          duration: 6000,
          icon: (
            <img
              className="toast-logo"
              src={`${window.location.protocol}//${window.location.host}/images/basic-logo.png`}
            />
          )
        }
      }
    );
  }

  async function firstWelcomeVideoBack() {
    if (!locker) {
      setLocker(true);
      toast.promise(
        wizardService
          .updateStep(4)
          .then(() => setFlowState({ ...flowState, step: 4 })),
        {
          loading: 'Preparing...',
          success: (data) => {
            setLocker(false);
            setUpdateTimer(+new Date());
            return `Done`;
          },
          error: (err) => {
            setLocker(false);
            return `${err.message.toString()}`;
          }
        },
        {
          success: {
            duration: 6000,
            icon: (
              <img
                className="toast-logo"
                src={`${window.location.protocol}//${window.location.host}/images/basic-logo.png`}
              />
            )
          }
        }
      );
    }
  }
  async function secondWelcomeVideoBack() {
    if (!locker) {
      setLocker(true);
      toast.promise(
        wizardService
          .updateStep(5)
          .then(() => setFlowState({ ...flowState, step: 5 })),
        {
          loading: 'Preparing...',
          success: (data) => {
            setLocker(false);
            setUpdateTimer(+new Date());
            return `Done`;
          },
          error: (err) => {
            setLocker(false);
            return `${err.message.toString()}`;
          }
        },
        {
          success: {
            duration: 6000,
            icon: (
              <img
                className="toast-logo"
                src={`${window.location.protocol}//${window.location.host}/images/basic-logo.png`}
              />
            )
          }
        }
      );
    }
  }
  async function thirdWelcomeVideoBack() {
    if (!locker) {
      setLocker(true);
      toast.promise(
        wizardService
          .updateStep(5)
          .then(() => setFlowState({ ...flowState, step: 5 })),
        {
          loading: 'Preparing...',
          success: (data) => {
            setLocker(false);
            setUpdateTimer(+new Date());
            return `Done`;
          },
          error: (err) => {
            setLocker(false);
            return `${err.message.toString()}`;
          }
        },
        {
          success: {
            duration: 6000,
            icon: (
              <img
                className="toast-logo"
                src={`${window.location.protocol}//${window.location.host}/images/basic-logo.png`}
              />
            )
          }
        }
      );
    }
  }
  async function fourthWelcomeVideoBack() {
    if (!locker) {
      setLocker(true);
      toast.promise(
        wizardService
          .updateStep(7)
          .then(() => setFlowState({ ...flowState, step: 7 })),
        {
          loading: 'Preparing...',
          success: (data) => {
            setLocker(false);
            setUpdateTimer(+new Date());
            return `Done`;
          },
          error: (err) => {
            setLocker(false);
            return `${err.message.toString()}`;
          }
        },
        {
          success: {
            duration: 6000,
            icon: (
              <img
                className="toast-logo"
                src={`${window.location.protocol}//${window.location.host}/images/basic-logo.png`}
              />
            )
          }
        }
      );
    }
  }

  async function firstWelcomeVideo() {
    if (!locker) {
      setLocker(true);
      toast.promise(
        wizardService
          .updateStep(5)
          .then(() => setFlowState({ ...flowState, step: 5 })),
        {
          loading: 'Preparing...',
          success: (data) => {
            setLocker(false);
            setUpdateTimer(+new Date());
            return `Done`;
          },
          error: (err) => {
            setLocker(false);
            return `${err.message.toString()}`;
          }
        },
        {
          success: {
            duration: 6000,
            icon: (
              <img
                className="toast-logo"
                src={`${window.location.protocol}//${window.location.host}/images/basic-logo.png`}
              />
            )
          }
        }
      );
    }
  }
  async function secondWelcomeVideo() {
    if (!locker) {
      setLocker(true);
      toast.promise(
        wizardService
          .updateStep(7)
          .then(() => setFlowState({ ...flowState, step: 7 })),
        {
          loading: 'Preparing...',
          success: (data) => {
            setLocker(false);
            setUpdateTimer(+new Date());
            return `Done`;
          },
          error: (err) => {
            setLocker(false);
            return `${err.message.toString()}`;
          }
        },
        {
          success: {
            duration: 6000,
            icon: (
              <img
                className="toast-logo"
                src={`${window.location.protocol}//${window.location.host}/images/basic-logo.png`}
              />
            )
          }
        }
      );
    }
  }
  async function thirdWelcomeVideo() {
    if (!locker) {
      setLocker(true);
      toast.promise(
        wizardService
          .updateStep(7)
          .then(() => setFlowState({ ...flowState, step: 7 })),
        {
          loading: 'Preparing...',
          success: (data) => {
            setLocker(false);
            setUpdateTimer(+new Date());
            return `Done`;
          },
          error: (err) => {
            setLocker(false);
            return `${err.message.toString()}`;
          }
        },
        {
          success: {
            duration: 6000,
            icon: (
              <img
                className="toast-logo"
                src={`${window.location.protocol}//${window.location.host}/images/basic-logo.png`}
              />
            )
          }
        }
      );
    }
  }
  async function fourthWelcomeVideo() {
    if (!locker) {
      setLocker(true);
      toast.promise(
        wizardService
          .updateStep(8)
          .then(() => setFlowState({ ...flowState, step: 8 })),
        {
          loading: 'Preparing...',
          success: (data) => {
            setLocker(false);
            setUpdateTimer(+new Date());
            return `Done`;
          },
          error: (err) => {
            setLocker(false);
            return `${err.message.toString()}`;
          }
        },
        {
          success: {
            duration: 6000,
            icon: (
              <img
                className="toast-logo"
                src={`${window.location.protocol}//${window.location.host}/images/basic-logo.png`}
              />
            )
          }
        }
      );
    }
  }

  async function loadQuestionsInWatchVideo() {
    await wizardService.getQuestionVideo().then((q) => setQuestion(q));
  }

  const [questionsAnswers, setQuestionsAnswers] = useState({
    answer1: "",
    answer2: "",
    answer3: "",
    answer4: "",
  })

  async function watchVideoVerifyHandle() {
    const questionContainer: string[] = [];
    questions.map((obj: any) => questionContainer.push(obj?.id));

    const watchVideoForm = {
      ...questionsAnswers,
      watch_video: 1,
    };

    if (!locker) {
      setLocker(true);
      toast.promise(
        wizardService
          .verifyWatchVideo(watchVideoForm)
          .then((response) =>
            setFlowState({ ...flowState, step: response?.flow?.step })
          ),
        {
          loading: 'Checking terms...',
          success: (data: number | null | undefined) => {
            // setIsVerificationCodeSentToEmail(true)
            setLocker(false);
            return `In the next step, complete the application form .`;
          },
          error: (err) => {
            setLocker(false);
            return `${err.message.toString()}`;
          }
        },
        {
          success: {
            duration: 6000,
            icon: (
              <img
                className="toast-logo"
                src={`${window.location.protocol}//${window.location.host}/images/basic-logo.png`}
              />
            )
          }
        }
      );
    }
  }

  async function loadAccount() {
    await wizardService.getAccountType().then((type) => setAccountTypes(type));
  }
  async function loadPaymenInfo() {
    await membershipService
      .getPaymentInfo()
      .then((info) => setPaymentInfo(info));
  }
  async function loadPaymenCheckMigratedUser() {
    await membershipService
      .getCheckUserMigrated()
      .then((info) => setPaymentCheckUser(info));
  }
  async function loadAccountDescription() {
    await wizardService
      .getAccountTypeDescription()
      .then((description) => setAccountDescription(description));
  }

  async function firstStepApplicationForm(type: any) {
    if (!locker) {
      setLocker(true);
      toast.promise(
        wizardService
          .updateAccountType(type)
          .then((response) =>
            setFlowState({ ...flowState, step: response?.flow?.step })
          ),
        {
          loading: 'Preparing...',
          success: (data) => {
            setLocker(false);
            return `Your account type has been set`;
          },
          error: (err) => {
            setLocker(false);
            return `${err.message.toString()}`;
          }
        },
        {
          success: {
            duration: 6000,
            icon: (
              <img
                className="toast-logo"
                src={`${window.location.protocol}//${window.location.host}/images/basic-logo.png`}
              />
            )
          }
        }
      );
    }
  }

  const [userData, setUserData] = useState({
    code: null,
    phone: null,
    countryId: null
  });
  async function loadUserData() {
    await wizardService.getUserData().then((data) => {
      setUserData({
        ...userData,
        code: data?.Countries,
        phone: data?.User[0]?.phone_number,
        countryId: data?.User[0]?.country_id
      });
    });
  }

  const [isModalChangeNumber, setIsModalChangeNumber] = useState(false);
  async function updatePhoneNumberHandle(newData: any) {
    if (!locker) {
      setLocker(true);
      toast.promise(
        wizardService.updatePhoneNumber(newData).then(() => loadUserData()),
        {
          loading: 'Preparing...',
          success: (data) => {
            setLocker(false);
            setIsModalChangeNumber(false);
            return `Update Successfully.`;
          },
          error: (err) => {
            setLocker(false);
            return `${err.message.toString()}`;
          }
        },
        {
          success: {
            duration: 6000,
            icon: (
              <img
                className="toast-logo"
                src={`${window.location.protocol}//${window.location.host}/images/basic-logo.png`}
              />
            )
          }
        }
      );
    }
  }

  async function finalStepToStartMyMap() {
    if (!locker) {
      setLocker(true);
      toast.promise(
        wizardService
          .updateStep(17)
          .then(() => setFlowState({ ...flowState, step: 17, menu: 7 })),
        {
          loading: 'Preparing...',
          success: (data) => {
            setLocker(false);
            return `Welcome`;
          },
          error: (err) => {
            setLocker(false);
            return `${err.message.toString()}`;
          }
        },
        {
          success: {
            duration: 6000,
            icon: (
              <img
                className="toast-logo"
                src={`${window.location.protocol}//${window.location.host}/images/basic-logo.png`}
              />
            )
          }
        }
      );
    }
  }
  async function backToFirstStepApplicationForm() {
    if (!locker) {
      setLocker(true);
      toast.promise(
        wizardService
          .updateStep(9)
          .then(() => setFlowState({ ...flowState, step: 9 })),
        {
          loading: 'Preparing...',
          success: (data) => {
            setLocker(false);
            return ``;
          },
          error: (err) => {
            setLocker(false);
            return `${err.message.toString()}`;
          }
        },
        {
          success: {
            duration: 6000,
            icon: (
              <img
                className="toast-logo"
                src={`${window.location.protocol}//${window.location.host}/images/basic-logo.png`}
              />
            )
          }
        }
      );
    }
  }

  const [isLockedBtnApplicationForm, setIsLockedBtnApplicationForm] =
    useState(false);
  const [age, setAge] = useState(-1);
  const [errorsApplicationForm, setErrorsApplicationForm] = useState<any>({});
  async function applicationFormHandle(formData: ApplicationForm, data: any) {
    const errors0 = wazardValidation.personalRegisterPending(data);
    let errors1;
    let errors2;
    if (Number(data.user_type) !== 5) {
      const finalData = {
        organization_name: data.organization_name
        // organization_address: data.organization_address,
      };
      errors1 = wazardValidation.trustRegisterPending(data);
    }
    if (
      Number(data.user_type) == 1 ||
      Number(data.user_type) == 3 ||
      Number(data.user_type) == 4
    ) {
      errors2 = wazardValidation.othersRegisterPending(data);
    }

    const errors = Object.assign(errors0, errors1, errors2);
    if (Object.keys(errors).length === 0 && age !== -1 && age >= 18) {
      if (datePickerRegisterPending) {
        if (!isLockedBtnApplicationForm) {
          setIsLockedBtnApplicationForm(true);
          toast.promise(
            wizardService
              .applicationForm(formData)
              .then(() => wizardService.updateStep(20).then()),
            {
              loading: 'Waiting for submiting the form...',
              success: (data) => {
                setIsLockedBtnApplicationForm(false);
                window.location.reload();
                return `Submitted.`;
              },
              error: (err) => {
                setIsLockedBtnApplicationForm(false);
                return `${err.message.toString()}`;
              }
            },
            {
              success: {
                duration: 6000,
                icon: (
                  <img
                    className="toast-logo"
                    src={`${window.location.protocol}//${window.location.host}/images/basic-logo.png`}
                  />
                )
              }
            }
          );
        }
      } else {
        toast.error('"Date of birth" is required.');
      }
    } else {
      age === -1 && setAge(0);
      setErrorsApplicationForm(errors);
      setIsLockedBtnApplicationForm(false);
    }
  }
  async function loadCountries() {
    await wizardService.getCountries().then((list) => setCountriesList(list));
  }
  async function loadCounts() {
    const response = await sumsubService.getSumsubInfoBasic().then((info) => {
      if (info.accessToken?.token === null) {
        loadCounts();
      }
    });
  }

  const [isCheckRejection, setIsCheckRejection] = useState(false);
  const [registerPendingDescription, setRegisterPendingDescription] =
    useState('');
  async function loadRegisterPendingFormData() {
    setRegisterPendingDescription('');
    await wizardService.getUserRegisterPendingFormData().then((data) => {
      setApplicationFormData({
        ...applicationFormData,
        // user_type: data?.User[0]?.user_type ? data?.User[0]?.user_type : '5',
        zip_code: data?.User[0]?.zip_code ? data?.User[0]?.zip_code : '',
        street_address: data?.User[0]?.street_address
          ? data?.User[0]?.street_address
          : '',
        telegram_username: data?.User[0]?.telegram_username
          ? data?.User[0]?.telegram_username
          : '',
        social_x_link: data?.User[0]?.social_x_link
          ? data?.User[0]?.social_x_link
          : '',
        social_linkdin_link: data?.User[0]?.social_linkdin_link
          ? data?.User[0]?.social_linkdin_link
          : '',
        social_facebook_link: data?.User[0]?.social_facebook_link
          ? data?.User[0]?.social_facebook_link
          : '',
        country_id: data?.User[0]?.country_id
          ? `${data?.User[0]?.country_id}`
          : '',
        street_address2: data?.User[0]?.street_address2
          ? data?.User[0]?.street_address2
          : '',
        city: data?.User[0]?.city ? data?.User[0]?.city : '',
        birthday: data?.User[0]?.birthday ? data?.User[0]?.birthday : '',
        state_provence: data?.User[0]?.state_provence
          ? data?.User[0]?.state_provence
          : '',
        organization_name:
          data?.User[0]?.organization_name &&
            data?.User[0]?.organization_name !== 'undefined'
            ? data?.User[0]?.organization_name
            : '',
        organization_identification:
          data?.User[0]?.organization_identification &&
            data?.User[0]?.organization_identification !== 'undefined'
            ? data?.User[0]?.organization_identification
            : '',
        // organization_address: data?.User[0]?.organization_address && data?.User[0]?.organization_address !== "undefined" ? data?.User[0]?.organization_address : '',
        beneficiary_name: data?.User[0]?.beneficiary_name
          ? data?.User[0]?.beneficiary_name
          : '',
        beneficiary_email: data?.User[0]?.beneficiary_email
          ? data?.User[0]?.beneficiary_email
          : '',
        second_register:
          data?.User[0]?.proof_document?.length > 0 ? true : false
      });

      setRegisterPendingDescription(data?.UserRejected[0]?.description);
    });
    setIsCheckRejection(true);
  }


  const [isUploaded, setIsUploaded] = useState(false)
  async function attachmentUploadHandle(formData: any) {
    if (!locker) {
      setLocker(true);

      toast.promise(
        wizardService
          .uploadAttachment(formData),
        {
          loading: 'Uploading...',
          success: (data) => {
            setLocker(false);
            setIsUploaded(true)
            return `Uploaded.`;
          },
          error: (err) => {
            setLocker(false);
            return `${err.message.toString()}`;
          }
        },
        {
          success: {
            duration: 6000,
            icon: (
              <img
                className="toast-logo"
                src={`${window.location.protocol}//${window.location.host}/images/basic-logo.png`}
              />
            )
          }
        }
      );
    }
  }

  async function SubmittedIGotIt() {
    if (!locker) {
      setLocker(true);
      toast.promise(
        wizardService
          .updateStep(12)
          .then(() => setFlowState({ ...flowState, step: 12 })),
        {
          loading: 'Preparing...',
          success: (data) => {
            setLocker(false);
            return `Application Submitted`;
          },
          error: (err) => {
            setLocker(false);
            return `${err.message.toString()}`;
          }
        },
        {
          success: {
            duration: 6000,
            icon: (
              <img
                className="toast-logo"
                src={`${window.location.protocol}//${window.location.host}/images/basic-logo.png`}
              />
            )
          }
        }
      );
    }
  }
  async function setupNow() {
    if (!locker) {
      setLocker(true);
      toast.promise(
        wizardService
          .updateStep(14)
          .then(() => setFlowState({ ...flowState, step: 14 })),
        {
          loading: 'Preparing...',
          success: (data) => {
            setLocker(false);
            return `First Pay Membership.`;
          },
          error: (err) => {
            setLocker(false);
            return `${err.message.toString()}`;
          }
        },
        {
          success: {
            duration: 6000,
            icon: (
              <img
                className="toast-logo"
                src={`${window.location.protocol}//${window.location.host}/images/basic-logo.png`}
              />
            )
          }
        }
      );
    }
  }

  async function skipSetupNow() {
    if (!locker) {
      setLocker(true);
      toast.promise(
        wizardService
          .updateStep(-1)
          .then(() => setFlowState({ ...flowState, step: -1 })),
        {
          loading: 'Preparing...',
          success: (data) => {
            setLocker(false);
            return `Done.`;
          },
          error: (err) => {
            setLocker(false);
            return `${err.message.toString()}`;
          }
        },
        {
          success: {
            duration: 6000,
            icon: (
              <img
                className="toast-logo"
                src={`${window.location.protocol}//${window.location.host}/images/basic-logo.png`}
              />
            )
          }
        }
      );
    }
  }

  const navigate = useNavigate();
  const [isLockedBtnPayFee, setIsLockedBtnPayFee] = useState(false);
  const [errorsCryptoFee, setErrorsCryptoFee] = useState<any>({});
  const [errorsWireFee, setErrorsWireFee] = useState<any>({});
  async function payFeeHandle(formData: any, data: any, type: string) {
    if (!locker) {
      setLocker(true);
      if (type === 'crypto') {
        const errorsCrypto = wazardValidation.membershipFeeCrypto(formData);
        setIsLockedBtnPayFee(true);
        if (Object.keys(errorsCrypto).length === 0) {
          if (datePickerCryptoTransfer) {
            toast.promise(
              membershipService
                .payFee(formData)
                .then((response) =>
                  setFlowState({ ...flowState, step: response?.flow?.step })
                ),
              {
                loading: 'Preparing...',
                success: (data: any) => {
                  setLocker(false);
                  setIsLockedBtnPayFee(false);
                  setModal(false);
                  return `Done.`;
                },
                error: (err: any) => {
                  setIsLockedBtnPayFee(false);
                  setLocker(false);
                  return `${err.message.toString()}`;
                }
              },
              {
                success: {
                  duration: 6000,
                  icon: (
                    <img
                      className="toast-logo"
                      src={`${window.location.protocol}//${window.location.host}/images/basic-logo.png`}
                    />
                  )
                }
              }
            );
          } else {
            toast.error('"Transfer date" is required.');
            setIsLockedBtnPayFee(false);
            setLocker(false);
          }
        } else {
          setErrorsCryptoFee(errorsCrypto);
          setIsLockedBtnPayFee(false);
          setLocker(false);
        }
      }

      if (type === 'wire') {
        const errorsWire = wazardValidation.membershipFeeWire(data);

        setIsLockedBtnPayFee(true);
        if (Object.keys(errorsWire).length === 0) {
          if (
            Object.keys(data).length !== 0 &&
            data?.transaction_document?.size > 0
          ) {
            if (datePickerWireExp && datePickerWireTransfer) {
              toast.promise(
                membershipService
                  .payFee(formData)
                  .then((response) =>
                    setFlowState({ ...flowState, step: response?.flow?.step })
                  ),
                {
                  loading: 'Preparing...',
                  success: (data: any) => {
                    setLocker(false);
                    setIsLockedBtnPayFee(false);
                    setModal(false);
                    return `Done.`;
                  },
                  error: (err: any) => {
                    setIsLockedBtnPayFee(false);
                    setLocker(false);
                    return `${err.message.toString()}`;
                  }
                },
                {
                  success: {
                    duration: 6000,
                    icon: (
                      <img
                        className="toast-logo"
                        src={`${window.location.protocol}//${window.location.host}/images/basic-logo.png`}
                      />
                    )
                  }
                }
              );
            } else {
              toast.error('Both date are required.');
              setIsLockedBtnPayFee(false);
              setLocker(false);
            }
          } else {
            toast.error('Bank transaction receipt is required.');
            setIsLockedBtnPayFee(false);
            setLocker(false);
          }
        } else {
          setErrorsWireFee(errorsWire);
          setIsLockedBtnPayFee(false);
          setLocker(false);
        }
      }
    }
  }

  const { loadUserInfo } = useContext(AccountContext)



  async function payFeeRenewHandle(formData: any, data: any, type: string) {
    if (!locker) {
      setLocker(true);
      if (type === 'crypto') {
        // const errorsCrypto = wazardValidation.membershipFeeCrypto(formData);
        // setIsLockedBtnPayFee(true);
        // if (Object.keys(errorsCrypto).length === 0) {
        //   if (datePickerCryptoTransfer) {
        toast.promise(
          membershipService
            .payFeeRenew(formData)
            .then((response) =>
              loadUserInfo()
            ),
          {
            loading: 'Preparing...',
            success: (data: any) => {
              setLocker(false);
              setIsLockedBtnPayFee(false);
              setModal(false);

              navigate("/")
              return `Done.`;
            },
            error: (err: any) => {
              setIsLockedBtnPayFee(false);
              setLocker(false);
              return `${err.message.toString()}`;
            }
          },
          {
            success: {
              duration: 6000,
              icon: (
                <img
                  className="toast-logo"
                  src={`${window.location.protocol}//${window.location.host}/images/basic-logo.png`}
                />
              )
            }
          }
        );
        //   } else {
        //     toast.error('"Transfer date" is required.');
        //     setIsLockedBtnPayFee(false);
        //     setLocker(false);
        //   }
        // } else {
        //   setErrorsCryptoFee(errorsCrypto);
        //   setIsLockedBtnPayFee(false);
        //   setLocker(false);
        // }
      }

      if (type === 'wire') {
        // const errorsWire = wazardValidation.membershipFeeWire(data);

        // setIsLockedBtnPayFee(true);
        // if (Object.keys(errorsWire).length === 0) {
        //   if (
        //     Object.keys(data).length !== 0 &&
        //     data?.transaction_document?.size > 0
        //   ) {
        //     if (datePickerWireExp && datePickerWireTransfer) {
        toast.promise(
          membershipService
            .payFeeRenew(formData)
            .then((response) =>
              loadUserInfo()
            ),
          {
            loading: 'Preparing...',
            success: (data: any) => {
              setLocker(false);
              setIsLockedBtnPayFee(false);
              setModal(false);

              navigate("/")
              return `Done.`;
            },
            error: (err: any) => {
              setIsLockedBtnPayFee(false);
              setLocker(false);
              return `${err.message.toString()}`;
            }
          },
          {
            success: {
              duration: 6000,
              icon: (
                <img
                  className="toast-logo"
                  src={`${window.location.protocol}//${window.location.host}/images/basic-logo.png`}
                />
              )
            }
          }
        );
        //   } else {
        //     toast.error('Both date are required.');
        //     setIsLockedBtnPayFee(false);
        //     setLocker(false);
        //   }
        // } else {
        //   toast.error('Bank transaction receipt is required.');
        //   setIsLockedBtnPayFee(false);
        //   setLocker(false);
        // }
        // } else {
        //   setErrorsWireFee(errorsWire);
        //   setIsLockedBtnPayFee(false);
        //   setLocker(false);
        // }
      }

      if (type === 'balance') {
        toast.promise(
          membershipService
            .payFeeRenew(formData)
            .then((response) =>
              loadUserInfo()
            ),
          {
            loading: 'Preparing...',
            success: (data: any) => {
              setLocker(false);
              setIsLockedBtnPayFee(false);
              setModal(false);
              navigate("/")
              return `Done.`;
            },
            error: (err: any) => {
              setIsLockedBtnPayFee(false);
              setLocker(false);
              return `${err.message.toString()}`;
            }
          },
          {
            success: {
              duration: 6000,
              icon: (
                <img
                  className="toast-logo"
                  src={`${window.location.protocol}//${window.location.host}/images/basic-logo.png`}
                />
              )
            }
          }
        );
      }
    }
  }

  async function payFeeByBalanceHandle() {
    toast.promise(
      membershipService
        .payFeeByBalance()
        .then((response) =>
          setFlowState({ ...flowState, step: response?.flow?.step })
        ),
      {
        loading: 'Preparing...',
        success: (data: any) => {
          setIsLockedBtnPayFee(false);
          setModal(false);
          return `Done.`;
        },
        error: (err: any) => {
          setIsLockedBtnPayFee(false);
          return `${err.message.toString()}`;
        }
      },
      {
        success: {
          duration: 6000,
          icon: (
            <img
              className="toast-logo"
              src={`${window.location.protocol}//${window.location.host}/images/basic-logo.png`}
            />
          )
        }
      }
    );
  }

  async function backToPayment() {
    if (!locker) {
      setLocker(true);
      toast.promise(
        wizardService
          .updateStep(14)
          .then(() => setFlowState({ ...flowState, step: 14 })),
        {
          loading: 'Preparing...',
          success: (data) => {
            setLocker(false);
            return `Pay Membership.`;
          },
          error: (err) => {
            setLocker(false);
            return `${err.message.toString()}`;
          }
        },
        {
          success: {
            duration: 6000,
            icon: (
              <img
                className="toast-logo"
                src={`${window.location.protocol}//${window.location.host}/images/basic-logo.png`}
              />
            )
          }
        }
      );
    }
  }
  async function backToApplicationForm() {
    if (!locker) {
      setLocker(true);
      toast.promise(
        wizardService
          .updateStep(9)
          .then(() => setFlowState({ ...flowState, step: 9 })),
        {
          loading: 'Preparing...',
          success: (data) => {
            setLocker(false);
            return `Application Form.`;
          },
          error: (err) => {
            setLocker(false);
            return `${err.message.toString()}`;
          }
        },
        {
          success: {
            duration: 6000,
            icon: (
              <img
                className="toast-logo"
                src={`${window.location.protocol}//${window.location.host}/images/basic-logo.png`}
              />
            )
          }
        }
      );
    }
  }

  const [walletInfoMembershipModal, setWalletInfoMembershipModal] =
    useState(null);
  async function loadWalletInfoMembershipModal() {
    await walletService
      .getWalletsInfoForModal('membership')
      .then((list) => setWalletInfoMembershipModal(list));
  }

  useEffect(() => {
    if ((flowState.step > 10 && flowState.step < 13) || flowState.step == 20) {
      loadCounts();
    }
  }, [flowState]);

  useEffect(() => {
    if (flowState.step > 7) {
      loadUserType();
    }
  }, [flowState]);

  useEffect(() => {
    setUpdateTimer(+new Date());
  }, [modal]);

  const context = {
    payFeeByBalanceHandle,
    isCheckRejection,
    setIsCheckRejection,
    loadUserType,
    userType,
    setUserType,
    emailOTPHandle,
    phoneOTPHandle,
    uplineOTPHandle,
    emailVerifyOTPHandle,
    phoneVerifyOTPHandle,
    uplineVerifyOTPHandle,
    isVerificationCodeSentToEmail,
    expireTimeVCToEmail,
    isVerificationCodeSentToUpline,
    setIsVerificationCodeSentToUpline,
    finishVerificationWithOTP,
    firstWelcomeVideo,
    secondWelcomeVideo,
    thirdWelcomeVideo,
    fourthWelcomeVideo,
    loadQuestionsInWatchVideo,
    questions,
    watchVideoVerifyHandle,
    setAnswers,
    answers,
    setFinalAnswers,
    finalAnswers,
    loadAccount,
    accountTypes,
    loadCountries,
    countriesList,
    loadAccountDescription,
    accountDescription,
    firstStepApplicationForm,
    backToFirstStepApplicationForm,
    applicationFormHandle,
    applicationFormData,
    setApplicationFormData,
    SubmittedIGotIt,
    setupNow,
    skipSetupNow,
    isVerificationCodeSentToPhone,
    isError,
    setIsVerificationCodeSentToPhone,
    modal,
    setModal,
    membershipFormDataCrypto,
    setMembershipFormDataCrypto,
    membershipFormDataWire,
    setMembershipFormDataWire,
    membershipFormDataCryptoRenew,
    setMembershipFormDataCryptoRenew,
    membershipFormDataWireRenew,
    setMembershipFormDataWireRenew,
    payFeeHandle,
    payFeeRenewHandle,
    expireTimeVCToPhone,
    expireTimeVCToUpline,
    setIsAllAnswersCurrect,
    isAllAnswersCurrect,
    datePickerRegisterPending,
    setDatePickerRegisterPending,
    datePickerWireTransfer,
    setDatePickerWireTransfer,
    datePickerWireExp,
    setDatePickerWireExp,
    datePickerCryptoTransfer,
    setDatePickerCryptoTransfer,
    finalStepToStartMyMap,
    paymentInfo,
    paymentCheckUser,
    setPaymentCheckUser,
    loadPaymenInfo,
    loadPaymenCheckMigratedUser,
    isLockedBtnApplicationForm,
    membershipFormDataBosBalanceRenew, setMembershipFormDataBosBalanceRenew,
    isLockedBtnPayFee,
    errorsApplicationForm,
    setErrorsApplicationForm,
    errorsCryptoFee,
    setErrorsCryptoFee,
    loadUserData,
    loadVerificationData,
    verificationData,
    userData,
    updatePhoneNumberHandle,
    isModalChangeNumber,
    setIsModalChangeNumber,
    errorsWireFee,
    setErrorsWireFee,
    backToPayment,
    backToApplicationForm,
    loadRegisterPendingFormData,
    registerPendingDescription,
    skipConnectorVerificationHandle,
    firstWelcomeVideoBack,
    secondWelcomeVideoBack,
    thirdWelcomeVideoBack,
    fourthWelcomeVideoBack,
    loadWalletInfoMembershipModal,
    walletInfoMembershipModal,
    age,
    setAge,
    attachmentUploadHandle,
    isUploaded, setIsUploaded,
    questionsAnswers, setQuestionsAnswers
  };

  return (
    <WizardContext.Provider value={context}>{children}</WizardContext.Provider>
  );
};

export default WizardContext;
