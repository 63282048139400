import React, { useContext, useEffect, useState } from 'react';
import toast from 'react-hot-toast';
import { Link, useNavigate } from 'react-router-dom';
import config from '../../config';
import PrivateContext from '../../context/private/privateContext';
import ProfileContext from '../../context/private/profileContext';
import { countries } from '../../context/public/countries';
import { Container, Flex } from '../../styles/Styles';
import {
  dateFormat,
  timestampToLocalDaily,
  timestampToLocalHour
} from '../../utils/tools';
import profileValidation from '../../validations/profileValidation';
import Header from '../Header';
import Layout from '../layout/Index';
import ModalCropper from '../ModalCropper';
import Steps from './steps';
import { GrSecure } from 'react-icons/gr';
import { AiOutlineInfoCircle } from 'react-icons/ai';
import dictionary from '../../locals/my-account.json';
import { FaCheckCircle } from 'react-icons/fa';
import AccountContext from '../../context/private/accountContext';

interface CheckBoxState {
  title: string;
  value: boolean;
  id: string;
}

function getDaysUntilTimestamp(expireTime) {
  const now = Date.now();
  const timeDifference = expireTime - now;
  const millisecondsInADay = 24 * 60 * 60 * 1000;
  const daysRemaining = Math.floor(timeDifference / millisecondsInADay);
  return daysRemaining;
}

const CheckBox = ({ nameKey, states, setStates, index }: any) => {
  return (
    <>
      <div className="checkbox">
        <div className="content">
          <div className="inner-side">
            <input
              type="checkbox"
              id={states[index]?.title.replace(' ', '')}
              name={states[index]?.title.replace(' ', '')}
              value={states[index]?.value}
              onChange={(event) => {
                const newStates = [...states];
                newStates[index].value = event.target.checked;
                setStates(newStates);
              }}
              checked={states[index]?.value}
            />
            <label
              className="label"
              htmlFor={states[index]?.title.replace(' ', '')}
              style={{
                paddingLeft: '1rem'
              }}
            >
              {states[index]?.title}
            </label>
          </div>
        </div>
      </div>
    </>
  );
};

const LoadingAnimation = () => {
  return (
    <Flex x="center" y="center" style={{ width: '100%' }}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width={100}
        height={100}
        viewBox="0 0 100 100"
        overflow="visible"
        fill="#153458"
      >
        <defs>
          {' '}
          <circle
            id="spinner"
            r={4}
            cx={50}
            cy={50}
            transform="translate(0 -30)"
          />{' '}
        </defs>{' '}
        <use xlinkHref="#spinner" transform="rotate(0 50 50)">
          <animate
            attributeName="opacity"
            values="0;1;0"
            dur="1s"
            begin="0s"
            repeatCount="indefinite"
          />{' '}
          <animateTransform
            attributeName="transform"
            type="translate"
            additive="sum"
            dur="1s"
            begin="0s"
            repeatCount="indefinite"
            from="0 0"
            to={10}
          />{' '}
        </use>
        <use xlinkHref="#spinner" transform="rotate(30 50 50)">
          <animate
            attributeName="opacity"
            values="0;1;0"
            dur="1s"
            begin="0.08s"
            repeatCount="indefinite"
          />{' '}
          <animateTransform
            attributeName="transform"
            type="translate"
            additive="sum"
            dur="1s"
            begin="0.08s"
            repeatCount="indefinite"
            from="0 0"
            to={10}
          />{' '}
        </use>
        <use xlinkHref="#spinner" transform="rotate(60 50 50)">
          <animate
            attributeName="opacity"
            values="0;1;0"
            dur="1s"
            begin="0.16s"
            repeatCount="indefinite"
          />{' '}
          <animateTransform
            attributeName="transform"
            type="translate"
            additive="sum"
            dur="1s"
            begin="0.16s"
            repeatCount="indefinite"
            from="0 0"
            to={10}
          />{' '}
        </use>
        <use xlinkHref="#spinner" transform="rotate(90 50 50)">
          <animate
            attributeName="opacity"
            values="0;1;0"
            dur="1s"
            begin="0.24s"
            repeatCount="indefinite"
          />{' '}
          <animateTransform
            attributeName="transform"
            type="translate"
            additive="sum"
            dur="1s"
            begin="0.24s"
            repeatCount="indefinite"
            from="0 0"
            to={10}
          />{' '}
        </use>
        <use xlinkHref="#spinner" transform="rotate(120 50 50)">
          <animate
            attributeName="opacity"
            values="0;1;0"
            dur="1s"
            begin="0.32s"
            repeatCount="indefinite"
          />{' '}
          <animateTransform
            attributeName="transform"
            type="translate"
            additive="sum"
            dur="1s"
            begin="0.32s"
            repeatCount="indefinite"
            from="0 0"
            to={10}
          />{' '}
        </use>
        <use xlinkHref="#spinner" transform="rotate(150 50 50)">
          <animate
            attributeName="opacity"
            values="0;1;0"
            dur="1s"
            begin="0.4s"
            repeatCount="indefinite"
          />{' '}
          <animateTransform
            attributeName="transform"
            type="translate"
            additive="sum"
            dur="1s"
            begin="0.4s"
            repeatCount="indefinite"
            from="0 0"
            to={10}
          />{' '}
        </use>
        <use xlinkHref="#spinner" transform="rotate(180 50 50)">
          <animate
            attributeName="opacity"
            values="0;1;0"
            dur="1s"
            begin="0.48s"
            repeatCount="indefinite"
          />{' '}
          <animateTransform
            attributeName="transform"
            type="translate"
            additive="sum"
            dur="1s"
            begin="0.48s"
            repeatCount="indefinite"
            from="0 0"
            to={10}
          />{' '}
        </use>
        <use xlinkHref="#spinner" transform="rotate(210 50 50)">
          <animate
            attributeName="opacity"
            values="0;1;0"
            dur="1s"
            begin="0.56s"
            repeatCount="indefinite"
          />{' '}
          <animateTransform
            attributeName="transform"
            type="translate"
            additive="sum"
            dur="1s"
            begin="0.56s"
            repeatCount="indefinite"
            from="0 0"
            to={10}
          />{' '}
        </use>
        <use xlinkHref="#spinner" transform="rotate(240 50 50)">
          <animate
            attributeName="opacity"
            values="0;1;0"
            dur="1s"
            begin="0.64s"
            repeatCount="indefinite"
          />{' '}
          <animateTransform
            attributeName="transform"
            type="translate"
            additive="sum"
            dur="1s"
            begin="0.64s"
            repeatCount="indefinite"
            from="0 0"
            to={10}
          />{' '}
        </use>
        <use xlinkHref="#spinner" transform="rotate(270 50 50)">
          <animate
            attributeName="opacity"
            values="0;1;0"
            dur="1s"
            begin="0.72s"
            repeatCount="indefinite"
          />{' '}
          <animateTransform
            attributeName="transform"
            type="translate"
            additive="sum"
            dur="1s"
            begin="0.72s"
            repeatCount="indefinite"
            from="0 0"
            to={10}
          />{' '}
        </use>
        <use xlinkHref="#spinner" transform="rotate(300 50 50)">
          <animate
            attributeName="opacity"
            values="0;1;0"
            dur="1s"
            begin="0.8s"
            repeatCount="indefinite"
          />{' '}
          <animateTransform
            attributeName="transform"
            type="translate"
            additive="sum"
            dur="1s"
            begin="0.8s"
            repeatCount="indefinite"
            from="0 0"
            to={10}
          />{' '}
        </use>
        <use xlinkHref="#spinner" transform="rotate(330 50 50)">
          <animate
            attributeName="opacity"
            values="0;1;0"
            dur="1s"
            begin="0.88s"
            repeatCount="indefinite"
          />{' '}
          <animateTransform
            attributeName="transform"
            type="translate"
            additive="sum"
            dur="1s"
            begin="0.88s"
            repeatCount="indefinite"
            from="0 0"
            to={10}
          />{' '}
        </use>{' '}
      </svg>
    </Flex>
  );
};

const Input = ({ id, currentPrefix, setCurrentPrefix }: any) => {
  const [currentCountry, setCurrentCountry] = useState<string | null>(id);
  const [tempId, setTempId] = useState<string | null>(null);

  const { profilePhone, setProfilePhone } = useContext(ProfileContext);

  useEffect(() => {
    if (countries) {
      const prefix = countries.filter(
        (c: { id: number | string }) => Number(c.id) === Number(currentCountry)
      );
      setCurrentPrefix(prefix[0]?.root + prefix[0]?.suffixes);
      setProfilePhone({ ...profilePhone, country_id: prefix[0]?.id });
    }
  }, [countries, currentCountry]);

  return (
    <>
      <div
        className="phone-number-with-country"
        style={{
          display: 'grid',
          gridTemplateColumns: '1fr 5fr',
          gap: '1rem',
          marginTop: '1rem'
        }}
      >
        {currentPrefix ? (
          <>
            <input
              type="text"
              placeholder="Number"
              className="register-input-input"
              value={currentPrefix ? currentPrefix : ''}
              autoComplete="off"
              disabled
            />
            <div
              className="appf-type-info appf-type-selector"
              style={{
                padding: '0 1rem'
              }}
            >
              {countries && countries.length > 0 && (
                <select
                  style={{
                    border: 0,
                    background: 'unset',
                    borderRadius: 8,
                    width: '100%',
                    height: 48,
                    padding: '0rem',
                    fontSize: '16px'
                  }}
                  onChange={(e) => {
                    setCurrentCountry(e.target.value);
                    setProfilePhone({
                      ...profilePhone,
                      country_id: e.target.value
                    });
                  }}
                >
                  <option selected={true}>
                    {dictionary['MY-ACCOUNT-title1']}
                  </option>
                  {countries.map(
                    (
                      type: {
                        id: number;
                        name: string;
                        logo: string;
                        suffixes: string;
                        root: string;
                      },
                      index: number
                    ) => {
                      if (type.id == Number(id)) {
                        return (
                          <option key={index} value={type.id} selected={true}>
                            {type.name}
                          </option>
                        );
                      } else {
                        return (
                          <option key={index} value={type.id}>
                            {type.name} ({type.root + type.suffixes}){' '}
                          </option>
                        );
                      }
                    }
                  )}
                </select>
              )}
            </div>
          </>
        ) : null}
      </div>
    </>
  );
};

const Profile = (): JSX.Element => {
  const navigate = useNavigate();

  // const { load2FA, twoFA } = useContext(PrivateContext)
  const {
    loadProfile,
    updateBeneficiaryEmailHandle,
    isEditEmailBeneficiaryName,
    setIsEditEmailBeneficiaryName,
    isEditEmailBeneficiaryEmail,
    setIsEditEmailBeneficiaryEmail,
    userGroupIconData,
    profileEmailBeneficiary,
    setProfileEmailBeneficiary,

    isEditFacebook,
    setIsEditFacebook,
    isEditLinkedIn,
    setIsEditLinkedIn,
    isEditX,
    setIsEditX,

    profileFacebook,
    setProfileFacebook,
    profileLinkedIn,
    setProfileLinkedIn,
    profileX,
    setProfileX,

    updateFacebookHandle,
    updateLinkedInHandle,
    updateXHandle,

    load2FA,
    twoFA,
    check2FaStatus,
    is2faShow,
    setIs2faShow,
    verfiyTwoFactorAuthHandle,
    currentStep,
    setCurrentStep,
    profileData,
    defaultAvatar,
    isActiceEditor,
    setIsActiceEditor,
    profileTelegram,
    setProfileTelegram,
    profileEmail,
    setProfileEmail,
    profilePhone,
    setProfilePhone,
    updateHandle,
    isEditTelegram,
    setIsEditTelegram,
    isEditEmail,
    setIsEditEmail,
    isEditPhone,
    setIsEditPhone,
    errorsProfileUpdate,
    setErrorsProfileUpdate,
    modalVerifyEmail,
    setModalVerifyEmail,
    modalVerifyPhone,
    setModalVerifyPhone,
    emailOTPHandle,
    emailVerifyOTPHandle,
    phoneOTPHandle,
    phoneVerifyOTPHandle,
    changePasswordHandle,
    modalChangePassword,
    setModalChangePassword,
    additionalCodeSecurityData,
    change2faStatusHandle,
    modalChange2faStatus,
    setModalChange2faStatus,
    setModalSophisticatedInvestor,
    modalSophisticatedInvestor,
    mailChimpInfo,
    loadMailChimpInfo,
    updateMailchimpHandle,
    isMailChimpFirstNameEmail,
    setIsMailChimpFirstNameEmail,
    isMailChimpLastNameEmail,
    setIsMailChimpLastNameEmail
  } = useContext(ProfileContext);
  const { settings, flowState, sophisticateStatus } =
    useContext(PrivateContext);
  const [phoneCountry, setPhoneCountry] = useState<any>(null);
  const [twoFACode, setTwoFACode] = useState<any>(null);

  const { userInfo } = useContext(AccountContext);

  useEffect(() => {
    if (flowState.step === 17) {
      loadProfile();
      loadMailChimpInfo();
    }
  }, [flowState]);

  useEffect(() => {
    check2FaStatus();
    // window.scrollTo(0, 0)
  }, []);
  useEffect(() => {
    if (is2faShow == 0) {
      load2FA();
    }
  }, [is2faShow]);

  useEffect(() => {
    if (profileData?.country_id) {
      const country = countries.find((c) => c.id === profileData?.country_id);
      setPhoneCountry(country);
    }
  }, [profileData]);

  const [mailChimpFirstName, setMailChimpFirstName] = useState({
    first_name: ''
  });

  const [mailChimpLastName, setMailChimpLastName] = useState({
    last_name: ''
  });

  const [firstNameSub, setFirstNameSub] = useState('');
  const [lastNameSub, setLastNameSub] = useState('');
  const [checkBoxes, setCheckBoxes] = useState<CheckBoxState[]>([]);
  const [isSubscript, setIsSubscript] = useState<boolean>(false);
  const [finalBodyForSub, setFinalBodyForSub] = useState({});

  useEffect(() => {
    const areAllChecked = checkBoxes.every((item) => item.value === true);
    setIsSubscript(areAllChecked);
  }, [checkBoxes]);
  useEffect(() => {
    mailChimpInfo && setCheckBoxes(mailChimpInfo.items);
    mailChimpInfo && setFirstNameSub(mailChimpInfo.firstName);
    mailChimpInfo && setLastNameSub(mailChimpInfo.lastName);
  }, [mailChimpInfo]);

  useEffect(() => {
    let finalObj = {
      status: {},
      type: 0
    };

    const arrayTitles: any = [];
    checkBoxes.map((item: any) => {
      const data = { [item?.id]: item.value };
      arrayTitles.push(data);
    });

    const combinedObject = arrayTitles.reduce((acc: any, item: any) => {
      return { ...acc, ...item };
    }, {});

    finalObj.status = combinedObject;
    setFinalBodyForSub(finalObj);
  }, [checkBoxes]);

  const SubscriptionFirstNameHandle = () => {
    updateMailchimpHandle({
      first_name: mailChimpFirstName.first_name,
      last_name: mailChimpLastName.last_name,
      type: 1
    });
  };
  const SubscriptionLastNameHandle = () => {
    updateMailchimpHandle({
      last_name: mailChimpLastName.last_name,
      type: 2
    });
  };
  const ReSubscribeHandle = async () => {
    await updateMailchimpHandle({
      type: 3
    });
  };

  const SubscriptionHandle = () => {
    updateMailchimpHandle(finalBodyForSub);
  };

  const [modal, setModal] = useState(false);

  const [verifyEmailToken, setVerifyEmailToken] = useState('');
  const [verifyEmail2FAToken, setVerifyEmail2FAToken] = useState('');

  const [verifyPhoneToken, setVerifyPhoneToken] = useState('');
  const [verifyPhone2FAToken, setVerifyPhone2FAToken] = useState('');

  const [verifyChangePassword2FAToken, setVerifyChangePassword2FAToken] =
    useState('');
  const [verifyChange2FAStatusToken, setVerifyChange2FAStatusToken] =
    useState('');

  const [currentPrefix, setCurrentPrefix] = useState<string | null>('+0');

  const [isShow, setIsShow] = useState(false);
  const [isShowConfirm, setIsShowConfirm] = useState(false);

  const [inputNewPassword, setInputNewPassword] = useState('');
  const [inputConfirmNewPassword, setInputConfirmNewPassword] = useState('');

  if (flowState?.step === 17)
    return (
      <>
        <>
          {modalVerifyEmail && (
            <Flex x="center">
              <div className="mod">
                <div className="mod-border">
                  <div className="mod-box">
                    <p className="mod-box-p">
                      {dictionary['MY-ACCOUNT-title2']}
                    </p>
                    <button
                      className="mod-box-btn"
                      onClick={() => setModalVerifyEmail(false)}
                    >
                      X
                    </button>
                  </div>
                  <div style={{ marginRight: 10, marginLeft: 10 }}></div>
                  <div className="mod-inside">
                    <div className="mod-inside-body">
                      <b>{dictionary['MY-ACCOUNT-title3']}</b>

                      <label
                        className="mod-inside-body-label"
                        style={{ marginTop: '15px' }}
                      >
                        {dictionary['MY-ACCOUNT-title4']}:
                      </label>
                      <input
                        type="number"
                        placeholder="code"
                        className="mod-inside-body-input"
                        value={verifyEmailToken}
                        onChange={(e) =>
                          e.target.value.length <= 6 &&
                          setVerifyEmailToken(e.target.value)
                        }
                      />
                      <label
                        className="mod-inside-body-label"
                        style={{ marginTop: '15px' }}
                      >
                        {dictionary['MY-ACCOUNT-title5']}:
                      </label>
                      <input
                        type="number"
                        placeholder="code"
                        className="mod-inside-body-input"
                        value={verifyEmail2FAToken}
                        onChange={(e) =>
                          e.target.value.length <= 6 &&
                          setVerifyEmail2FAToken(e.target.value)
                        }
                      />
                    </div>
                    <Flex gap={0.8} x="center">
                      <button
                        className="mod-inside-body-btn pointer"
                        onClick={() => {
                          setModalVerifyEmail(false);
                          setIsEditEmail(false);
                        }}
                      >
                        Cancel
                      </button>
                      <button
                        className="mod-inside-body-btn pointer"
                        onClick={() => {
                          emailVerifyOTPHandle(
                            verifyEmailToken,
                            verifyEmail2FAToken
                          );
                          setVerifyEmailToken('');
                          setVerifyEmail2FAToken('');
                        }}
                      >
                        {dictionary['MY-ACCOUNT-title7']}
                      </button>
                    </Flex>
                  </div>
                </div>

                <div
                  className="blur-bg"
                  // onClick={() => setModalVerifyEmail(false)}
                ></div>
              </div>
            </Flex>
          )}

          {modalVerifyPhone && (
            <Flex x="center">
              <div className="mod">
                <div className="mod-border">
                  <div className="mod-box">
                    <p className="mod-box-p">
                      {dictionary['MY-ACCOUNT-title8']}
                    </p>
                    <button
                      className="mod-box-btn"
                      onClick={() => setModalVerifyPhone(false)}
                    >
                      X
                    </button>
                  </div>
                  <div style={{ marginRight: 10, marginLeft: 10 }}></div>
                  <div className="mod-inside">
                    <div className="mod-inside-body">
                      <b>{dictionary['MY-ACCOUNT-title9']}</b>

                      <label
                        className="mod-inside-body-label"
                        style={{ marginTop: '15px' }}
                      >
                        {dictionary['MY-ACCOUNT-title10']}:
                      </label>
                      <input
                        type="number"
                        placeholder="code"
                        className="mod-inside-body-input"
                        value={verifyPhoneToken}
                        onChange={(e) => setVerifyPhoneToken(e.target.value)}
                      />
                      <label
                        className="mod-inside-body-label"
                        style={{ marginTop: '15px' }}
                      >
                        {dictionary['MY-ACCOUNT-title5']}:
                      </label>
                      <input
                        type="number"
                        placeholder="code"
                        className="mod-inside-body-input"
                        value={verifyPhone2FAToken}
                        onChange={(e) =>
                          e.target.value.length <= 6 &&
                          setVerifyPhone2FAToken(e.target.value)
                        }
                      />
                    </div>
                    <Flex gap={0.8} x="center">
                      <button
                        className="mod-inside-body-btn pointer"
                        onClick={() => {
                          setModalVerifyPhone(false);
                          setIsEditPhone(false);
                        }}
                      >
                        {dictionary['MY-ACCOUNT-title6']}
                      </button>
                      <button
                        className="mod-inside-body-btn pointer"
                        onClick={() => {
                          phoneVerifyOTPHandle(
                            verifyPhoneToken,
                            verifyPhone2FAToken
                          );
                          setVerifyPhoneToken('');
                          setVerifyPhone2FAToken('');
                        }}
                      >
                        {dictionary['MY-ACCOUNT-title7']}
                      </button>
                    </Flex>
                  </div>
                </div>

                <div
                  className="blur-bg"
                  // onClick={() => setModalVerifyPhone(false)}
                ></div>
              </div>
            </Flex>
          )}

          {modal && (
            <Flex x="center">
              <div className="blur-bg"></div>
              <div className="mod">
                <div className="mod-border">
                  <div className="mod-box">
                    <p className="mod-box-p">
                      {dictionary['MY-ACCOUNT-title11']}
                    </p>
                    <button
                      className="mod-box-btn"
                      onClick={() => setModal(false)}
                    >
                      X
                    </button>
                  </div>
                  <div style={{ marginRight: 10, marginLeft: 10 }}>
                    <Steps current={currentStep} />
                  </div>
                  <div className="mod-inside">
                    <div className="mod-inside-body">
                      {/* step 1 */}
                      {currentStep === 1 && (
                        <>
                          <h3>{dictionary['MY-ACCOUNT-title12']}</h3>
                          <p>
                            {dictionary['MY-ACCOUNT-title13']} (<b>2FA</b>).
                          </p>
                          <br />
                          <p>{dictionary['MY-ACCOUNT-title14']}</p>
                          <br />
                          <p>{dictionary['MY-ACCOUNT-title15']}</p>
                          <br />
                          <p>
                            <b>
                              <a
                                href="https://support.google.com/accounts/answer/1066447"
                                target="_blank"
                              >
                                {dictionary['MY-ACCOUNT-title16']}
                              </a>
                            </b>{' '}
                            {dictionary['MY-ACCOUNT-title17']}{' '}
                            <b>
                              <a
                                href="https://support.google.com/accounts/answer/1066447"
                                target="_blank"
                              >
                                {dictionary['MY-ACCOUNT-title18']}
                              </a>
                            </b>{' '}
                            {dictionary['MY-ACCOUNT-title19']}
                          </p>
                          <br />
                          <p>
                            {dictionary['MY-ACCOUNT-title20']} "
                            <b>{dictionary['MY-ACCOUNT-title21']}</b>"{' '}
                            {dictionary['MY-ACCOUNT-title22']}{' '}
                          </p>

                          <div
                            style={{
                              padding: '56.25% 0 0 0',
                              position: 'relative'
                            }}
                          >
                            <iframe
                              src="https://player.vimeo.com/video/845786642?badge=0&autopause=0&player_id=0&app_id=58479"
                              frameBorder={0}
                              allow="autoplay; fullscreen; picture-in-picture"
                              allowFullScreen
                              style={{
                                position: 'absolute',
                                top: 0,
                                left: 0,
                                width: '100%',
                                height: '100%'
                              }}
                              title="BOS 2FA"
                            />
                          </div>
                          <script src="https://player.vimeo.com/api/player.js" />
                        </>
                      )}

                      {/* step 2 */}
                      {currentStep === 2 && (
                        <>
                          <div
                            style={{
                              display: 'flex',
                              alignItems: 'center',
                              justifyContent: 'center'
                            }}
                          >
                            <img src={twoFA?.QRCode} alt="QRCODE" />
                          </div>

                          <p
                            style={{
                              textAlign: 'center',
                              padding: '0 1rem 2rem',
                              wordBreak: 'break-word',
                              display: 'flex',
                              justifyContent: 'center',
                              alignItems: 'center',
                              flexDirection: 'column'
                            }}
                          >
                            <b>{dictionary['MY-ACCOUNT-title23']}:</b>{' '}
                            {twoFA?.Secret}
                          </p>
                          <hr />

                          <label
                            style={{
                              marginBottom: '0',
                              marginTop: '1rem'
                            }}
                            className="mod-inside-body-label"
                          >
                            {dictionary['MY-ACCOUNT-title24']}
                          </label>
                          <input
                            type="text"
                            placeholder="Enter your 2FA code here"
                            className="mod-inside-body-input"
                            value={twoFACode}
                            onChange={(e) =>
                              e.target.value.length <= 6 &&
                              setTwoFACode(e.target.value)
                            }
                          />
                        </>
                      )}

                      {/* step 3 */}
                      {currentStep === 3 && (
                        <>
                          <Flex gap={1.7} x={'center'}>
                            <div className="approved">
                              <img src="/images/shinyMan.svg" alt="shinyMan" />

                              <div className="approved-h">
                                {dictionary['MY-ACCOUNT-title25']}
                              </div>
                              <div className="approved-t">
                                {dictionary['MY-ACCOUNT-title26']}
                              </div>
                            </div>
                          </Flex>
                        </>
                      )}
                    </div>
                    <button
                      className="mod-inside-body-btn pointer"
                      onClick={() => {
                        if (currentStep === 3) {
                          setModal(false);
                          setIs2faShow(1);
                        } else if (currentStep === 2) {
                          verfiyTwoFactorAuthHandle(twoFACode);
                        } else {
                          setCurrentStep(2);
                        }
                      }}
                    >
                      {currentStep === 3 ? 'Close' : 'Next'}
                    </button>
                  </div>
                </div>
              </div>
            </Flex>
          )}

          {modalChangePassword && (
            <Flex x="center">
              <div className="mod">
                <div className="mod-border">
                  <div className="mod-box">
                    <p className="mod-box-p">
                      {dictionary['MY-ACCOUNT-title27']}
                    </p>
                    <button
                      className="mod-box-btn"
                      onClick={() => setModalChangePassword(false)}
                    >
                      X
                    </button>
                  </div>
                  <div style={{ marginRight: 10, marginLeft: 10 }}></div>
                  <div className="mod-inside">
                    <div className="mod-inside-body">
                      <p style={{ marginBottom: '0.7rem' }}>
                        <b>{dictionary['MY-ACCOUNT-title28']}</b>,{' '}
                        {dictionary['MY-ACCOUNT-title29']}{' '}
                      </p>
                      <p style={{ marginBottom: '0.7rem' }}>
                        {dictionary['MY-ACCOUNT-title30']} "
                        <b>{dictionary['MY-ACCOUNT-title31']}</b>"{' '}
                        {dictionary['MY-ACCOUNT-title32']} "
                        <b>{dictionary['MY-ACCOUNT-title33']}</b>"{' '}
                        {dictionary['MY-ACCOUNT-title34']}{' '}
                      </p>
                      <p style={{ marginBottom: '3rem' }}>
                        {dictionary['MY-ACCOUNT-title35']} <br></br>
                        {dictionary['MY-ACCOUNT-title36']} "
                        <b>{dictionary['MY-ACCOUNT-title37']}</b>"{' '}
                        {dictionary['MY-ACCOUNT-title38']}
                      </p>

                      <div className="input-password-with-option">
                        <input
                          type={isShow ? 'text' : 'password'}
                          className="register-inputs white"
                          placeholder="New Password"
                          value={inputNewPassword}
                          autoComplete="off"
                          onChange={(e) => {
                            setInputNewPassword(e.target.value);
                          }}
                        />
                        <div
                          className="input-option pointer"
                          onClick={() =>
                            isShow ? setIsShow(false) : setIsShow(true)
                          }
                        >
                          {isShow ? 'Hide' : 'Show'}
                        </div>
                      </div>
                      <div className="input-password-with-option">
                        <input
                          type={isShowConfirm ? 'text' : 'password'}
                          className="register-inputs white"
                          placeholder="Confirm New Password"
                          value={inputConfirmNewPassword}
                          autoComplete="off"
                          onChange={(e) => {
                            setInputConfirmNewPassword(e.target.value);
                          }}
                        />
                        <div
                          className="input-option pointer"
                          onClick={() =>
                            isShowConfirm
                              ? setIsShowConfirm(false)
                              : setIsShowConfirm(true)
                          }
                        >
                          {isShowConfirm ? 'Hide' : 'Show'}
                        </div>
                      </div>

                      <label
                        className="mod-inside-body-label"
                        style={{ marginTop: '15px' }}
                      >
                        {dictionary['MY-ACCOUNT-title5']}:
                      </label>
                      <input
                        type="number"
                        placeholder="code"
                        className="mod-inside-body-input"
                        value={verifyChangePassword2FAToken}
                        onChange={(e) =>
                          e.target.value.length <= 6 &&
                          setVerifyChangePassword2FAToken(e.target.value)
                        }
                      />
                    </div>
                    <Flex gap={0.8} x="center">
                      <button
                        className="mod-inside-body-btn pointer"
                        onClick={() => {
                          setModalChangePassword(false);
                        }}
                      >
                        {dictionary['MY-ACCOUNT-title6']}
                      </button>
                      <button
                        className="mod-inside-body-btn pointer"
                        onClick={() => {
                          if (
                            inputNewPassword.length > 0 &&
                            inputConfirmNewPassword.length > 0
                          ) {
                            if (inputNewPassword === inputConfirmNewPassword) {
                              changePasswordHandle(
                                inputNewPassword,
                                verifyChangePassword2FAToken
                              );
                              setInputNewPassword('');
                              setInputConfirmNewPassword('');
                              setVerifyChangePassword2FAToken('');
                            } else {
                              toast.error(
                                `"Confirm Password" must match password`
                              );
                            }
                          } else {
                            toast.error(
                              `Required fields are empty. Please fill them before continuing.`
                            );
                          }
                        }}
                      >
                        {dictionary['MY-ACCOUNT-title37']}
                      </button>
                    </Flex>
                  </div>
                </div>

                <div
                  className="blur-bg"
                  onClick={() => setModalChangePassword(false)}
                ></div>
              </div>
            </Flex>
          )}

          {modalChange2faStatus && (
            <Flex x="center">
              <div className="mod">
                <div className="mod-border">
                  <div className="mod-box">
                    <p className="mod-box-p">
                      {dictionary['MY-ACCOUNT-title117']}
                    </p>
                    <button
                      className="mod-box-btn"
                      onClick={() => setModalChange2faStatus(false)}
                    >
                      X
                    </button>
                  </div>
                  <div style={{ marginRight: 10, marginLeft: 10 }}></div>
                  <div className="mod-inside">
                    <div className="mod-inside-body">
                      <p>
                        To{' '}
                        <b>
                          {profileData?.faLogin === 1
                            ? 'disable 2FA'
                            : 'enable 2FA'}
                        </b>
                        , enter your 2FA code in the input field
                      </p>
                      <label
                        className="mod-inside-body-label"
                        style={{ marginTop: '15px' }}
                      >
                        {dictionary['MY-ACCOUNT-title5']}:
                      </label>
                      <input
                        type="number"
                        placeholder="code"
                        className="mod-inside-body-input"
                        value={verifyChange2FAStatusToken}
                        onChange={(e) =>
                          e.target.value.length <= 6 &&
                          setVerifyChange2FAStatusToken(e.target.value)
                        }
                      />
                    </div>
                    <Flex gap={0.8} x="center">
                      <button
                        className="mod-inside-body-btn pointer"
                        onClick={() => {
                          setModalChange2faStatus(false);
                        }}
                      >
                        {dictionary['MY-ACCOUNT-title6']}
                      </button>
                      <button
                        className="mod-inside-body-btn pointer"
                        onClick={() => {
                          profileData?.faLogin === 1
                            ? change2faStatusHandle(
                                0,
                                verifyChange2FAStatusToken
                              )
                            : change2faStatusHandle(
                                1,
                                verifyChange2FAStatusToken
                              );
                          setVerifyChange2FAStatusToken('');
                        }}
                      >
                        {dictionary['MY-ACCOUNT-title37']}
                      </button>
                    </Flex>
                  </div>
                </div>

                <div
                  className="blur-bg"
                  onClick={() => setModalChange2faStatus(false)}
                ></div>
              </div>
            </Flex>
          )}
        </>

        <Layout className="pro">
          <Container x={'center'} marginT={1} className="container">
            <Flex gap={1.7} x={'center'} y={'start'} className="pro-flex">
              <div className="pro-head">
                <Header title={dictionary['PAGE-title1']} />
                <div className="pro-head-head">
                  {profileData ? (
                    <Flex direction={'col'}>
                      <>
                        <Flex>
                          <div className="pro-head-flex">
                            <Flex
                              className="mkm"
                              direction="col"
                              gap="0.6"
                              x="center"
                              style={{ width: 'auto' }}
                            >
                              <div className="profile-avatar">
                                {profileData?.avatar ? (
                                  <img
                                    src={
                                      defaultAvatar
                                        ? defaultAvatar
                                        : `${config.server}${profileData?.avatar}`
                                    }
                                    alt="man profile"
                                    className="profile-img-file"
                                  />
                                ) : (
                                  <div className="profile-img"></div>
                                )}
                                {!isActiceEditor && (
                                  <span
                                    className="profile-edit-btn"
                                    onClick={() => setIsActiceEditor(true)}
                                  >
                                    {dictionary['MY-ACCOUNT-title40']}
                                  </span>
                                )}
                              </div>
                              {isActiceEditor && <ModalCropper />}
                            </Flex>
                            <Flex
                              direction="col"
                              x="start"
                              y="center"
                              gap="0.8"
                            >
                              <div className="pro-head-row">
                                <div className="pro-head-row-title group-title-container">
                                  <div className="group-icon-title">
                                    {profileData
                                      ? `${profileData?.first_name} ${profileData?.last_name}`
                                      : null}
                                  </div>
                                  {userGroupIconData ? (
                                    <div className="group-icon-container">
                                      {userGroupIconData?.map((item: any) => {
                                        return (
                                          <>
                                            <Flex y="center" gap={0.5}>
                                              <div
                                                className="tooltip-info tooltip-info-normal tooltip-info-normal-inside"
                                                style={{
                                                  left: 0,
                                                  width: 20,
                                                  margin: 'auto 5px 9px'
                                                }}
                                              >
                                                <img
                                                  className="group-icon tooltip-info-icon"
                                                  src={
                                                    config.server.slice(
                                                      0,
                                                      config.server.length - 1
                                                    ) + item?.avatar
                                                  }
                                                  alt={item?.name}
                                                />
                                                <div className="tooltip-info-text">
                                                  <div className="group-icon-texts">
                                                    <span>
                                                      Group Name: {item?.name}
                                                    </span>
                                                    <hr />
                                                    <span>
                                                      Join Date:{' '}
                                                      {timestampToLocalDaily(
                                                        Number(
                                                          +new Date(
                                                            item?.created_at
                                                          )
                                                        ) / 1000
                                                      )}
                                                      ,{' '}
                                                      {timestampToLocalHour(
                                                        Number(
                                                          +new Date(
                                                            item?.created_at
                                                          )
                                                        ) / 1000
                                                      )}
                                                    </span>
                                                  </div>
                                                </div>
                                              </div>
                                            </Flex>
                                          </>
                                        );
                                      })}
                                    </div>
                                  ) : null}
                                </div>
                                <div className="pro-head-row-id">{`${
                                  profileData?.username
                                    ? '@' + profileData.username
                                    : ''
                                }`}</div>
                              </div>
                              <div className="pro-head-row-mail">
                                {profileData?.username
                                  ? `Email: ${profileData?.email_address}`
                                  : null}
                              </div>
                              <div className="pro-head-row-mail">
                                {additionalCodeSecurityData
                                  ? `Support Code: ${additionalCodeSecurityData}`
                                  : null}
                              </div>
                              <div className="pro-head-row-num">
                                <Flex y="center" gap={0.5}>
                                  {profileData?.phone_number ? (
                                    <img
                                      src={phoneCountry?.logo}
                                      className="country-flag"
                                    />
                                  ) : null}
                                  {profileData?.phone_number
                                    ? `${phoneCountry?.root}${phoneCountry?.suffixes} ${profileData?.phone_number} (${phoneCountry?.name})`
                                    : null}
                                </Flex>
                              </div>
                            </Flex>
                          </div>
                          <div className="pro-head-row-combo items-start">
                            {is2faShow === 0 && (
                              <button
                                style={{
                                  padding: '1rem'
                                }}
                                className="profile-edit-button pointer"
                                onClick={() => {
                                  setModal(true);
                                  setCurrentStep(1);
                                }}
                              >
                                {dictionary['MY-ACCOUNT-title5']}
                              </button>
                            )}

                            {is2faShow === 1 && (
                              <div
                                style={{
                                  display: 'flex',
                                  gap: 5
                                }}
                              >
                                <GrSecure
                                  style={{
                                    opacity: 0.6,
                                    color: '#1534588a'
                                  }}
                                  color="#153458"
                                  size={18}
                                />
                                <span
                                  style={{
                                    fontSize: '18px',
                                    color: '#1534588a',
                                    width: 'max-content',
                                    cursor: 'not-allowed'
                                  }}
                                >
                                  {dictionary['MY-ACCOUNT-title41']}
                                </span>
                              </div>
                            )}
                          </div>
                        </Flex>
                      </>

                      <div
                        className="pro-head-row-edit pointer"
                        onClick={() => {
                          setModalChangePassword(true);
                        }}
                      >
                        {dictionary['MY-ACCOUNT-title42']}
                      </div>
                    </Flex>
                  ) : (
                    <LoadingAnimation />
                  )}
                </div>

                {userInfo && userInfo?.membershipStatus !== 2 && (
                  <div className="setting-post">
                    <div
                      style={{
                        display: 'flex',
                        flexDirection: 'column',
                        gap: '1.5rem'
                      }}
                    >
                      <div
                        className="pro-post"
                        style={{
                          minHeight: '18px',
                          height: 'unset'
                        }}
                      >
                        {profileData ? (
                          <>
                            <div className="pro-post-title">
                              {dictionary['MY-ACCOUNT-title118']}
                            </div>
                            <div
                              style={{
                                display: 'flex',
                                justifyContent: 'space-between',
                                alignItems: 'center',
                                color: '#153458',
                                borderRadius: '16px'
                              }}
                            >
                              <span style={{ lineHeight: '0' }}>
                                Current status:
                              </span>
                              {sophisticateStatus?.status === 0 ||
                              sophisticateStatus?.status === 3 ||
                              sophisticateStatus?.status === 4 ? (
                                <Link to="/sophisticate-register">
                                  <button
                                    style={{
                                      padding: '1rem'
                                    }}
                                    className="profile-edit-button update pointer"
                                  >
                                    Update
                                  </button>
                                </Link>
                              ) : (
                                <span
                                  style={{
                                    padding: '1rem',
                                    cursor: 'not-allowed'
                                  }}
                                >
                                  {sophisticateStatus?.status === 1
                                    ? 'Pending'
                                    : null}
                                  {sophisticateStatus?.status === 2 ? (
                                    <div className="flex gap-4 items-center">
                                      <span className="accepted-status">
                                        <FaCheckCircle color="#00d26a" />
                                        Approved
                                      </span>

                                      <Link to="/sophisticate-register">
                                        <button
                                          style={{
                                            padding: '1rem'
                                          }}
                                          className="profile-edit-button update pointer"
                                        >
                                          Update
                                        </button>
                                      </Link>
                                    </div>
                                  ) : null}
                                  {sophisticateStatus?.status === 3
                                    ? 'Rejected'
                                    : null}
                                  {sophisticateStatus?.status === 0
                                    ? 'Update'
                                    : null}
                                </span>
                              )}
                            </div>
                          </>
                        ) : (
                          <LoadingAnimation />
                        )}
                      </div>

                      <div className="pro-l" style={{ minHeight: '616px' }}>
                        {profileData ? (
                          <>
                            <div className="pro-l-title">
                              {dictionary['MY-ACCOUNT-title43']}
                            </div>
                            {is2faShow === 1 && (
                              <div
                                style={{
                                  display: 'flex',
                                  justifyContent: 'space-between',
                                  alignItems: 'center',
                                  padding: '1rem 0'
                                }}
                              >
                                <span>
                                  2FA status for login:{' '}
                                  {profileData?.faLogin === 1 ? 'On' : 'Off'}
                                </span>
                                <button
                                  style={{
                                    padding: '1rem'
                                  }}
                                  onClick={() => setModalChange2faStatus(true)}
                                  className="profile-edit-button pointer"
                                >
                                  {profileData?.faLogin === 1
                                    ? 'Disable 2FA'
                                    : 'Enable 2FA'}
                                </button>
                              </div>
                            )}
                            {profileData?.user_type !== 5 &&
                              profileData?.organization_name && (
                                <Flex direction="col" className="input">
                                  <label>
                                    {dictionary['MY-ACCOUNT-title44']}
                                  </label>
                                  <input
                                    type="text"
                                    placeholder="Street address"
                                    className="pro-l-input"
                                    value={profileData?.organization_name}
                                    disabled={true}
                                  />
                                </Flex>
                              )}
                            {profileData?.user_type !== 5 &&
                              profileData?.organization_identification && (
                                <Flex direction="col" className="input">
                                  <label>
                                    {dictionary['MY-ACCOUNT-title45']}
                                  </label>
                                  <input
                                    type="text"
                                    placeholder="Street address"
                                    className="pro-l-input"
                                    value={
                                      profileData?.organization_identification
                                    }
                                    disabled={true}
                                  />
                                </Flex>
                              )}
                            <Flex direction="col" className="input">
                              <label>{dictionary['MY-ACCOUNT-title46']}</label>
                              <input
                                type="text"
                                placeholder="Street address"
                                className="pro-l-input"
                                value={profileData?.street_address}
                                disabled={true}
                              />
                            </Flex>
                            <Flex direction="col" className="input">
                              <label>{dictionary['MY-ACCOUNT-title47']}</label>
                              <input
                                type="text"
                                placeholder="Street address Line 2"
                                className="pro-l-input"
                                value={profileData?.street_address2}
                                disabled={true}
                              />
                            </Flex>
                            <Flex direction="col" className="input">
                              <label>{dictionary['MY-ACCOUNT-title48']}</label>
                              <input
                                type="text"
                                placeholder="Country"
                                className="pro-l-input"
                                value={
                                  countries.filter(
                                    (country) =>
                                      country.id === profileData?.country_id
                                  )[0]?.name
                                }
                                disabled={true}
                              />
                            </Flex>
                            <Flex direction="col" className="input">
                              <label>{dictionary['MY-ACCOUNT-title49']}</label>
                              <input
                                type="text"
                                placeholder="City"
                                className="pro-l-input"
                                value={profileData?.city}
                                disabled={true}
                              />
                            </Flex>
                            <Flex direction="col" className="input">
                              <label>{dictionary['MY-ACCOUNT-title50']}</label>
                              <input
                                type="text"
                                placeholder="State / Province"
                                className="pro-l-input"
                                value={profileData?.state_provence}
                                disabled={true}
                              />
                            </Flex>
                            <Flex direction="col" className="input">
                              <label>{dictionary['MY-ACCOUNT-title51']}</label>
                              <input
                                type="text"
                                placeholder="Zip code"
                                className="pro-l-input"
                                value={profileData?.zip_code}
                                disabled={true}
                              />
                            </Flex>

                            <Flex direction="col" className="input">
                              <label>{dictionary['MY-ACCOUNT-title52']}</label>
                              <div
                                style={{
                                  position: 'relative'
                                }}
                              >
                                <input
                                  type="text"
                                  placeholder="Name"
                                  className={
                                    !isEditEmailBeneficiaryName
                                      ? 'pro-post-tele'
                                      : 'pro-post-tele profile-edit-border'
                                  }
                                  value={
                                    !isEditEmailBeneficiaryName
                                      ? profileData?.beneficiary_name
                                      : profileEmailBeneficiary?.beneficiary_name
                                  }
                                  disabled={!isEditEmailBeneficiaryName}
                                  onChange={(e) => {
                                    setProfileEmailBeneficiary({
                                      ...profileEmailBeneficiary,
                                      beneficiary_name: e.target.value
                                    });
                                    // const error = profileValidation.profileUpdateProperty('email_address', e.target.value)
                                    // setErrorsProfileUpdate({ ...errorsProfileUpdate, "email_address": error })
                                  }}
                                />

                                {!isEditEmailBeneficiaryName ? (
                                  <>
                                    <span
                                      className="profile-edit-button right"
                                      onClick={() => {
                                        setProfileEmailBeneficiary({
                                          ...profileEmailBeneficiary,
                                          beneficiary_name:
                                            profileData?.beneficiary_name,
                                          beneficiary_email:
                                            profileData?.beneficiary_email
                                        });
                                        setIsEditEmailBeneficiaryName(true);
                                      }}
                                    >
                                      Edit
                                    </span>
                                  </>
                                ) : (
                                  <Flex gap="1">
                                    <span
                                      className="profile-edit-button left"
                                      onClick={() => {
                                        setIsEditEmailBeneficiaryName(false);
                                      }}
                                    >
                                      Cancel
                                    </span>
                                    {
                                      <span
                                        className="profile-edit-button right blue"
                                        style={
                                          profileEmailBeneficiary?.beneficiary_name?.trim() !==
                                          profileData?.beneficiary_name?.trim()
                                            ? {}
                                            : {
                                                opacity: '0.3',
                                                cursor: 'not-allowed'
                                              }
                                        }
                                        onClick={() => {
                                          if (
                                            profileEmailBeneficiary?.beneficiary_name?.trim() !==
                                            profileData?.beneficiary_name?.trim()
                                          ) {
                                            // setModalVerifyEmail(true)
                                            // window.scrollTo(0,0)
                                            updateBeneficiaryEmailHandle(
                                              profileEmailBeneficiary
                                            );
                                          }
                                        }}
                                      >
                                        {dictionary['MY-ACCOUNT-title39']}
                                      </span>
                                    }
                                  </Flex>
                                )}
                              </div>
                            </Flex>

                            <Flex direction="col" className="input">
                              <label>{dictionary['MY-ACCOUNT-title53']}</label>
                              <div
                                style={{
                                  position: 'relative'
                                }}
                              >
                                <input
                                  type="email"
                                  placeholder="Email Address"
                                  className={
                                    !isEditEmailBeneficiaryEmail
                                      ? 'pro-post-tele'
                                      : 'pro-post-tele profile-edit-border'
                                  }
                                  value={
                                    !isEditEmailBeneficiaryEmail
                                      ? profileData?.beneficiary_email
                                      : profileEmailBeneficiary?.beneficiary_email
                                  }
                                  disabled={!isEditEmailBeneficiaryEmail}
                                  onChange={(e) => {
                                    setProfileEmailBeneficiary({
                                      ...profileEmailBeneficiary,
                                      beneficiary_email: e.target.value
                                    });
                                    // const error = profileValidation.profileUpdateProperty('email_address', e.target.value)
                                    // setErrorsProfileUpdate({ ...errorsProfileUpdate, "email_address": error })
                                  }}
                                />

                                {!isEditEmailBeneficiaryEmail ? (
                                  <>
                                    <span
                                      className="profile-edit-button right"
                                      onClick={() => {
                                        setProfileEmailBeneficiary({
                                          ...profileEmailBeneficiary,
                                          beneficiary_email:
                                            profileData?.beneficiary_email,
                                          beneficiary_name:
                                            profileData?.beneficiary_name
                                        });
                                        setIsEditEmailBeneficiaryEmail(true);
                                      }}
                                    >
                                      {dictionary['MY-ACCOUNT-title40']}
                                    </span>
                                  </>
                                ) : (
                                  <Flex gap="1">
                                    <span
                                      className="profile-edit-button left"
                                      onClick={() => {
                                        setIsEditEmailBeneficiaryEmail(false);
                                      }}
                                    >
                                      Cancel
                                    </span>
                                    {
                                      <span
                                        className="profile-edit-button right blue"
                                        style={
                                          profileEmailBeneficiary?.beneficiary_email?.trim() !==
                                          profileData?.beneficiary_email?.trim()
                                            ? {}
                                            : {
                                                opacity: '0.3',
                                                cursor: 'not-allowed'
                                              }
                                        }
                                        onClick={() => {
                                          if (
                                            profileEmailBeneficiary?.beneficiary_email?.trim() !==
                                            profileData?.beneficiary_email?.trim()
                                          ) {
                                            if (
                                              /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/.test(
                                                profileEmailBeneficiary?.beneficiary_email?.trim()
                                              )
                                            ) {
                                              // window.scrollTo(0,0)
                                              // setModalVerifyEmail(true)
                                              updateBeneficiaryEmailHandle(
                                                profileEmailBeneficiary
                                              );
                                            } else {
                                              toast.error(
                                                'Please enter a valid email address'
                                              );
                                            }
                                          }
                                        }}
                                      >
                                        {dictionary['MY-ACCOUNT-title39']}
                                      </span>
                                    }
                                  </Flex>
                                )}
                              </div>
                            </Flex>
                          </>
                        ) : (
                          <LoadingAnimation />
                        )}
                      </div>
                    </div>
                    <div className="pro-r">
                      {mailChimpInfo?.status == true && (
                        <div
                          className="pro-post"
                          style={{
                            minHeight: '0px'
                          }}
                        >
                          {mailChimpInfo ? (
                            <>
                              <div
                                className="pro-post-title"
                                style={{
                                  display: 'flex',
                                  gap: '1rem',
                                  justifyContent: 'space-between',
                                  alignItems: 'center',
                                  width: '100%'
                                }}
                              >
                                {dictionary['MY-ACCOUNT-title120']}

                                {mailChimpInfo?.mailchimp_status ==
                                  'subscribed' && (
                                  <h3 style={{ textAlign: 'left' }}>
                                    <b className="badge-subscribe">
                                      Subscribed
                                    </b>
                                  </h3>
                                )}

                                {mailChimpInfo?.mailchimp_status ==
                                  'unsubscribed' && (
                                  <h3 style={{ textAlign: 'left' }}>
                                    <b className="badge-unsubscribe">
                                      Unsubscribed
                                    </b>
                                  </h3>
                                )}

                                {mailChimpInfo?.mailchimp_status ==
                                  'pending' && (
                                  <h3 style={{ textAlign: 'left' }}>
                                    <b className="badge-unsubscribe">Pending</b>
                                  </h3>
                                )}

                                {(mailChimpInfo?.mailchimp_status ==
                                  'cleaned' ||
                                  mailChimpInfo?.mailchimp_status ==
                                    'clean') && (
                                  <h3 style={{ textAlign: 'left' }}>
                                    <b className="badge-cleaned">Cleaned</b>
                                  </h3>
                                )}
                              </div>
                              <div
                                style={{
                                  display: 'flex',
                                  flexDirection: 'column',
                                  gap: '1rem',
                                  justifyContent: 'space-between',
                                  alignItems: 'center',
                                  color: '#153458',
                                  borderRadius: '16px'
                                }}
                              >
                                {mailChimpInfo?.mailchimp_status ==
                                  'subscribed' && (
                                  <>
                                    <p>
                                      By subscribing to our mailing lists,
                                      you'll stay informed about the latest
                                      updates and announcements from Blue Ocean
                                      Society. Simply subscribe and select your
                                      preferred mailing lists below to start
                                      receiving timely updates straight to your
                                      inbox.
                                    </p>
                                    <div
                                      style={{
                                        display: 'flex',
                                        flexDirection: 'column',
                                        justifyContent: 'left',
                                        width: '100%',
                                        gap: '0.3rem'
                                      }}
                                    >
                                      {mailChimpInfo &&
                                      mailChimpInfo?.items?.length > 0 ? (
                                        <>
                                          <br />
                                          <h3>
                                            {dictionary['MY-ACCOUNT-title124']}
                                          </h3>
                                        </>
                                      ) : null}
                                      <br />
                                      {mailChimpInfo && (
                                        <>
                                          {isSubscript ? (
                                            <div className="checkbox">
                                              <div className="content">
                                                <div className="inner-side">
                                                  <input
                                                    type="checkbox"
                                                    id={'checkall'}
                                                    name={'checkall'}
                                                    checked={isSubscript}
                                                    onChange={(event) => {
                                                      setIsSubscript(false);
                                                      setCheckBoxes(
                                                        checkBoxes.map(
                                                          (item) => ({
                                                            ...item,
                                                            value: false
                                                          })
                                                        )
                                                      );
                                                    }}
                                                    // checked={states[index]?.value}
                                                  />
                                                  <label
                                                    className="label"
                                                    htmlFor={'checkall'}
                                                    style={{
                                                      paddingLeft: '1rem'
                                                    }}
                                                  >
                                                    All
                                                  </label>
                                                </div>
                                              </div>
                                            </div>
                                          ) : (
                                            <div className="checkbox">
                                              <div className="content">
                                                <div className="inner-side">
                                                  <input
                                                    type="checkbox"
                                                    id={'checkall'}
                                                    name={'checkall'}
                                                    checked={isSubscript}
                                                    onChange={(event) => {
                                                      setIsSubscript(true);
                                                      setCheckBoxes(
                                                        checkBoxes.map(
                                                          (item) => ({
                                                            ...item,
                                                            value: true
                                                          })
                                                        )
                                                      );
                                                    }}
                                                    // checked={states[index]?.value}
                                                  />
                                                  <label
                                                    className="label"
                                                    htmlFor={'checkall'}
                                                    style={{
                                                      paddingLeft: '1rem'
                                                    }}
                                                  >
                                                    All
                                                  </label>
                                                </div>
                                              </div>
                                            </div>
                                          )}

                                          {mailChimpInfo?.items.map(
                                            (item: any, index: any) => (
                                              <p
                                                style={{
                                                  justifyContent: 'left',
                                                  paddingLeft: '1.2rem'
                                                }}
                                              >
                                                <CheckBox
                                                  index={index}
                                                  states={checkBoxes}
                                                  setStates={setCheckBoxes}
                                                />
                                              </p>
                                            )
                                          )}
                                        </>
                                      )}
                                    </div>
                                    <hr />
                                    <Flex>
                                      {mailChimpInfo &&
                                      mailChimpInfo?.items?.length > 0 ? (
                                        <button
                                          style={{
                                            padding: '1rem'
                                          }}
                                          onClick={SubscriptionHandle}
                                          className="profile-edit-button update pointer"
                                        >
                                          Update
                                        </button>
                                      ) : null}
                                    </Flex>
                                  </>
                                )}

                                {mailChimpInfo?.mailchimp_status ==
                                  'unsubscribed' && (
                                  <>
                                    <div style={{ opacity: '0.5' }}>
                                      <p>
                                        By subscribing to our mailing lists,
                                        you'll stay informed about the latest
                                        updates and announcements from Blue
                                        Ocean Society. Simply subscribe and
                                        select your preferred mailing lists
                                        below to start receiving timely updates
                                        straight to your inbox.
                                      </p>
                                      <div
                                        style={{
                                          display: 'flex',
                                          flexDirection: 'column',
                                          justifyContent: 'left',
                                          width: '100%',
                                          gap: '0.3rem'
                                        }}
                                      >
                                        {mailChimpInfo &&
                                        mailChimpInfo?.items?.length > 0 ? (
                                          <>
                                            <br />
                                            <h3>
                                              {
                                                dictionary[
                                                  'MY-ACCOUNT-title124'
                                                ]
                                              }
                                            </h3>
                                          </>
                                        ) : null}
                                        <br />
                                        {mailChimpInfo && (
                                          <>
                                            {isSubscript ? (
                                              <div className="checkbox">
                                                <div className="content">
                                                  <div className="inner-side">
                                                    <input
                                                      type="checkbox"
                                                      id={'checkall'}
                                                      name={'checkall'}
                                                      checked={isSubscript}
                                                      onChange={() =>
                                                        console.log(
                                                          'NOT ALLOWED'
                                                        )
                                                      }
                                                      // checked={states[index]?.value}
                                                    />
                                                    <label
                                                      className="label"
                                                      htmlFor={'checkall'}
                                                      style={{
                                                        paddingLeft: '1rem'
                                                      }}
                                                    >
                                                      All
                                                    </label>
                                                  </div>
                                                </div>
                                              </div>
                                            ) : (
                                              <div className="checkbox">
                                                <div className="content">
                                                  <div className="inner-side">
                                                    <input
                                                      type="checkbox"
                                                      id={'checkall'}
                                                      name={'checkall'}
                                                      checked={isSubscript}
                                                      onChange={() =>
                                                        console.log(
                                                          'NOT ALLOWED'
                                                        )
                                                      }
                                                      // checked={states[index]?.value}
                                                    />
                                                    <label
                                                      className="label"
                                                      htmlFor={'checkall'}
                                                      style={{
                                                        paddingLeft: '1rem'
                                                      }}
                                                    >
                                                      All
                                                    </label>
                                                  </div>
                                                </div>
                                              </div>
                                            )}

                                            {mailChimpInfo?.items.map(
                                              (item: any, index: any) => (
                                                <p
                                                  style={{
                                                    justifyContent: 'left',
                                                    paddingLeft: '1.2rem'
                                                  }}
                                                >
                                                  <CheckBox
                                                    index={index}
                                                    states={checkBoxes}
                                                    setStates={() =>
                                                      console.log('NOT ALLOWED')
                                                    }
                                                  />
                                                </p>
                                              )
                                            )}
                                          </>
                                        )}
                                      </div>
                                    </div>
                                    <Flex>
                                      <button
                                        style={{
                                          padding: '1rem'
                                        }}
                                        onClick={async () => {
                                          await ReSubscribeHandle();
                                        }}
                                        className="profile-edit-button update pointer"
                                      >
                                        Re-subscribe
                                      </button>
                                    </Flex>
                                  </>
                                )}
                                {(mailChimpInfo?.mailchimp_status ==
                                  'cleaned' ||
                                  mailChimpInfo?.mailchimp_status ==
                                    'clean') && (
                                  <>
                                    <p>
                                      Your email address had issues receiving
                                      emails in the past, therefor our
                                      maillinglist provider does not send emails
                                      to this address anymore. Please change
                                      your email address if you want to receive
                                      future emails.
                                    </p>
                                  </>
                                )}
                                {mailChimpInfo?.mailchimp_status ==
                                  'pending' && (
                                  <>
                                    <p>
                                      To complete your mailing list
                                      subscription, please check your email for
                                      a confirmation message. <br />
                                      <br />
                                      Click the verification link within to
                                      confirm. <br />
                                      <br />
                                      If you don't see the email, check your
                                      spam folder or request another one.
                                    </p>
                                  </>
                                )}
                              </div>
                            </>
                          ) : (
                            <LoadingAnimation />
                          )}
                        </div>
                      )}

                      <div
                        className="pro-r-post"
                        style={{ gridTemplateColumns: '1fr' }}
                      >
                        {profileData ? (
                          <>
                            <div className=" w-full">
                              <div className="pro-r-post-row  w-full">
                                <div className="pro-r-post-title">
                                  {dictionary['MY-ACCOUNT-title54']}
                                </div>

                                {userInfo &&
                                  userInfo?.is_action_limit === 0 &&
                                  userInfo?.membershipStatus === 1 &&
                                  userInfo?.pendingMembershipRequest === 0 && (
                                    <div
                                      style={{
                                        display: 'flex',
                                        justifyContent: 'space-between',
                                        alignItems: 'center',
                                        padding: '1rem 0',
                                        width: '100%'
                                      }}
                                    >
                                      <span>
                                        Your membership will expire in{' '}
                                        {getDaysUntilTimestamp(
                                          Number(
                                            userInfo?.membership_expiration_date
                                          )
                                        )}{' '}
                                        days.
                                      </span>
                                      <Link to={'/membership'}>
                                        <button
                                          style={{
                                            padding: '1rem'
                                          }}
                                          className="profile-edit-button pointer"
                                        >
                                          Renew Now
                                        </button>
                                      </Link>
                                    </div>
                                  )}

                                <div className="pro-r-post-parent flex justify-between w-full gap-20">
                                  <div className="flex flex-col gap-2 w-full">
                                    <div className="flex h-[60px] px-4 rounded-xl w-full bg-[#f7fafc] justify-between items-center text-[#0e608b]">
                                      <div className="text-[16px] font-bold">
                                        {dictionary['MY-ACCOUNT-title55']}
                                      </div>
                                      <div className="text-[14px]">
                                        {profileData?.membership_start_date
                                          ? `${timestampToLocalDaily(
                                              profileData?.membership_start_date /
                                                1000
                                            )}, ${timestampToLocalHour(
                                              profileData?.membership_start_date /
                                                1000
                                            )}`
                                          : '◾️◾️◾️'}
                                        {/* {profileData?.membership_start_date ? `${dateFormat(Number(profileData?.membership_start_date))}` : null} */}
                                      </div>
                                    </div>
                                    <div className="flex h-[60px] px-4 rounded-xl w-full bg-[#f7fafc] justify-between items-center text-[#0e608b]">
                                      <div className="text-[16px] font-bold">
                                        {dictionary['MY-ACCOUNT-title56']}
                                      </div>
                                      <div className="text-[14px]">
                                        {profileData?.membership_expiration_date
                                          ? `${timestampToLocalDaily(
                                              profileData?.membership_expiration_date /
                                                1000
                                            )}, ${timestampToLocalHour(
                                              profileData?.membership_expiration_date /
                                                1000
                                            )}`
                                          : '◾️◾️◾️'}
                                        {/* {profileData?.membership_expiration_date ? `${dateFormat(Number(profileData?.membership_expiration_date))}` : null} */}
                                      </div>
                                    </div>
                                  </div>

                                  <div className="pro-r-post-combo">
                                    <div className="pro-r-post-money">
                                      {flowState
                                        ? `$${flowState?.membership_fee}`
                                        : '0$'}
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </>
                        ) : (
                          <LoadingAnimation />
                        )}
                      </div>

                      <div
                        className="pro-post"
                        style={{
                          minHeight: '418px'
                        }}
                      >
                        {profileData ? (
                          <>
                            <div className="pro-post-title">
                              {dictionary['MY-ACCOUNT-title57']}
                            </div>
                            <div
                              className="pro-post-gird"
                              style={{
                                gap: '14px'
                              }}
                            >
                              <Flex direction="col" className="input">
                                <label>
                                  {dictionary['MY-ACCOUNT-title58']}
                                </label>
                                <input
                                  type="text"
                                  placeholder="Street address"
                                  className="pro-l-input"
                                  value={profileData?.full_name}
                                  disabled={true}
                                />
                              </Flex>
                              <Flex direction="col" className="input">
                                <label>
                                  {dictionary['MY-ACCOUNT-title59']}
                                </label>
                                <div
                                  style={{
                                    position: 'relative'
                                  }}
                                >
                                  <input
                                    type="email"
                                    placeholder="Email Address"
                                    className={
                                      !isEditEmail
                                        ? 'pro-post-tele'
                                        : 'pro-post-tele profile-edit-border'
                                    }
                                    value={
                                      !isEditEmail
                                        ? profileData?.email_address
                                        : profileEmail?.email
                                    }
                                    disabled={!isEditEmail}
                                    onChange={(e) => {
                                      setProfileEmail({
                                        ...profileEmail,
                                        email: e.target.value
                                      });
                                      // const error = profileValidation.profileUpdateProperty('email_address', e.target.value)
                                      // setErrorsProfileUpdate({ ...errorsProfileUpdate, "email_address": error })
                                    }}
                                  />

                                  {!isEditEmail ? (
                                    <>
                                      <span
                                        className="profile-edit-button right"
                                        onClick={() => {
                                          setProfileEmail({
                                            ...profileEmail,
                                            email: profileData?.email_address
                                          });
                                          setIsEditEmail(true);
                                        }}
                                      >
                                        {dictionary['MY-ACCOUNT-title40']}
                                      </span>
                                    </>
                                  ) : (
                                    <Flex gap="1">
                                      <span
                                        className="profile-edit-button left"
                                        onClick={() => {
                                          setIsEditEmail(false);
                                        }}
                                      >
                                        Cancel
                                      </span>
                                      {
                                        <span
                                          className="profile-edit-button right blue"
                                          style={
                                            profileEmail?.email?.trim() !==
                                            profileData?.email_address?.trim()
                                              ? {}
                                              : {
                                                  opacity: '0.3',
                                                  cursor: 'not-allowed'
                                                }
                                          }
                                          onClick={() => {
                                            if (
                                              profileEmail?.email?.trim() !==
                                              profileData?.email_address?.trim()
                                            ) {
                                              if (
                                                /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/.test(
                                                  profileEmail?.email?.trim()
                                                )
                                              ) {
                                                window.scrollTo(0, 0);
                                                setModalVerifyEmail(true);
                                                emailOTPHandle(
                                                  profileEmail.email.trim()
                                                );
                                              } else {
                                                toast.error(
                                                  'Please enter a valid email address'
                                                );
                                              }
                                            }
                                          }}
                                        >
                                          {dictionary['MY-ACCOUNT-title39']}
                                        </span>
                                      }
                                    </Flex>
                                  )}
                                </div>
                                {/* {errorsProfileUpdate && <p style={{ 'color': 'red', 'margin': '0 0 1rem 0' }}>{errorsProfileUpdate?.telegram_username}</p>} */}
                              </Flex>

                              <Flex direction="col" className="input">
                                <label>
                                  {dictionary['MY-ACCOUNT-title60']}
                                </label>
                                {isEditPhone && (
                                  <Input
                                    id={phoneCountry?.id}
                                    currentPrefix={currentPrefix}
                                    setCurrentPrefix={setCurrentPrefix}
                                  />
                                )}
                                <div
                                  style={{
                                    position: 'relative'
                                  }}
                                >
                                  <input
                                    type="text"
                                    placeholder="Phone Number"
                                    className={
                                      !isEditPhone
                                        ? 'pro-post-tele'
                                        : 'pro-post-tele profile-edit-border'
                                    }
                                    value={
                                      !isEditPhone
                                        ? `${phoneCountry?.root}${phoneCountry?.suffixes} ${profileData?.phone_number}`
                                        : profilePhone?.phone_number
                                    }
                                    disabled={!isEditPhone}
                                    onChange={(e) => {
                                      setProfilePhone({
                                        ...profilePhone,
                                        phone_number: e.target.value
                                      });
                                      // const error = profileValidation.profileUpdateProperty('email_address', e.target.value)
                                      // setErrorsProfileUpdate({ ...errorsProfileUpdate, "email_address": error })
                                    }}
                                  />

                                  {!isEditPhone ? (
                                    <>
                                      <span
                                        className="profile-edit-button right"
                                        onClick={() => {
                                          setProfilePhone({
                                            ...profilePhone,
                                            phone_number:
                                              profileData?.phone_number
                                          });
                                          setIsEditPhone(true);
                                        }}
                                      >
                                        {dictionary['MY-ACCOUNT-title40']}
                                      </span>
                                    </>
                                  ) : (
                                    <Flex gap="1">
                                      <span
                                        className="profile-edit-button left"
                                        onClick={() => {
                                          setIsEditPhone(false);
                                        }}
                                      >
                                        {dictionary['MY-ACCOUNT-title6']}
                                      </span>
                                      <span
                                        className="profile-edit-button right blue"
                                        style={
                                          profilePhone?.phone_number?.trim() !==
                                          profileData?.phone_number?.trim()
                                            ? {}
                                            : {
                                                opacity: '0.3',
                                                cursor: 'not-allowed'
                                              }
                                        }
                                        onClick={() => {
                                          if (
                                            profilePhone?.phone_number?.trim() !==
                                            profileData?.phone_number?.trim()
                                          ) {
                                            window.scrollTo(0, 0);
                                            setModalVerifyPhone(true);
                                            phoneOTPHandle(
                                              profilePhone?.country_id,
                                              profilePhone?.phone_number
                                            );
                                          }
                                        }}
                                      >
                                        {dictionary['MY-ACCOUNT-title39']}
                                      </span>
                                    </Flex>
                                  )}
                                </div>
                                {/* {errorsProfileUpdate && <p style={{ 'color': 'red', 'margin': '0 0 1rem 0' }}>{errorsProfileUpdate?.telegram_username}</p>} */}
                              </Flex>
                              <Flex direction="col" className="input">
                                <Flex y="center" gap={0.5}>
                                  <label>
                                    {dictionary['MY-ACCOUNT-title61']}
                                  </label>
                                  <div
                                    className="tooltip-info tooltip-info-normal tooltip-info-normal-inside"
                                    style={{
                                      left: 0,
                                      width: 20,
                                      margin: '-12px 0px 0px'
                                    }}
                                  >
                                    <AiOutlineInfoCircle className="tooltip-info-icon" />
                                    <div className="tooltip-info-text">
                                      <span>
                                        {dictionary['MY-ACCOUNT-title62']}
                                      </span>
                                    </div>
                                  </div>
                                </Flex>

                                <div
                                  style={{
                                    position: 'relative'
                                  }}
                                >
                                  <input
                                    type="text"
                                    placeholder="Telegram"
                                    className={
                                      !isEditTelegram
                                        ? 'pro-post-tele'
                                        : 'pro-post-tele profile-edit-border'
                                    }
                                    value={
                                      !isEditTelegram
                                        ? profileData?.telegram_username
                                        : profileTelegram?.telegram_username
                                    }
                                    disabled={!isEditTelegram}
                                    onChange={(e) => {
                                      setProfileTelegram({
                                        ...profileTelegram,
                                        telegram_username: e.target.value
                                      });
                                      const error =
                                        profileValidation.profileUpdateProperty(
                                          'telegram_username',
                                          e.target.value
                                        );
                                      setErrorsProfileUpdate({
                                        ...errorsProfileUpdate,
                                        telegram_username: error
                                      });
                                    }}
                                  />

                                  {!isEditTelegram ? (
                                    <>
                                      <span
                                        className="profile-edit-button right"
                                        onClick={() => {
                                          setProfileTelegram({
                                            ...profileTelegram,
                                            telegram_username:
                                              profileData?.telegram_username
                                          });
                                          setIsEditTelegram(true);
                                        }}
                                      >
                                        {dictionary['MY-ACCOUNT-title40']}
                                      </span>
                                    </>
                                  ) : (
                                    <Flex gap="1">
                                      <span
                                        className="profile-edit-button left"
                                        onClick={() => {
                                          setIsEditTelegram(false);
                                        }}
                                      >
                                        {dictionary['MY-ACCOUNT-title6']}
                                      </span>
                                      <span
                                        className="profile-edit-button right blue"
                                        style={
                                          profileTelegram?.telegram_username?.trim() !==
                                          profileData?.telegram_username?.trim()
                                            ? {}
                                            : {
                                                opacity: '0.3',
                                                cursor: 'not-allowed'
                                              }
                                        }
                                        onClick={() => {
                                          if (
                                            profileTelegram?.telegram_username?.trim() !==
                                            profileData?.telegram_username?.trim()
                                          ) {
                                            // window.scrollTo(0,0)
                                            updateHandle();
                                          }
                                        }}
                                      >
                                        {dictionary['MY-ACCOUNT-title39']}
                                      </span>
                                    </Flex>
                                  )}
                                </div>
                                {/* {errorsProfileUpdate && <p style={{ 'color': 'red', 'margin': '0 0 1rem 0' }}>{errorsProfileUpdate?.telegram_username}</p>} */}
                              </Flex>

                              {/* X */}
                              <Flex direction="col" className="input">
                                <label>
                                  {dictionary['MY-ACCOUNT-title61-A']}
                                </label>
                                <div
                                  style={{
                                    position: 'relative'
                                  }}
                                >
                                  <input
                                    type="text"
                                    placeholder="Name"
                                    className={
                                      !isEditFacebook
                                        ? 'pro-post-tele'
                                        : 'pro-post-tele profile-edit-border'
                                    }
                                    value={
                                      !isEditFacebook
                                        ? profileData?.social_facebook_link
                                        : profileFacebook?.social_facebook_link
                                    }
                                    disabled={!isEditFacebook}
                                    onChange={(e) => {
                                      setProfileFacebook({
                                        ...profileFacebook,
                                        social_facebook_link: e.target.value
                                      });
                                      // const error = profileValidation.profileUpdateProperty('email_address', e.target.value)
                                      // setErrorsProfileUpdate({ ...errorsProfileUpdate, "email_address": error })
                                    }}
                                  />

                                  {!isEditFacebook ? (
                                    <>
                                      <span
                                        className="profile-edit-button right"
                                        onClick={() => {
                                          setProfileFacebook({
                                            ...profileFacebook,
                                            social_facebook_link:
                                              profileData?.social_facebook_link
                                          });
                                          setIsEditFacebook(true);
                                        }}
                                      >
                                        Edit
                                      </span>
                                    </>
                                  ) : (
                                    <Flex gap="1">
                                      <span
                                        className="profile-edit-button left"
                                        onClick={() => {
                                          setIsEditFacebook(false);
                                        }}
                                      >
                                        Cancel
                                      </span>
                                      {
                                        <span
                                          className="profile-edit-button right blue"
                                          style={
                                            profileFacebook?.social_facebook_link?.trim() !==
                                            profileData?.social_facebook_link?.trim()
                                              ? {}
                                              : {
                                                  opacity: '0.3',
                                                  cursor: 'not-allowed'
                                                }
                                          }
                                          onClick={() => {
                                            if (
                                              profileFacebook?.social_facebook_link?.trim() !==
                                              profileData?.social_facebook_link?.trim()
                                            ) {
                                              // setModalVerifyEmail(true)
                                              // window.scrollTo(0,0)
                                              updateFacebookHandle(
                                                profileFacebook
                                              );
                                            }
                                          }}
                                        >
                                          {dictionary['MY-ACCOUNT-title39']}
                                        </span>
                                      }
                                    </Flex>
                                  )}
                                </div>
                              </Flex>

                              {/* W */}
                              <Flex direction="col" className="input">
                                <label>
                                  {dictionary['MY-ACCOUNT-title61-B']}
                                </label>
                                <div
                                  style={{
                                    position: 'relative'
                                  }}
                                >
                                  <input
                                    type="text"
                                    placeholder="Name"
                                    className={
                                      !isEditLinkedIn
                                        ? 'pro-post-tele'
                                        : 'pro-post-tele profile-edit-border'
                                    }
                                    value={
                                      !isEditLinkedIn
                                        ? profileData?.social_linkdin_link
                                        : profileLinkedIn?.social_linkdin_link
                                    }
                                    disabled={!isEditLinkedIn}
                                    onChange={(e) => {
                                      setProfileLinkedIn({
                                        ...profileLinkedIn,
                                        social_linkdin_link: e.target.value
                                      });
                                      // const error = profileValidation.profileUpdateProperty('email_address', e.target.value)
                                      // setErrorsProfileUpdate({ ...errorsProfileUpdate, "email_address": error })
                                    }}
                                  />

                                  {!isEditLinkedIn ? (
                                    <>
                                      <span
                                        className="profile-edit-button right"
                                        onClick={() => {
                                          setProfileLinkedIn({
                                            ...profileLinkedIn,
                                            social_linkdin_link:
                                              profileData?.social_linkdin_link
                                          });
                                          setIsEditLinkedIn(true);
                                        }}
                                      >
                                        Edit
                                      </span>
                                    </>
                                  ) : (
                                    <Flex gap="1">
                                      <span
                                        className="profile-edit-button left"
                                        onClick={() => {
                                          setIsEditLinkedIn(false);
                                        }}
                                      >
                                        Cancel
                                      </span>
                                      {
                                        <span
                                          className="profile-edit-button right blue"
                                          style={
                                            profileLinkedIn?.social_linkdin_link?.trim() !==
                                            profileData?.social_linkdin_link?.trim()
                                              ? {}
                                              : {
                                                  opacity: '0.3',
                                                  cursor: 'not-allowed'
                                                }
                                          }
                                          onClick={() => {
                                            if (
                                              profileLinkedIn?.social_linkdin_link?.trim() !==
                                              profileData?.social_linkdin_link?.trim()
                                            ) {
                                              // setModalVerifyEmail(true)
                                              // window.scrollTo(0,0)
                                              updateLinkedInHandle(
                                                profileLinkedIn
                                              );
                                            }
                                          }}
                                        >
                                          {dictionary['MY-ACCOUNT-title39']}
                                        </span>
                                      }
                                    </Flex>
                                  )}
                                </div>
                              </Flex>

                              {/* M */}
                              <Flex direction="col" className="input">
                                <label>
                                  {dictionary['MY-ACCOUNT-title61-C']}
                                </label>
                                <div
                                  style={{
                                    position: 'relative'
                                  }}
                                >
                                  <input
                                    type="text"
                                    placeholder="Name"
                                    className={
                                      !isEditX
                                        ? 'pro-post-tele'
                                        : 'pro-post-tele profile-edit-border'
                                    }
                                    value={
                                      !isEditX
                                        ? profileData?.social_x_link
                                        : profileX?.social_x_link
                                    }
                                    disabled={!isEditX}
                                    onChange={(e) => {
                                      setProfileX({
                                        ...profileX,
                                        social_x_link: e.target.value
                                      });
                                      // const error = profileValidation.profileUpdateProperty('email_address', e.target.value)
                                      // setErrorsProfileUpdate({ ...errorsProfileUpdate, "email_address": error })
                                    }}
                                  />

                                  {!isEditX ? (
                                    <>
                                      <span
                                        className="profile-edit-button right"
                                        onClick={() => {
                                          setProfileX({
                                            ...profileX,
                                            social_x_link:
                                              profileData?.social_x_link
                                          });
                                          setIsEditX(true);
                                        }}
                                      >
                                        Edit
                                      </span>
                                    </>
                                  ) : (
                                    <Flex gap="1">
                                      <span
                                        className="profile-edit-button left"
                                        onClick={() => {
                                          setProfileX(false);
                                        }}
                                      >
                                        Cancel
                                      </span>
                                      {
                                        <span
                                          className="profile-edit-button right blue"
                                          style={
                                            profileX?.social_x_link?.trim() !==
                                            profileData?.social_x_link?.trim()
                                              ? {}
                                              : {
                                                  opacity: '0.3',
                                                  cursor: 'not-allowed'
                                                }
                                          }
                                          onClick={() => {
                                            if (
                                              profileX?.social_x_link?.trim() !==
                                              profileData?.social_x_link?.trim()
                                            ) {
                                              // setModalVerifyEmail(true)
                                              // window.scrollTo(0,0)
                                              updateXHandle(profileX);
                                            }
                                          }}
                                        >
                                          {dictionary['MY-ACCOUNT-title39']}
                                        </span>
                                      }
                                    </Flex>
                                  )}
                                </div>
                              </Flex>
                            </div>
                          </>
                        ) : (
                          <LoadingAnimation />
                        )}
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </Flex>
          </Container>
        </Layout>
      </>
    );
  else return <>{navigate('/')}</>;
};

export default Profile;
