import './App.scss';
import IndexRouter from './routes';
import { Toaster } from 'react-hot-toast';
import Theme from './styles/Theme';
import useClearStorages from './hooks/useDestroyCache';
import config from './config';
import settingService from './services/settingService';
import { useEffect, useState } from 'react';
import Loader from './components/Loader';
import Maintenance from './components/Maintenance';
import { Flex } from './styles/Styles';
import { broadcastChannel } from './services/BroadcastChannel';
import './styles/tailwind.css';
import "react-datepicker/dist/react-datepicker.css";


function App() {
  useClearStorages(config.version)

  const [maintenanceStatus, setMaintenanceStatus] = useState<any>(null)
  async function loadMaintenanceStatus() {
    await settingService.getMaintenanceStatus().then((data) => setMaintenanceStatus(data?.status))
  }

  useEffect(() => {
    loadMaintenanceStatus()
  }, [])

  useEffect(() => {
    const handleLogoutEvent = (event: any) => {
      if (event.data.type === 'logout') {
        window.location.href = '/login';
      }
    };

    broadcastChannel.addEventListener('message', handleLogoutEvent);

    return () => broadcastChannel.removeEventListener('message', handleLogoutEvent);
  }, []);

  if (maintenanceStatus == 0) {
    return (
      <Theme>
        <Toaster position="bottom-center" />
        <IndexRouter setMaintenanceStatus={setMaintenanceStatus} />
      </Theme>
    );
  } else if (maintenanceStatus == 1) {
    return (
      <Theme>
        <Maintenance />
      </Theme>
    );
  } else {
    return <Theme>
      <Theme>
        <Toaster position="bottom-center" />
        <IndexRouter setMaintenanceStatus={setMaintenanceStatus} />
      </Theme>
      {/* <Flex gap={1.7} x={'center'} y={'center'}>
        <Loader />
      </Flex> */}
    </Theme>
  }

}

export default App;
