import { useContext, useEffect, useState } from 'react';
import { useWindowWidth } from '../breakpoint';
import './style.css';
import WalletContext from '../../../context/private/walletContext';
import { numberRounder, numberWithCommas } from '../../../utils/tools';
import PrivateContext from '../../../context/private/privateContext';
import { ModalMethods, SuccessModal } from '../Success';
import confirmedIcon from '../assets/vector-6@2x.png';
import dottedLine from '../assets/line-9@2x.png';
import line7 from '../assets/line-7.png';
import closeIcon from '../assets/union-3@2x.png';

export const TransferModal = ({ islandID = null }) => {
  const screenWidth = useWindowWidth();

  const [inputAmount, setInputAmount] = useState('');
  const { settings, setUpdateTimer } = useContext(PrivateContext);
  const {
    balances,
    loadWalletInfoDepositModal,
    walletInfoDepositModal,
    walletListCrypto,
    loadCryptoWallet,
    walletListBank,
    loadBankWallet,
    allAccountsCrypto,
    allAccountsWire,
    loadAccounts,
    depositHandle,
    datePickerWireTransfer,
    datePickerWireExp,
    datePickerCryptoTransfer,
    withdrawFormData,
    setWithdrawFormData,
    withdrawHandle,
    modalDeposit,
    setModalDeposit,
    depositWireFormData,
    setDepositWireFormData,
    depositCryptoFormData,
    setDepositCryptoFormData,
    balancesNew,
    transferHandle,
    setDatePickerCryptoTransfer,
    depositSuccessData,
    setDepositSuccessData,
    modal,
    setModal,
    transferSuccessData,
    setTransferSuccessData,
    ckeditorTransferModal,
    loadCkEditorByID
  } = useContext(WalletContext);

  useEffect(() => {
    setUpdateTimer(+new Date());
    modal === false && setTransferSuccessData(null);
  }, [modal]);

  useEffect(() => {
    loadCkEditorByID(5);
  }, []);





  return (
    <>
      {!transferSuccessData && modal && (
        <div className="blur-bg" onClick={() => setModal(false)}></div>
      )}

      {!transferSuccessData && modal && (
        <div className="island-transfer-modal">
          <div
            className="modal-mobile"
            style={{
              height:
                screenWidth < 1037
                  ? '1294px'
                  : screenWidth >= 1037
                  ? '704px'
                  : undefined,
              overflow: screenWidth < 1037 ? 'hidden' : undefined,
              width:
                screenWidth < 1037
                  ? '564px'
                  : screenWidth >= 1037
                  ? '1037px'
                  : undefined
            }}
          >
            <div
              className="content"
              style={{
                alignItems:
                  screenWidth < 1037
                    ? 'flex-start'
                    : screenWidth >= 1037
                    ? 'center'
                    : undefined,
                flexDirection: screenWidth < 1037 ? 'column' : undefined,
                gap: screenWidth < 1037 ? '20px' : undefined,
                justifyContent:
                  screenWidth >= 1037 ? 'space-between' : undefined,
                width:
                  screenWidth < 1037
                    ? '524px'
                    : screenWidth >= 1037
                    ? '989px'
                    : undefined
              }}
            >
              {screenWidth < 1037 && (
                <>
                  <div className="left">
                    <div className="details">
                      <div className="payment-detail">
                        <div className="text-wrapper-2">BOS Balance</div>
                        <div className="text-wrapper-3">
                          {balancesNew?.main_balance ||
                          balancesNew?.bos_interest ||
                          balancesNew?.main_balance === 0 ||
                          balancesNew?.bos_interest === 0 ? (
                            `$${numberWithCommas(
                              Number(balancesNew?.main_balance) +
                                Number(balancesNew?.bos_interest),
                              2
                            )}`
                          ) : (
                            <div className="skeleton-element-item skeleton-box"></div>
                          )}
                        </div>
                      </div>
                      <div className="payment-detail">
                        <div className="text-wrapper-2">
                          BOS Gratitude Balance
                        </div>
                        <div className="text-wrapper-3">
                          {islandID?.available_profit ||
                          islandID?.available_profit === 0 ? (
                            `$${numberWithCommas(
                              islandID?.available_profit,
                              2
                            )}`
                          ) : (
                            <div className="skeleton-element-item skeleton-box"></div>
                          )}
                        </div>
                      </div>
                      <div className="payment-detail">
                        <div className="text-wrapper-2">BOS Fee</div>
                        <div className="text-wrapper-3">
                          {settings?.withdraw_gratitude_fee}%
                        </div>
                      </div>
                    </div>
                    <div className="enterance">
                      <input
                        type="text"
                        placeholder="Enter an amount"
                        className="input mod-inside-body-input"
                        value={withdrawFormData.amount}
                        onChange={(e) =>
                          islandID?.available_profit >= +e.target.value &&
                          setWithdrawFormData({
                            ...withdrawFormData,
                            amount: e.target.value
                          })
                        }
                      />
                      <div className="options">
                        <p className="min pointer">
                          <span className="span">Min:</span>
                          <span className="text-wrapper-4">&nbsp;</span>
                          <span className="text-wrapper-5">No limit</span>
                        </p>
                        <p
                          className="max pointer"
                          onClick={(e) =>
                            setWithdrawFormData({
                              ...withdrawFormData,
                              amount: numberRounder(islandID?.available_profit)
                            })
                          }
                        >
                          <span className="span">Max:</span>
                          <span className="text-wrapper-4">&nbsp;</span>
                          <span className="text-wrapper-5">
                            {islandID?.available_profit ||
                            islandID?.available_profit === 0 ? (
                              `$${numberWithCommas(
                                islandID?.available_profit,
                                2
                              )}`
                            ) : (
                              <div className="skeleton-element-item skeleton-box"></div>
                            )}
                          </span>
                        </p>
                      </div>
                    </div>
                    <div className="description">
                      <div className="overlap-group">
                        <p
                          className="please-ensure-to"
                          dangerouslySetInnerHTML={{
                            __html: ckeditorTransferModal
                          }}
                        ></p>
                      </div>
                    </div>
                  </div>
                  <div className="right">
                    <div className="header">
                      <div className="success-icon">
                        <div className="overlap">
                          <div className="vuesax-bold-tick">
                            <div className="tick-circle-wrapper">
                              <div className="tick-circle">
                                <div className="overlap-group-2">
                                  <div className="rectangle" />
                                  <img
                                    className="vector"
                                    alt="Vector"
                                    src="https://anima-uploads.s3.amazonaws.com/projects/64b77eb848521524cc106dda/releases/64d7361b8264fdbeecae76b1/img/vector-4@2x.png"
                                  />
                                </div>
                                <img
                                  className="img"
                                  alt="Vector"
                                  src="https://anima-uploads.s3.amazonaws.com/projects/64b77eb848521524cc106dda/releases/64d7361b8264fdbeecae76b1/img/vector-5@2x.png"
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="header-text">
                        <div className="text-wrapper-10">
                          Your Requested Amount
                        </div>
                        <div className="text-wrapper-11">
                          ${numberWithCommas(withdrawFormData.amount, 0)}
                        </div>
                      </div>
                    </div>
                    <div className="frame">
                      <div className="group">
                        <div className="overlap-group-3">
                          <div className="rectangle-2" />
                          <div className="payment-details">
                            <div className="div-2">
                              <div className="payment-detail">
                                <div className="text-wrapper-2">
                                  Payment Method
                                </div>
                                <div className="text-wrapper-3">Transfer</div>
                              </div>
                              <div className="payment-detail">
                                <div className="text-wrapper-2">
                                  Requested Amount
                                </div>
                                <div className="text-wrapper-3">
                                  ${numberWithCommas(withdrawFormData.amount)}
                                </div>
                              </div>
                            </div>
                            <img
                              className="line"
                              alt="Line"
                              src="https://anima-uploads.s3.amazonaws.com/projects/64b77eb848521524cc106dda/releases/64d7361b8264fdbeecae76b1/img/line-6@2x.png"
                            />
                            <div className="div-2">
                              <div className="payment-detail">
                                <div className="text-wrapper-2">BOS Fee</div>
                                <div className="text-wrapper-3">
                                  $
                                  {numberWithCommas(
                                    withdrawFormData.amount *
                                      (settings?.withdraw_gratitude_fee / 100)
                                  )}
                                </div>
                              </div>
                              <div className="payment-detail">
                                <div className="text-wrapper-2">
                                  Received Amount
                                </div>
                                <div className="text-wrapper-3">
                                  $
                                  {numberWithCommas(
                                    Number(withdrawFormData.amount) -
                                      withdrawFormData.amount *
                                        (settings?.withdraw_gratitude_fee / 100)
                                  )}
                                </div>
                              </div>
                              <div className="payment-detail">
                                <p className="text-wrapper-2">
                                  Your BOS Balance will be
                                </p>
                                <div className="text-wrapper-3">
                                  $
                                  {numberWithCommas(
                                    Number(balancesNew?.main_balance) +
                                      Number(balancesNew?.bos_interest) +
                                      (Number(withdrawFormData.amount) -
                                        withdrawFormData.amount *
                                          (settings?.withdraw_gratitude_fee /
                                            100))
                                  )}
                                </div>
                              </div>
                              <div className="payment-detail">
                                <p className="text-wrapper-2">
                                  Your BOS Gratitude Balance will be
                                </p>
                                <div className="text-wrapper-3">
                                  $
                                  {numberWithCommas(
                                    Number(islandID?.available_profit) -
                                      Number(withdrawFormData.amount)
                                  )}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="item">
                        <div
                          className="div-wrapper pointer"
                          onClick={() => {
                            if (
                              withdrawFormData.amount <=
                              islandID?.available_profit
                            ) {
                              if (islandID) {
                                transferHandle(islandID?.island_id);
                              } else {
                                transferHandle();
                              }
                            } else {
                              toast.error(dictionary['MY-WALLET-title82']);
                            }
                          }}
                        >
                          <div className="text-wrapper-12">Submit</div>
                        </div>
                      </div>
                    </div>
                    <img
                      className="line-2"
                      alt="Line"
                      src="https://anima-uploads.s3.amazonaws.com/projects/64b77eb848521524cc106dda/releases/64d7361b8264fdbeecae76b1/img/line-5@2x.png"
                    />
                  </div>
                </>
              )}

              {screenWidth >= 1037 && (
                <>
                  <div className="left-2">
                    <div className="details">
                      <div className="payment-detail">
                        <div className="text-wrapper-2">BOS Balance</div>
                        <div className="text-wrapper-3">
                          {balancesNew?.main_balance ||
                          balancesNew?.bos_interest ||
                          balancesNew?.main_balance === 0 ||
                          balancesNew?.bos_interest === 0 ? (
                            `$${numberWithCommas(
                              Number(balancesNew?.main_balance) +
                                Number(balancesNew?.bos_interest),
                              2
                            )}`
                          ) : (
                            <div className="skeleton-element-item skeleton-box"></div>
                          )}
                        </div>
                      </div>
                      <div className="payment-detail">
                        <div className="text-wrapper-2">
                          BOS Gratitude Balance
                        </div>
                        <div className="text-wrapper-3">
                          {islandID?.available_profit ||
                          islandID?.available_profit === 0 ? (
                            `$${numberWithCommas(
                              islandID?.available_profit,
                              2
                            )}`
                          ) : (
                            <div className="skeleton-element-item skeleton-box"></div>
                          )}
                        </div>
                      </div>
                      <div className="payment-detail">
                        <div className="text-wrapper-2">BOS Fee</div>
                        <div className="text-wrapper-3">
                          {settings?.withdraw_gratitude_fee}%
                        </div>
                      </div>
                    </div>
                    <div className="enterance">
                      <input
                        type="text"
                        placeholder="Enter an amount"
                        className="input mod-inside-body-input"
                        value={withdrawFormData.amount}
                        onChange={(e) =>
                          islandID?.available_profit >= +e.target.value &&
                          setWithdrawFormData({
                            ...withdrawFormData,
                            amount: e.target.value
                          })
                        }
                      />
                      <div className="options">
                        <p className="min">
                          <span className="span">Min:</span>
                          <span className="text-wrapper-4">&nbsp;</span>
                          <span className="text-wrapper-5">No limit</span>
                        </p>
                        <p
                          className="max pointer"
                          onClick={(e) =>
                            setWithdrawFormData({
                              ...withdrawFormData,
                              amount: numberRounder(islandID?.available_profit)
                            })
                          }
                        >
                          <span className="span">Max:</span>
                          <span className="text-wrapper-4">&nbsp;</span>
                          <span className="text-wrapper-5">
                            {islandID?.available_profit ||
                            islandID?.available_profit === 0 ? (
                              `$${numberWithCommas(
                                islandID?.available_profit,
                                2
                              )}`
                            ) : (
                              <div className="skeleton-element-item skeleton-box"></div>
                            )}
                          </span>
                        </p>
                      </div>
                    </div>
                    <div className="description">
                      <div className="overlap-group-4">
                        <p
                          className="please-ensure-to"
                          dangerouslySetInnerHTML={{
                            __html: ckeditorTransferModal
                          }}
                        ></p>
                      </div>
                    </div>
                  </div>
                  <img className="line-3" alt="Line" src={line7} />
                  <div className="right-2">
                    <div className="header">
                      <div className="success-icon">
                        <div className="overlap">
                          <div className="vuesax-bold-tick">
                            <div className="tick-circle-wrapper">
                              <div className="tick-circle">
                                <div className="overlap-group-2">
                                  <div className="rectangle" />
                                  <img
                                    className="vector"
                                    alt="Vector"
                                    src={confirmedIcon}
                                  />
                                </div>
                                <img
                                  className="vector-2"
                                  alt="Vector"
                                  src="https://anima-uploads.s3.amazonaws.com/projects/64b77eb848521524cc106dda/releases/64d7361b8264fdbeecae76b1/img/vector-5@2x.png"
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="header-text">
                        <div className="text-wrapper-10">
                          Your Requested Amount
                        </div>
                        <div className="text-wrapper-11">
                          ${numberWithCommas(withdrawFormData.amount, 0)}
                        </div>
                      </div>
                    </div>
                    <div className="frame-3">
                      <div className="overlap-group-wrapper">
                        <div className="overlap-group-3">
                          <div className="rectangle-3" />
                          <div className="payment-details-2">
                            <div className="div-2">
                              <div className="payment-detail">
                                <div className="text-wrapper-2">
                                  Payment Method
                                </div>
                                <div className="text-wrapper-3">Transfer</div>
                              </div>
                              <div className="payment-detail">
                                <div className="text-wrapper-2">
                                  Requested Amount
                                </div>
                                <div className="text-wrapper-3">
                                  ${numberWithCommas(withdrawFormData.amount)}
                                </div>
                              </div>
                            </div>
                            <img
                              className="line-4"
                              alt="Line"
                              src={dottedLine}
                            />
                            <div className="div-2">
                              <div className="payment-detail">
                                <div className="text-wrapper-2">BOS Fee</div>
                                <div className="text-wrapper-3">
                                  $
                                  {numberWithCommas(
                                    withdrawFormData.amount *
                                      (settings?.withdraw_gratitude_fee / 100)
                                  )}
                                </div>
                              </div>
                              <div className="payment-detail">
                                <div className="text-wrapper-2">
                                  Received Amount
                                </div>
                                <div className="text-wrapper-3">
                                  $
                                  {numberWithCommas(
                                    Number(withdrawFormData.amount) -
                                      withdrawFormData.amount *
                                        (settings?.withdraw_gratitude_fee / 100)
                                  )}
                                </div>
                              </div>
                              <div className="payment-detail">
                                <p className="text-wrapper-2">
                                  Your BOS Balance will be
                                </p>
                                <div className="text-wrapper-3">
                                  $
                                  {numberWithCommas(
                                    Number(balancesNew?.main_balance) +
                                      Number(balancesNew?.bos_interest) +
                                      (Number(withdrawFormData.amount) -
                                        withdrawFormData.amount *
                                          (settings?.withdraw_gratitude_fee /
                                            100))
                                  )}
                                </div>
                              </div>
                              <div className="payment-detail">
                                <p className="text-wrapper-2">
                                  Your BOS Gratitude Balance will be
                                </p>
                                <div className="text-wrapper-3">
                                  $
                                  {numberWithCommas(
                                    Number(islandID?.available_profit) -
                                      Number(withdrawFormData.amount)
                                  )}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div
                        className="overlap-wrapper pointer"
                        onClick={() => {
                          if (
                            withdrawFormData.amount <=
                            islandID?.available_profit
                          ) {
                            if (islandID) {
                              transferHandle(islandID?.island_id);
                            } else {
                              transferHandle();
                            }
                          } else {
                            toast.error(dictionary['MY-WALLET-title82']);
                          }
                        }}
                      >
                        <div className="overlap-2">
                          <div className="text-wrapper-13">Submit</div>
                        </div>
                      </div>
                    </div>
                    <img
                      className="line-2"
                      alt="Line"
                      src="https://anima-uploads.s3.amazonaws.com/projects/64b77eb848521524cc106dda/releases/64d7361b8264fdbeecae76b1/img/line-5@2x.png"
                    />
                  </div>
                </>
              )}
            </div>
            <div
              className="hr"
              style={{
                width:
                  screenWidth < 1037
                    ? '565px'
                    : screenWidth >= 1037
                    ? '1037px'
                    : undefined
              }}
            />
            <div
              className="header-2"
              style={{
                width:
                  screenWidth < 1037
                    ? '516px'
                    : screenWidth >= 1037
                    ? '993px'
                    : undefined
              }}
            >
              <div className="text-wrapper-14">Transfer</div>
              <div className="close-btn" onClick={() => setModal(false)}>
                <img
                  className="union"
                  alt="Union"
                  src={
                    (screenWidth >= 564 && screenWidth < 1037) ||
                    screenWidth < 564
                      ? 'https://anima-uploads.s3.amazonaws.com/projects/64b77eb848521524cc106dda/releases/64d7420f1ba4c21dede3e26a/img/union-1.svg'
                      : screenWidth >= 1037
                      ? 'https://anima-uploads.s3.amazonaws.com/projects/64b77eb848521524cc106dda/releases/64d7420f1ba4c21dede3e26a/img/union.svg'
                      : undefined
                  }
                />
              </div>
            </div>
          </div>
        </div>
      )}

      {modal && transferSuccessData && (
        <SuccessModal
          requestedAmount={transferSuccessData?.requestedAmount}
          txID={transferSuccessData?.txID}
          method={ModalMethods.Transfer}
          time={transferSuccessData?.time}
          fee={transferSuccessData?.fee}
          finalAmount={transferSuccessData?.finalAmount}
          closeState={setModal}
        />
      )}
    </>
  );
};
