import { useContext, useEffect, useState } from "react";
import NotFoundImage from "../../../assets/images/not-found.png";
import IslandsContext from "../../../context/private/islandsContext";
import { Flex } from "../../../styles/Styles";
import { numberWithCommas, timeToYYYYMMDD, timestampToLocalDaily, timestampToLocalHour } from "../../../utils/tools";
import PrivateContext from "../../../context/private/privateContext";
import { IslandsItemContext } from "../Index";
import Loader from "../../Loader";
import config from "../../../config";
import { Link } from "react-router-dom";

import Pagination from 'react-responsive-pagination';

import dictionary from '../../../locals/my-treasures.json';
import { AiOutlineInfoCircle } from "react-icons/ai";
import logo from "../../../assets/images/thumbnail-updates.png";
import AccountContext from "../../../context/private/accountContext";
import useExternalScript from "../../../hooks/useExternalScript";


const Updates = () => {
    const [boxHeight, setBoxHeight] = useState<string | number>(134);
    const [moreText, setMoreTest] = useState('More');
    const moreHandler = () => {
        if (moreText === 'More') {
            setMoreTest('Less');
            setBoxHeight('auto');
        } else {
            setMoreTest('More');
            setBoxHeight(134);
        }
    };

    const { flowState } = useContext(PrivateContext)
    const { loadUpdate } = useContext(IslandsContext)
    const { currentId, setCurrentTab } = useContext(IslandsItemContext)
    const { userInfo } = useContext(AccountContext)

    const [islandUpdate, setIslandUpdate] = useState<any>(null)

    const [modalIsOpen, setModalIsOpen] = useState(false)
    const [modalDescription, setModalDescription] = useState('')
    const [modalTitle, setModalTitle] = useState('')
    const [currentPage, setCurrentPage] = useState<any>(1)

    useEffect(() => {
        if (flowState.step === 17 && userInfo
            && userInfo?.membershipStatus !== 2) {
            loadUpdate(currentId, setIslandUpdate, currentPage)
        }
    }, [flowState, currentPage, userInfo])


    const [scripts, setScripts] = useState<string[]>([]); // به عنوان یک آرایه از لینک‌های اسکریپت
    useEffect(() => {
        if (modalDescription) {
            const parser = new DOMParser();
            const doc = parser.parseFromString(modalDescription, 'text/html');
            const scriptTags = doc.getElementsByTagName('script');
            const scriptSrcs = Array.from(scriptTags).map(script => script.src).filter(src => src);
            setScripts(scriptSrcs);
        }
    }, [modalDescription]);

    useEffect(() => {
        scripts.forEach((script, index) => {
            const existingScript = document.getElementById(`script-${index}`);
            if (!existingScript) {
                const scriptElement = document.createElement('script');
                scriptElement.src = script;
                scriptElement.id = `script-${index}`;
                scriptElement.async = true;
                document.body.appendChild(scriptElement);
            }
        });
    }, [scripts]);

    if (islandUpdate === null) return <Loader />
    return (
        <>
            {modalIsOpen && <div className="blur-bg" onClick={() => setModalIsOpen(false)}></div>}

            {modalIsOpen &&
                <div className="mod" >

                    <div className="mod-border">
                        <div className="mod-box">
                            <p className="mod-box-p">{modalTitle}</p>
                            <button className="mod-box-btn" onClick={() => setModalIsOpen(false)}>X</button>
                        </div>

                        <div className="mod-inside">
                            <div className='mod-inside-body'>
                                <p className="modal-description" dangerouslySetInnerHTML={{ __html: modalDescription }}></p>
                            </div>

                            <Flex x="center" gap="1">
                                <button onClick={() => {
                                    setCurrentTab(0)
                                    window.scrollTo(0, 0)
                                }} className="mod-inside-body-btn pointer" style={{ width: '100%' }}>
                                    Back to Overview
                                </button>
                            </Flex>

                        </div>
                    </div>
                </div>}

            <div className="myt-body">
                {islandUpdate?.msg?.length === 0 ? (
                    <img src={NotFoundImage} />
                ) : islandUpdate && islandUpdate?.msg?.length > 0 ? (
                    islandUpdate?.msg?.map((item: any, index: number) => (
                        <div key={index} className="myt-body-border">
                            <div className="myt-body-border-image">
                                <img src={item?.image ? config.server + item?.image : logo} alt="nature" />
                            </div>
                            <div className="myt-body-detail">
                                <div>
                                    <div className="myt-body-header">
                                        <div className="pointer" onClick={() => {
                                            setModalIsOpen(true)
                                            window.scrollTo(0, 0)
                                            setModalDescription(item?.content)
                                            setModalTitle(item?.title)
                                        }}>
                                            <div className="myt-body-header-t">
                                                {item?.title}
                                            </div>
                                        </div>

                                        <div className="myt-body-r">
                                            <div className="myt-body-r-box">
                                                <div className="myt-body-r-box-title">Date</div>
                                                <div className="myt-body-r-box-val">
                                                    {timeToYYYYMMDD(+new Date(item?.created_at))}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <p className="myt-body-r-box-p" style={{ fontSize: '17px' }} dangerouslySetInnerHTML={{ __html: item?.short_content }}>
                                    </p>
                                </div>
                                <div className="myt-body-r-box-grid" style={{ gridTemplateColumns: '1fr' }}>
                                    <div className="myt-body-r-box-r">
                                        <button className="myt-body-r-box-r-btn" onClick={() => {

                                            setModalIsOpen(true)
                                            window.scrollTo(0, 0)
                                            setModalDescription(item?.content)
                                            setModalTitle(item?.title)
                                        }} >
                                            Read More...
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    ))
                ) : (
                    <>
                        <div className="myt-body-border">
                            <div className="myt-body-border-image skeleton-box"></div>
                            <div className="myt-body-detail">
                                <div>
                                    <div className="myt-body-header">
                                        <div
                                            className="myt-body-header-t skeleton-box"
                                            style={{ width: '30%' }}
                                        ></div>
                                        <div className="myt-body-r">
                                            <div
                                                className="myt-body-r-box skeleton-box"
                                                style={{ width: '45px' }}
                                            ></div>
                                            <div
                                                className="myt-body-r-box skeleton-box"
                                                style={{ width: '45px' }}
                                            ></div>
                                        </div>
                                    </div>
                                    <p className="myt-body-r-box-p">
                                        <span
                                            className="skeleton-box"
                                            style={{ width: '80%' }}
                                        ></span>
                                        <span
                                            className="skeleton-box"
                                            style={{ width: '60%' }}
                                        ></span>
                                    </p>
                                </div>
                                <div className="myt-body-r-box-grid">
                                    <div>
                                        <div
                                            className="myt-body-r-box-flex skeleton-box"
                                            style={{
                                                width: '60px',
                                                backgroundColor: '#f6f6f6'
                                            }}
                                        ></div>
                                        <div
                                            className="myt-body-r-box-flex skeleton-box"
                                            style={{
                                                width: '60px',
                                                backgroundColor: '#f6f6f6'
                                            }}
                                        ></div>
                                    </div>
                                    <div>
                                        <div
                                            className="myt-body-r-box-flex skeleton-box"
                                            style={{
                                                width: '60px',
                                                backgroundColor: '#f6f6f6'
                                            }}
                                        ></div>
                                        <div
                                            className="myt-body-r-box-flex skeleton-box"
                                            style={{
                                                width: '60px',
                                                backgroundColor: '#f6f6f6'
                                            }}
                                        ></div>
                                    </div>
                                    <div className="myt-body-r-box-r">
                                        <button
                                            className="myt-body-r-box-r-btn skeleton-box"
                                            style={{ backgroundColor: '#DDDBDD' }}
                                        ></button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </>
                )}
            </div>
            {islandUpdate?.total_page > 0 ? <div className='island-list-pagination'
                style={{ marginTop: '1rem' }}
            >
                <Pagination
                    className="pagination"
                    total={islandUpdate?.total_page}
                    current={islandUpdate?.current_page}
                    maxWidth={300}
                    a11yActiveLabel=""
                    previousLabel=" "
                    nextLabel=" "
                    renderNav={false}
                    ariaPreviousLabel={"Prev"}
                    ariaNextLabel={"Next"}
                    onPageChange={(page: any) => setCurrentPage(page)}
                /></div> : null}

        </>
    )
}

export default Updates
