import { useContext } from "react"
import WizardContext from "../../../context/private/wizardContext"
import { Flex } from "../../../styles/Styles"

const Membership = () => {
    const { setModal } = useContext(WizardContext)
    return (
        <Flex y="center">
            <div className="set-fin">
                <div className="set-border">
                    <img src="/images/IDCard.svg" alt="IDCard" className="set-img" />
                    <div className="set-title">Membership Required</div>
                    <div className="set-info" style={{ marginBottom: "20.29px" }}>
                        <p style={{fontSize: 16 }}> 
                    Congratulations on finishing your Blue Ocean Society account creation steps! You are just one step away from accessing the BOS dashboard. Your access to the dashboard will become available once the annual membership fee has been successfully processed.
                    <br /><br />
                    
                    <b>Important Membership Fee Update</b>
                    <br />
                    
                    If you have verified your email to create your account &#40;Step 2 Verification Stage&#41; before 9 AM EDT November 2nd, 2024, congratulations! Your membership fee is locked at $200 a year. You now have 30 days to complete your membership fee payment to secure this deal.
                    <br /><br />
                    
                    If you verified your account AFTER 9 AM EDT November 2nd, 2024, your membership fee amount is $5,000 a year.
                    <br /><br />
                    
                    <b>To access the BOS Dashboard, you are required to make the necessary transaction.</b>
                    <br /><br />
                    
                    If you have any more questions, don't hesitate to reach out to us at <a href="mailto:hello@blueoceansociety.club">hello@blueoceansociety.club</a>.
                    <br /><br />
                    
                    Kindly select "Payment Details" below to view the payment options, and submit your deposit notification form after initiating your transaction.</p>
                    </div>
                    <button className="set-mem-mid-btn pointer" onClick={() => setModal(true)}>Payment Details</button>
                </div>
            </div>
        </Flex>
    )
}

export default Membership