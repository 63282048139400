import HttpService, { catchReporter, checkMaintenance, reporter } from "./httpService";
import toast from "react-hot-toast";
import { toPascalCase } from "../utils/tools";

class FinancialService {
    private _httpService = HttpService('UserAccount');
    private _httpServiceProfile = HttpService('Profile');

    async getAll() {
        try {
            const response = await this._httpService.get<any>('/');
            checkMaintenance(response)
            if (response.data.status) return response?.data?.message
            else reporter(response)
        } catch (ex: any) {
            catchReporter(ex)
            if (ex.response && ex.response && ex.response.status === 400) {
                throw new Error(ex.response.data.error)
            }
        }
    }

    async getMailchimp() {
        try {
            const response = await this._httpServiceProfile.get<any>('/MailchimpInfo');
            checkMaintenance(response)
            if (response.data.status) return response?.data?.message
            else reporter(response)
        } catch (ex: any) {
            catchReporter(ex)
            if (ex.response && ex.response && ex.response.status === 400) {
                throw new Error(ex.response.data.error)
            }
        }
    }

    async updateMailchimp(formData: any) {
        try {
            const response = await this._httpServiceProfile.post<any>('/UpdateMailchimpInfo', formData);
            checkMaintenance(response)
            if (response.data.status) return response?.data?.message
            else {
                if (typeof response.data.error === 'string') {
                    throw new Error(response?.data?.error?.toString())
                } else if (response.data.errors) {
                    response.data.errors.map((e: any) => toast.error(`${toPascalCase(e.param)}: ${e.msg}`))
                    throw new Error()
                }
            }
        } catch (ex: any) {
            catchReporter(ex)
            throw new Error(ex)
        }
    }

    async getUserInfo() {
        try {
            const response = await this._httpServiceProfile.get<any>('/UsersStats');
            checkMaintenance(response)
            if (response.data.status) return response?.data?.message
            else reporter(response)
        } catch (ex: any) {
            catchReporter(ex)
            if (ex.response && ex.response && ex.response.status === 400) {
                throw new Error(ex.response.data.error)
            }
        }
    }
    async createNewWire(formData: any) {
        try {
            const response = await this._httpService.post<any>('/New/Wire', formData);
            checkMaintenance(response)
            if (response.data.status) return response?.data?.message
            else {
                if (typeof response.data.error === 'string') {
                    throw new Error(response?.data?.error?.toString())
                } else if (response.data.errors) {
                    response.data.errors.map((e: any) => toast.error(`${toPascalCase(e.param)}: ${e.msg}`))
                    throw new Error()
                }
            }
        } catch (ex: any) {
            catchReporter(ex)
            throw new Error(ex)
        }
    }

    async createNewCrypto(formData: any) {
        try {
            const response = await this._httpService.post<any>('/New/Crypto', formData);
            checkMaintenance(response)
            if (response.data.status) return response?.data?.message
            else {
                if (typeof response.data.error === 'string') {
                    reporter(response)
                    throw new Error(response?.data?.error?.toString())
                } else if (response.data.errors) {
                    reporter(response)
                    response.data.errors.map((e: any) => toast.error(`${toPascalCase(e.param)}: ${e.msg}`))
                    throw new Error()
                }
            }
        } catch (ex: any) {
            catchReporter(ex)
            throw new Error(ex)
        }
    }

    async createPTIWallet(formData: any) {
        try {
            const response = await this._httpService.post<any>('/New/Crypto-Pti', formData);
            checkMaintenance(response)
            if (response.data.status) return response?.data?.message
            else {
                if (typeof response.data.error === 'string') {
                    reporter(response)
                    throw new Error(response?.data?.error?.toString())
                } else if (response.data.errors) {
                    reporter(response)
                    response.data.errors.map((e: any) => toast.error(`${toPascalCase(e.param)}: ${e.msg}`))
                    throw new Error()
                }
            }
        } catch (ex: any) {
            catchReporter(ex)
            throw new Error(ex)
        }
    }
    async createMingoWallet(formData: any) {
        try {
            const response = await this._httpService.post<any>('/New/Crypto-Hedera', formData);
            checkMaintenance(response)
            if (response.data.status) return response?.data?.message
            else {
                if (typeof response.data.error === 'string') {
                    reporter(response)
                    throw new Error(response?.data?.error?.toString())
                } else if (response.data.errors) {
                    reporter(response)
                    response.data.errors.map((e: any) => toast.error(`${toPascalCase(e.param)}: ${e.msg}`))
                    throw new Error()
                }
            }
        } catch (ex: any) {
            catchReporter(ex)
            throw new Error(ex)
        }
    }

    async setCurrentAccount(id: string) {
        try {
            const response = await this._httpServiceProfile.put<any>('/Edit/DefaultAccount', { "account_id": id });
            checkMaintenance(response)
            if (response.data.status) return response?.data?.message
            else {
                if (typeof response.data.error === 'string') {
                    reporter(response)
                    throw new Error(response?.data?.error?.toString())
                } else if (response.data.errors) {
                    reporter(response)
                    response.data.errors.map((e: any) => toast.error(`${toPascalCase(e.param)}: ${e.msg}`))
                    throw new Error()
                }
            }
        } catch (ex: any) {
            catchReporter(ex)
            throw new Error(ex)
        }
    }
    async edit(id: string, formData: any) {
        try {
            const response = await this._httpService.put<any>('/Edit/' + id, formData);
            checkMaintenance(response)
            if (response.data.status) return response?.data?.message
            else {
                if (typeof response.data.error === 'string') {
                    reporter(response)
                    throw new Error(response?.data?.error?.toString())
                } else if (response.data.errors) {
                    reporter(response)
                    response.data.errors.map((e: any) => toast.error(`${toPascalCase(e.param)}: ${e.msg}`))
                    throw new Error()
                }
            }
        } catch (ex: any) {
            catchReporter(ex)
            throw new Error(ex)
        }
    }

    async delete(id: string, token: string) {
        try {
            const response = await this._httpService.put<any>('/Delete/' + id, { token: token });
            checkMaintenance(response)
            if (response.data.status) return response?.data?.message
            else {
                if (typeof response.data.error === 'string') {
                    reporter(response)
                    throw new Error(response?.data?.error?.toString())
                } else if (response.data.errors) {
                    reporter(response)
                    response.data.errors.map((e: any) => toast.error(`${toPascalCase(e.param)}: ${e.msg}`))
                    throw new Error()
                }
            }
        } catch (ex: any) {
            catchReporter(ex)
            throw new Error(ex)
        }
    }

}

export default new FinancialService()