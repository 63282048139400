import { useContext, useEffect, useState } from "react"
import NotFoundImage from "../../../assets/images/not-found.png"
import IslandsContext from "../../../context/private/islandsContext"
import { Flex } from "../../../styles/Styles"
import PrivateContext from "../../../context/private/privateContext"
import { IslandsItemContext } from "../Index"
import Loader from "../../Loader"
import AccountContext from "../../../context/private/accountContext"

const Conditions = () => {
  const { flowState } = useContext(PrivateContext)
  const { loadCondition } = useContext(IslandsContext)
  const { currentId } = useContext(IslandsItemContext)
  const { userInfo } = useContext(AccountContext)

  const [islandCondition, setIslandCondition] = useState<any>(null)

  useEffect(() => {
    if (flowState.step === 17 && userInfo
      && userInfo?.membershipStatus !== 2) {
      loadCondition(currentId, setIslandCondition)
    }
  }, [flowState, userInfo])

  const [cleanedContent, setCleanedContent] = useState('');

  useEffect(() => {
    if (islandCondition?.content) {
      const parser = new DOMParser();
      const doc = parser.parseFromString(islandCondition?.content, 'text/html');
      const scripts = doc.getElementsByTagName('script');

      Array.from(scripts).forEach(script => {
        const newScript = document.createElement('script');
        Array.from(script.attributes).forEach(attr => newScript.setAttribute(attr.name, attr.value));
        newScript.appendChild(document.createTextNode(script.innerHTML));
        document.body.appendChild(newScript);
        script.remove();
      });

      setCleanedContent(doc.body.innerHTML);
    }
  }, [islandCondition]);

  if (islandCondition === null) return <Loader />
  return (
    <>
      {islandCondition ? <div className="conditions-body">
        <div className="conditions-content">
          <h4>{islandCondition?.title}</h4>
          <div className="content" dangerouslySetInnerHTML={{ __html: cleanedContent }}></div>
        </div>
      </div> : <Flex y="center" x="center">
        <img src={NotFoundImage} />
      </Flex>}
    </>
  )
}

export default Conditions