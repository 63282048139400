import { useContext, useEffect, useState } from 'react';
import { useWindowWidth } from '../breakpoint';
import './style.css';
import TreasuryContext from '../../../context/private/treasuryContext';
import { numberWithCommas, truncateToTwoDecimals } from '../../../utils/tools';
import AccountContext from '../../../context/private/accountContext';
import PrivateContext from '../../../context/private/privateContext';
import dictionary from '../../../locals/my-treasures.json';
import dictionaryModal from '../../../locals/modal.json';
import { ModalMethods, SuccessModal } from '../Success';
import { Link } from 'react-router-dom';
import { Flex } from '../../../styles/Styles';
import toast from 'react-hot-toast';
import vector6 from '../assets/vector-6@2x.png';
import dottedLine from '../assets/line-9@2x.png';
import line7 from '../assets/line-7.png';
import closeIcon from '../assets/union-3@2x.png';
import { AiOutlineInfoCircle } from 'react-icons/ai';

export const IslandClaimWindowModal = ({
  forceWithdrawAmount: claimAmount,
  setForceWithdrawAmount: setClaimAmount
}) => {
  const screenWidth = useWindowWidth();

  const { settings, setUpdateTimer } = useContext(PrivateContext);

  const [isTermsCheck, setTermsCheck] = useState('');
  const [withdraw2FAToken, setWithdraw2FAToken] = useState('');
  const {
    islandClaimWindow,
    loadProfitChart,
    loadIslandClaimWithdraw,
    modalForceWithdrawAreYouSure,
    setModalForceWithdrawAreYouSure,
    modalTopUpAreYouSure,
    setModalTopUpAreYouSure,
    profitChartDailyData,
    profitChartMonthlyData,
    approximatlyAvailable,
    loadTransactionChart,
    transactionChartDailyData,
    transactionChartMonthlyData,
    islandsDetailData,
    crewData,
    modalTopUp,
    setModalTopUp,
    modalClaim,
    setModalClaim,
    modalForceWithdraw,
    topUpHandle,
    islandData,
    claimHandle,
    claimHandleForType2,
    forceWithdrawHandle,
    currentIslandId,
    ckeditorClaimWindowModal,
    loadCkEditorByID,
    forceWithdrawSuccessData,
    setForceWithdrawSuccessData,
    claimSuccessData,
    setClaimSuccessData
  } = useContext(TreasuryContext);
  const { userInfo } = useContext(AccountContext);

  useEffect(() => {
    setUpdateTimer(+new Date());
    setClaimSuccessData(null);
    modalClaim === false &&
      currentIslandId &&
      loadIslandClaimWithdraw(currentIslandId);
  }, [modalTopUp, modalClaim, modalForceWithdraw]);

  useEffect(() => {
    loadCkEditorByID(9);
  }, []);

  useEffect(() => {
    if (modalClaim && islandClaimWindow?.island_type === 2) {
      if (
        Number(islandsDetailData?.balance) +
          Number(islandsDetailData?.available_balance) >
        1
      ) {
        setClaimAmount(
          truncateToTwoDecimals(
            Number(islandsDetailData?.balance) +
              Number(islandsDetailData?.available_balance)
          )
        );
      } else {
        setClaimAmount(
          truncateToTwoDecimals(
            Number(islandsDetailData?.balance) +
              Number(islandsDetailData?.available_balance)
          )
        );
      }
    }
  }, [modalClaim]);

  return (
    <>
      {!claimSuccessData && modalClaim && (
        <div className="blur-bg" onClick={() => setModalClaim(false)}></div>
      )}

      {!claimSuccessData && modalClaim && (
        <div className="island-withdrawal-modal">
          <div
            className="modal-mobile"
            style={{
              height:
                screenWidth < 1037
                  ? '1355px'
                  : screenWidth >= 1037
                  ? '772px'
                  : undefined,
              overflow: screenWidth < 1037 ? 'hidden' : undefined,
              width:
                screenWidth < 1037
                  ? '564px'
                  : screenWidth >= 1037
                  ? '1037px'
                  : undefined
            }}
          >
            <div
              className="content"
              style={{
                alignItems:
                  screenWidth < 1037
                    ? 'flex-start'
                    : screenWidth >= 1037
                    ? 'center'
                    : undefined,
                flexDirection: screenWidth < 1037 ? 'column' : undefined,
                gap: screenWidth < 1037 ? '20px' : undefined,
                justifyContent:
                  screenWidth >= 1037 ? 'space-between' : undefined,
                width:
                  screenWidth < 1037
                    ? '524px'
                    : screenWidth >= 1037
                    ? '989px'
                    : undefined
              }}
            >
              {screenWidth < 1037 && (
                <>
                  <div className="left">
                    <div className="details">
                      {islandClaimWindow &&
                      islandClaimWindow?.claim_window_view === 1 ? (
                        <div className="payment-detail">
                          <div className="item-with-tooltip">
                            <div className="text-wrapper-2">
                              {dictionaryModal['MODAL-title53']}:
                            </div>
                            <div
                              className="tooltip-info tooltip-info-normal tooltip-info-normal-inside"
                              style={{ right: 0, top: 4 }}
                            >
                              <AiOutlineInfoCircle className="tooltip-info-icon" />
                              <div
                                className="tooltip-info-text"
                                style={{ left: 0 }}
                              >
                                <span>{dictionaryModal['MODAL-title59']}</span>
                              </div>
                            </div>
                          </div>

                          <div className="text-wrapper-3">
                            {islandClaimWindow?.totalCapacity ||
                            islandClaimWindow?.totalCapacity === 0
                              ? islandClaimWindow?.totalCapacity === -1
                                ? 'Unlimited'
                                : `$${numberWithCommas(
                                    Number(islandClaimWindow?.totalCapacity)
                                  )}`
                              : 'Loading...'}
                          </div>
                        </div>
                      ) : null}
                      {islandClaimWindow &&
                      islandClaimWindow?.claim_window_view === 1 &&
                      islandClaimWindow?.totalCapacity !== -1 ? (
                        <div className="payment-detail">
                          <div className="item-with-tooltip">
                            <div className="text-wrapper-2">
                              {dictionaryModal['MODAL-title54']}:
                            </div>
                            <div
                              className="tooltip-info tooltip-info-normal tooltip-info-normal-inside"
                              style={{ right: 0, top: 4 }}
                            >
                              <AiOutlineInfoCircle className="tooltip-info-icon" />
                              <div
                                className="tooltip-info-text"
                                style={{ left: 0 }}
                              >
                                <span>{dictionaryModal['MODAL-title60']}</span>
                              </div>
                            </div>
                          </div>

                          <div className="text-wrapper-3">
                            {islandClaimWindow?.usedCapacity ||
                            islandClaimWindow?.usedCapacity === 0
                              ? islandClaimWindow?.usedCapacity === -1
                                ? 'Unlimited'
                                : `$${numberWithCommas(
                                    Number(islandClaimWindow?.usedCapacity)
                                  )}`
                              : 'Loading...'}
                          </div>
                        </div>
                      ) : null}
                      {islandClaimWindow &&
                      islandClaimWindow?.claim_window_view === 1 &&
                      islandClaimWindow?.totalCapacity !== -1 ? (
                        <div className="payment-detail">
                          <div className="item-with-tooltip">
                            <div className="text-wrapper-2">
                              {dictionaryModal['MODAL-title55']}:
                            </div>
                            <div
                              className="tooltip-info tooltip-info-normal tooltip-info-normal-inside"
                              style={{ right: 0, top: 4 }}
                            >
                              <AiOutlineInfoCircle className="tooltip-info-icon" />
                              <div
                                className="tooltip-info-text"
                                style={{ left: 0 }}
                              >
                                <span>{dictionaryModal['MODAL-title61']}</span>
                              </div>
                            </div>
                          </div>
                          <div className="text-wrapper-3">
                            {islandClaimWindow ? (
                              islandClaimWindow?.availableCapacity ? (
                                `$${numberWithCommas(
                                  islandClaimWindow?.availableCapacity,
                                  2
                                )}`
                              ) : (
                                '$0'
                              )
                            ) : (
                              <div className="skeleton-element-item skeleton-box"></div>
                            )}
                          </div>
                        </div>
                      ) : null}

                      {islandClaimWindow &&
                      islandClaimWindow?.claim_window_personal_view === 1 ? (
                        <div className="payment-detail">
                          <div className="item-with-tooltip">
                            <div className="text-wrapper-2">
                              {dictionaryModal['MODAL-title56']}:
                            </div>
                            <div
                              className="tooltip-info tooltip-info-normal tooltip-info-normal-inside"
                              style={{ right: 0, top: 4 }}
                            >
                              <AiOutlineInfoCircle className="tooltip-info-icon" />
                              <div
                                className="tooltip-info-text"
                                style={{ left: 0 }}
                              >
                                <span>{dictionaryModal['MODAL-title62']}</span>
                              </div>
                            </div>
                          </div>

                          <div className="text-wrapper-3">
                            {islandsDetailData ? (
                              islandsDetailData?.available_balance ||
                              islandsDetailData?.available_balance === 0 ? (
                                `$${numberWithCommas(
                                  islandsDetailData?.available_balance,
                                  2
                                )}`
                              ) : (
                                '$0'
                              )
                            ) : (
                              <div className="skeleton-element-item skeleton-box"></div>
                            )}
                          </div>
                        </div>
                      ) : null}
                      {islandClaimWindow &&
                      islandClaimWindow?.claim_window_personal_view === 1 ? (
                        <div className="payment-detail">
                          <div className="item-with-tooltip">
                            <div className="text-wrapper-2">
                              {dictionaryModal['MODAL-title57']}:
                            </div>
                            <div
                              className="tooltip-info tooltip-info-normal tooltip-info-normal-inside"
                              style={{ right: 0, top: 4 }}
                            >
                              <AiOutlineInfoCircle className="tooltip-info-icon" />
                              <div
                                className="tooltip-info-text"
                                style={{ left: 0 }}
                              >
                                <span>{dictionaryModal['MODAL-title63']}</span>
                              </div>
                            </div>
                          </div>

                          <div className="text-wrapper-3">
                            {islandClaimWindow ? (
                              islandClaimWindow?.userAvailableCapacity ||
                              islandClaimWindow?.totalCapacity === -1 ? (
                                `$${numberWithCommas(
                                  islandClaimWindow?.totalCapacity === -1
                                    ? islandsDetailData?.available_balance
                                    : islandClaimWindow?.userAvailableCapacity,
                                  2
                                )}`
                              ) : (
                                '$0'
                              )
                            ) : (
                              <div className="skeleton-element-item skeleton-box"></div>
                            )}
                          </div>
                        </div>
                      ) : null}
                      {islandClaimWindow &&
                      islandClaimWindow?.claim_window_personal_view === 1 ? (
                        <div className="payment-detail">
                          <div className="item-with-tooltip">
                            <div className="text-wrapper-2">
                              {dictionaryModal['MODAL-title58']}:
                            </div>
                            <div
                              className="tooltip-info tooltip-info-normal tooltip-info-normal-inside"
                              style={{ right: 0, top: 4 }}
                            >
                              <AiOutlineInfoCircle className="tooltip-info-icon" />
                              <div
                                className="tooltip-info-text"
                                style={{ left: 0 }}
                              >
                                <span>{dictionaryModal['MODAL-title64']}</span>
                              </div>
                            </div>
                          </div>

                          <div className="text-wrapper-3">
                            {islandClaimWindow ? (
                              islandClaimWindow?.userUsedCapacity ? (
                                `$${numberWithCommas(
                                  islandClaimWindow?.userUsedCapacity,
                                  2
                                )}`
                              ) : (
                                '$0'
                              )
                            ) : (
                              <div className="skeleton-element-item skeleton-box"></div>
                            )}
                          </div>
                        </div>
                      ) : null}
                    </div>
                    <div className="enterance">
                      <input
                        type="number"
                        placeholder="Enter an amount"
                        className="input mod-inside-body-input"
                        value={claimAmount}
                        disabled={islandClaimWindow?.island_type === 2}
                        onChange={(e) =>
                          e.target.value !== 'e' &&
                          setClaimAmount(
                            e.target.value.length > 0
                              ? Number(e.target.value)
                              : ''
                          )
                        }
                      />
                      {islandClaimWindow?.island_type === 2 ? null : (
                        <div className="options">
                          <p
                            className="min pointer"
                            onClick={(e) =>
                              setClaimAmount(
                                Number(islandsDetailData?.minimum_withdraw)
                              )
                            }
                          >
                            <span className="span">Min:</span>
                            <span className="text-wrapper-4">&nbsp;</span>
                            <span className="text-wrapper-5">
                              {islandsDetailData?.minimum_withdraw ||
                              islandsDetailData?.minimum_withdraw === 0
                                ? `$${numberWithCommas(
                                    islandsDetailData?.minimum_withdraw
                                  )}`
                                : ''}
                            </span>
                          </p>
                          <p
                            className="max pointer"
                            onClick={(e) => {
                              if (islandClaimWindow?.totalCapacity === -1) {
                                Number(islandsDetailData?.available_balance) > 1
                                  ? setClaimAmount(
                                      truncateToTwoDecimals(
                                        Number(
                                          islandsDetailData?.available_balance
                                        )
                                      )
                                    )
                                  : setClaimAmount(
                                      truncateToTwoDecimals(
                                        islandsDetailData?.available_balance
                                      )
                                    );
                              } else {
                                Number(
                                  islandClaimWindow?.userAvailableCapacity
                                ) > 1
                                  ? setClaimAmount(
                                      truncateToTwoDecimals(
                                        Number(
                                          islandClaimWindow?.userAvailableCapacity
                                        )
                                      )
                                    )
                                  : setClaimAmount(
                                      truncateToTwoDecimals(
                                        islandClaimWindow?.userAvailableCapacity
                                      )
                                    );
                              }
                            }}
                          >
                            <span className="span">Max:</span>
                            <span className="text-wrapper-4">&nbsp;</span>
                            <span className="text-wrapper-5">
                              {islandClaimWindow?.island_type === 2 ? (
                                islandsDetailData?.available_balance ||
                                islandsDetailData?.balance ? (
                                  `$${numberWithCommas(
                                    Number(islandsDetailData?.balance) +
                                      Number(
                                        islandsDetailData?.available_balance
                                      ),
                                    2
                                  )}`
                                ) : (
                                  <div className="skeleton-element-item skeleton-box"></div>
                                )
                              ) : islandClaimWindow?.userAvailableCapacity ||
                                islandClaimWindow?.userAvailableCapacity ===
                                  0 ||
                                islandClaimWindow?.totalCapacity === -1 ? (
                                `$${numberWithCommas(
                                  islandClaimWindow?.totalCapacity === -1
                                    ? islandsDetailData?.available_balance
                                    : islandClaimWindow?.userAvailableCapacity,
                                  2
                                )}`
                              ) : (
                                <div className="skeleton-element-item skeleton-box"></div>
                              )}
                            </span>
                          </p>
                        </div>
                      )}
                    </div>
                    <div className="description">
                      <div className="overlap-group">
                        <p className="please-ensure-to">
                          <span
                            className="text-wrapper-6"
                            dangerouslySetInnerHTML={{
                              __html: islandsDetailData?.claim_description_view
                                ? islandsDetailData?.claim_description
                                : ckeditorClaimWindowModal
                            }}
                          ></span>
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="right">
                    <div className="header">
                      <div className="success-icon">
                        <div className="overlap">
                          <div className="vuesax-bold-tick">
                            <div className="tick-circle-wrapper">
                              <div className="tick-circle">
                                <div className="overlap-group-2">
                                  <div className="rectangle" />
                                  <img
                                    className="vector"
                                    alt="Vector"
                                    src="https://anima-uploads.s3.amazonaws.com/projects/64b77eb848521524cc106dda/releases/64d7361b8264fdbeecae76b1/img/vector-4@2x.png"
                                  />
                                </div>
                                <img
                                  className="img"
                                  alt="Vector"
                                  src="https://anima-uploads.s3.amazonaws.com/projects/64b77eb848521524cc106dda/releases/64d7361b8264fdbeecae76b1/img/vector-5@2x.png"
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="header-text">
                        <div className="text-wrapper-10">
                          Your Requested Amount
                        </div>
                        <div className="text-wrapper-11">
                          ${numberWithCommas(claimAmount)}
                        </div>
                      </div>
                    </div>
                    <div className="frame">
                      <div className="group">
                        <div className="overlap-group-3">
                          <div className="rectangle-2" />
                          <div className="payment-details">
                            <div className="div-2">
                              <div className="payment-detail">
                                <div className="text-wrapper-2">
                                  Payment Method
                                </div>
                                <div className="text-wrapper-3">Claim</div>
                              </div>
                              <div className="payment-detail">
                                <div className="item-with-tooltip">
                                  <div className="text-wrapper-2">
                                    {dictionaryModal['MODAL-title66']}:
                                  </div>
                                  <div
                                    className="tooltip-info tooltip-info-normal tooltip-info-normal-inside"
                                    style={{ right: 0, top: 4 }}
                                  >
                                    <AiOutlineInfoCircle className="tooltip-info-icon" />
                                    <div
                                      className="tooltip-info-text"
                                      style={{ left: 0 }}
                                    >
                                      <span>
                                        {dictionaryModal['MODAL-title65']}
                                      </span>
                                    </div>
                                  </div>
                                </div>

                                <div className="text-wrapper-3">
                                  {islandsDetailData?.withdraw_fee}%
                                </div>
                              </div>
                              <div className="payment-detail">
                                <div className="text-wrapper-2">
                                  Requested Amount
                                </div>
                                <div className="text-wrapper-3">
                                  ${numberWithCommas(claimAmount)}
                                </div>
                              </div>
                            </div>
                            <img
                              className="line"
                              alt="Line"
                              src="https://anima-uploads.s3.amazonaws.com/projects/64b77eb848521524cc106dda/releases/64d7361b8264fdbeecae76b1/img/line-6@2x.png"
                            />
                            <div className="div-2">
                              <div className="payment-detail">
                                <div className="item-with-tooltip">
                                  <div className="text-wrapper-2">
                                    {dictionaryModal['MODAL-title66']}:
                                  </div>
                                  <div
                                    className="tooltip-info tooltip-info-normal tooltip-info-normal-inside"
                                    style={{ right: 0, top: 4 }}
                                  >
                                    <AiOutlineInfoCircle className="tooltip-info-icon" />
                                    <div
                                      className="tooltip-info-text"
                                      style={{ left: 0 }}
                                    >
                                      <span>
                                        {dictionaryModal['MODAL-title65']}
                                      </span>
                                    </div>
                                  </div>
                                </div>
                                <div className="text-wrapper-3">
                                  $
                                  {numberWithCommas(
                                    claimAmount *
                                      (islandsDetailData?.withdraw_fee / 100)
                                  )}
                                </div>
                              </div>
                              <div className="payment-detail">
                                <div className="text-wrapper-2">
                                  Received Amount
                                </div>
                                <div className="text-wrapper-3">
                                  $
                                  {numberWithCommas(
                                    Number(claimAmount) -
                                      claimAmount *
                                        (islandsDetailData?.withdraw_fee / 100)
                                  )}
                                </div>
                              </div>
                              <div className="payment-detail">
                                <p className="text-wrapper-2">
                                  Your BOS Balance will be
                                </p>
                                <div className="text-wrapper-3">
                                  $
                                  {numberWithCommas(
                                    Number(userInfo?.total_balance) +
                                      (Number(claimAmount) -
                                        claimAmount *
                                          (islandsDetailData?.withdraw_fee /
                                            100))
                                  )}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="frame-2">
                        <input
                          className="checkbox-size"
                          type="checkbox"
                          id="horns"
                          name="horns"
                          onChange={(e) => setTermsCheck(e.target.checked)}
                        />
                        <label htmlFor="horns">
                          <p className="p">
                            I have reviewed and agree to the{' '}
                            <Link
                              to={`/island-page?id=${currentIslandId}&tab=conditions`}
                              target="_blank"
                            >
                              Island Conditions.
                            </Link>
                          </p>
                        </label>
                      </div>
                      <div className="item">
                        <Flex direction="col" h="unset">
                          <div
                            className="div-wrapper pointer"
                            style={
                              isTermsCheck
                                ? {}
                                : { opacity: 0.3, cursor: 'not-allowed' }
                            }
                            onClick={() => {
                              if (isTermsCheck) {
                                if (islandsDetailData) {
                                  if (claimAmount > 0) {
                                    if (
                                      claimAmount >=
                                      Number(
                                        islandsDetailData?.minimum_withdraw
                                      )
                                    ) {
                                      islandClaimWindow?.island_type === 3
                                        ? claimHandle(
                                            claimAmount,
                                            currentIslandId
                                          )
                                        : claimHandleForType2(
                                            claimAmount,
                                            currentIslandId
                                          );
                                    } else {
                                      toast.error(
                                        `${dictionaryModal['MODAL-title67']} $${islandsDetailData?.minimum_withdraw}`
                                      );
                                    }
                                  } else {
                                    toast.error(dictionary['TREASURE-title32']);
                                  }
                                }
                              }
                            }}
                          >
                            <div className="text-wrapper-12">Submit</div>
                          </div>
                        </Flex>
                      </div>
                    </div>
                    <img
                      className="line-2"
                      alt="Line"
                      src="https://anima-uploads.s3.amazonaws.com/projects/64b77eb848521524cc106dda/releases/64d7361b8264fdbeecae76b1/img/line-5@2x.png"
                    />
                  </div>
                </>
              )}

              {screenWidth >= 1037 && (
                <>
                  <div className="left-2">
                    <div className="details">
                      {/* claim_window_personal_vie */}

                      {islandClaimWindow &&
                      islandClaimWindow?.claim_window_view === 1 ? (
                        <div className="payment-detail">
                          <div className="item-with-tooltip">
                            <div className="text-wrapper-2">
                              {dictionaryModal['MODAL-title53']}:
                            </div>
                            <div
                              className="tooltip-info tooltip-info-normal tooltip-info-normal-inside"
                              style={{ right: 0, top: 4 }}
                            >
                              <AiOutlineInfoCircle className="tooltip-info-icon" />
                              <div
                                className="tooltip-info-text"
                                style={{ left: 0 }}
                              >
                                <span>{dictionaryModal['MODAL-title59']}</span>
                              </div>
                            </div>
                          </div>

                          <div className="text-wrapper-3">
                            {islandClaimWindow?.totalCapacity ||
                            islandClaimWindow?.totalCapacity === 0
                              ? islandClaimWindow?.totalCapacity === -1
                                ? 'Unlimited'
                                : `$${numberWithCommas(
                                    Number(islandClaimWindow?.totalCapacity)
                                  )}`
                              : 'Loading...'}
                          </div>
                        </div>
                      ) : null}

                      {islandClaimWindow &&
                      islandClaimWindow?.claim_window_view === 1 &&
                      islandClaimWindow?.totalCapacity !== -1 ? (
                        <div className="payment-detail">
                          <div className="item-with-tooltip">
                            <div className="text-wrapper-2">
                              {dictionaryModal['MODAL-title54']}:
                            </div>
                            <div
                              className="tooltip-info tooltip-info-normal tooltip-info-normal-inside"
                              style={{ right: 0, top: 4 }}
                            >
                              <AiOutlineInfoCircle className="tooltip-info-icon" />
                              <div
                                className="tooltip-info-text"
                                style={{ left: 0 }}
                              >
                                <span>{dictionaryModal['MODAL-title60']}</span>
                              </div>
                            </div>
                          </div>

                          <div className="text-wrapper-3">
                            {islandClaimWindow?.usedCapacity ||
                            islandClaimWindow?.usedCapacity === 0
                              ? islandClaimWindow?.usedCapacity === -1
                                ? 'Unlimited'
                                : `$${numberWithCommas(
                                    Number(islandClaimWindow?.usedCapacity)
                                  )}`
                              : 'Loading...'}
                          </div>
                        </div>
                      ) : null}

                      {islandClaimWindow &&
                      islandClaimWindow?.claim_window_view === 1 &&
                      islandClaimWindow?.totalCapacity !== -1 ? (
                        <div className="payment-detail">
                          <div className="item-with-tooltip">
                            <div className="text-wrapper-2">
                              {dictionaryModal['MODAL-title55']}:
                            </div>
                            <div
                              className="tooltip-info tooltip-info-normal tooltip-info-normal-inside"
                              style={{ right: 0, top: 4 }}
                            >
                              <AiOutlineInfoCircle className="tooltip-info-icon" />
                              <div
                                className="tooltip-info-text"
                                style={{ left: 0 }}
                              >
                                <span>{dictionaryModal['MODAL-title61']}</span>
                              </div>
                            </div>
                          </div>

                          <div className="text-wrapper-3">
                            {islandClaimWindow ? (
                              islandClaimWindow?.availableCapacity ? (
                                `$${numberWithCommas(
                                  islandClaimWindow?.availableCapacity,
                                  2
                                )}`
                              ) : (
                                '$0'
                              )
                            ) : (
                              <div className="skeleton-element-item skeleton-box"></div>
                            )}
                          </div>
                        </div>
                      ) : null}

                      {islandClaimWindow &&
                      islandClaimWindow?.claim_window_personal_view === 1 ? (
                        <div className="payment-detail">
                          <div className="item-with-tooltip">
                            <div className="text-wrapper-2">
                              {dictionaryModal['MODAL-title56']}:
                            </div>
                            <div
                              className="tooltip-info tooltip-info-normal tooltip-info-normal-inside"
                              style={{ right: 0, top: 4 }}
                            >
                              <AiOutlineInfoCircle className="tooltip-info-icon" />
                              <div
                                className="tooltip-info-text"
                                style={{ left: 0 }}
                              >
                                <span>{dictionaryModal['MODAL-title62']}</span>
                              </div>
                            </div>
                          </div>

                          <div className="text-wrapper-3">
                            {islandsDetailData ? (
                              islandsDetailData?.available_balance ||
                              islandsDetailData?.available_balance === 0 ? (
                                `$${numberWithCommas(
                                  islandsDetailData?.available_balance,
                                  2
                                )}`
                              ) : (
                                '$0'
                              )
                            ) : (
                              <div className="skeleton-element-item skeleton-box"></div>
                            )}
                          </div>
                        </div>
                      ) : null}
                      {islandClaimWindow &&
                      islandClaimWindow?.claim_window_personal_view === 1 ? (
                        <div className="payment-detail">
                          <div className="item-with-tooltip">
                            <div className="text-wrapper-2">
                              {dictionaryModal['MODAL-title57']}:
                            </div>
                            <div
                              className="tooltip-info tooltip-info-normal tooltip-info-normal-inside"
                              style={{ right: 0, top: 4 }}
                            >
                              <AiOutlineInfoCircle className="tooltip-info-icon" />
                              <div
                                className="tooltip-info-text"
                                style={{ left: 0 }}
                              >
                                <span>{dictionaryModal['MODAL-title63']}</span>
                              </div>
                            </div>
                          </div>

                          <div className="text-wrapper-3">
                            {islandClaimWindow ? (
                              islandClaimWindow?.userAvailableCapacity ||
                              islandClaimWindow?.totalCapacity === -1 ? (
                                `$${numberWithCommas(
                                  islandClaimWindow?.totalCapacity === -1
                                    ? islandsDetailData?.available_balance
                                    : islandClaimWindow?.userAvailableCapacity,
                                  2
                                )}`
                              ) : (
                                '$0'
                              )
                            ) : (
                              <div className="skeleton-element-item skeleton-box"></div>
                            )}
                          </div>
                        </div>
                      ) : null}
                      {islandClaimWindow &&
                      islandClaimWindow?.claim_window_personal_view === 1 ? (
                        <div className="payment-detail">
                          <div className="item-with-tooltip">
                            <div className="text-wrapper-2">
                              {dictionaryModal['MODAL-title58']}:
                            </div>
                            <div
                              className="tooltip-info tooltip-info-normal tooltip-info-normal-inside"
                              style={{ right: 0, top: 4 }}
                            >
                              <AiOutlineInfoCircle className="tooltip-info-icon" />
                              <div
                                className="tooltip-info-text"
                                style={{ left: 0 }}
                              >
                                <span>{dictionaryModal['MODAL-title64']}</span>
                              </div>
                            </div>
                          </div>
                          <div className="text-wrapper-3">
                            {islandClaimWindow ? (
                              islandClaimWindow?.userUsedCapacity ? (
                                `$${numberWithCommas(
                                  islandClaimWindow?.userUsedCapacity,
                                  2
                                )}`
                              ) : (
                                '$0'
                              )
                            ) : (
                              <div className="skeleton-element-item skeleton-box"></div>
                            )}
                          </div>
                        </div>
                      ) : null}
                    </div>
                    <div className="enterance">
                      <input
                        type="number"
                        placeholder="Enter an amount"
                        className="input mod-inside-body-input"
                        value={claimAmount}
                        disabled={islandClaimWindow?.island_type === 2}
                        onChange={(e) =>
                          e.target.value !== 'e' &&
                          setClaimAmount(
                            e.target.value.length > 0
                              ? Number(e.target.value)
                              : ''
                          )
                        }
                      />
                      {islandClaimWindow?.island_type === 2 ? null : (
                        <div className="options">
                          <p
                            className="min pointer"
                            onClick={(e) =>
                              setClaimAmount(
                                Number(islandsDetailData?.minimum_withdraw)
                              )
                            }
                          >
                            <span className="span">Min:</span>
                            <span className="text-wrapper-4">&nbsp;</span>
                            <span className="text-wrapper-5">
                              {islandsDetailData?.minimum_withdraw ||
                              islandsDetailData?.minimum_withdraw === 0
                                ? `$${numberWithCommas(
                                    islandsDetailData?.minimum_withdraw
                                  )}`
                                : ''}
                            </span>
                          </p>
                          <p
                            className="max pointer"
                            onClick={(e) => {
                              if (islandClaimWindow?.totalCapacity === -1) {
                                Number(islandsDetailData?.available_balance) > 1
                                  ? setClaimAmount(
                                      truncateToTwoDecimals(
                                        Number(
                                          islandsDetailData?.available_balance
                                        )
                                      )
                                    )
                                  : setClaimAmount(
                                      truncateToTwoDecimals(
                                        islandsDetailData?.available_balance
                                      )
                                    );
                              } else {
                                Number(
                                  islandClaimWindow?.userAvailableCapacity
                                ) > 1
                                  ? setClaimAmount(
                                      truncateToTwoDecimals(
                                        Number(
                                          islandClaimWindow?.userAvailableCapacity
                                        )
                                      )
                                    )
                                  : setClaimAmount(
                                      truncateToTwoDecimals(
                                        islandClaimWindow?.userAvailableCapacity
                                      )
                                    );
                              }
                            }}
                          >
                            <span className="span">Max:</span>
                            <span className="text-wrapper-4">&nbsp;</span>
                            <span className="text-wrapper-5">
                              {islandClaimWindow?.island_type === 2 ? (
                                islandsDetailData?.available_balance ||
                                islandsDetailData?.balance ? (
                                  `$${numberWithCommas(
                                    Number(islandsDetailData?.balance) +
                                      Number(
                                        islandsDetailData?.available_balance
                                      ),
                                    2
                                  )}`
                                ) : (
                                  <div className="skeleton-element-item skeleton-box"></div>
                                )
                              ) : islandClaimWindow?.userAvailableCapacity ||
                                islandClaimWindow?.userAvailableCapacity ===
                                  0 ||
                                islandClaimWindow?.totalCapacity === -1 ? (
                                `$${numberWithCommas(
                                  islandClaimWindow?.totalCapacity === -1
                                    ? islandsDetailData?.available_balance
                                    : islandClaimWindow?.userAvailableCapacity,
                                  2
                                )}`
                              ) : (
                                <div className="skeleton-element-item skeleton-box"></div>
                              )}
                            </span>
                          </p>
                        </div>
                      )}
                    </div>
                    <div className="description">
                      <div
                        className="overlap-group-4"
                        style={
                          claimAmount &&
                          Number(islandsDetailData?.activeAmount) -
                            Number(claimAmount) <
                            Number(islandsDetailData?.minimum_invest)
                            ? {
                                height: '285px'
                              }
                            : {}
                        }
                      >
                        <p className="please-ensure-to">
                          <span
                            className="text-wrapper-6"
                            dangerouslySetInnerHTML={{
                              __html: islandsDetailData?.claim_description_view
                                ? islandsDetailData?.claim_description
                                : ckeditorClaimWindowModal
                            }}
                          ></span>
                        </p>
                      </div>
                    </div>
                  </div>
                  <img className="line-3" alt="Line" src={line7} />
                  <div className="right-2">
                    <div className="header">
                      <div className="success-icon">
                        <div className="overlap">
                          <div className="vuesax-bold-tick">
                            <div className="tick-circle-wrapper">
                              <div className="tick-circle">
                                <div className="overlap-group-2">
                                  <div className="rectangle" />
                                  <img
                                    className="vector"
                                    alt="Vector"
                                    src={vector6}
                                  />
                                </div>
                                <img
                                  className="vector-2"
                                  alt="Vector"
                                  src="https://anima-uploads.s3.amazonaws.com/projects/64b77eb848521524cc106dda/releases/64d7361b8264fdbeecae76b1/img/vector-5@2x.png"
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="header-text">
                        <div className="text-wrapper-10">
                          Your Requested Amount
                        </div>
                        <div className="text-wrapper-11">
                          ${numberWithCommas(claimAmount)}
                        </div>
                      </div>
                    </div>
                    <div className="frame-3">
                      <div className="overlap-group-wrapper">
                        <div className="overlap-group-3">
                          <div className="rectangle-3" />
                          <div className="payment-details-2">
                            <div className="div-2">
                              <div className="payment-detail">
                                <div className="text-wrapper-2">
                                  Payment Method
                                </div>
                                <div className="text-wrapper-3">Claim</div>
                              </div>
                              <div className="payment-detail">
                                <div className="item-with-tooltip">
                                  <div className="text-wrapper-2">
                                    {dictionaryModal['MODAL-title66']}:
                                  </div>
                                  <div
                                    className="tooltip-info tooltip-info-normal tooltip-info-normal-inside"
                                    style={{ right: 0, top: 4 }}
                                  >
                                    <AiOutlineInfoCircle className="tooltip-info-icon" />
                                    <div
                                      className="tooltip-info-text"
                                      style={{ left: 0 }}
                                    >
                                      <span>
                                        {dictionaryModal['MODAL-title65']}
                                      </span>
                                    </div>
                                  </div>
                                </div>
                                <div className="text-wrapper-3">
                                  {islandsDetailData?.withdraw_fee}%
                                </div>
                              </div>
                              <div className="payment-detail">
                                <div className="text-wrapper-2">
                                  Requested Amount
                                </div>
                                <div className="text-wrapper-3">
                                  ${numberWithCommas(claimAmount)}
                                </div>
                              </div>
                            </div>
                            <img
                              className="line-4"
                              alt="Line"
                              src={dottedLine}
                            />
                            <div className="div-2">
                              <div className="payment-detail">
                                <div className="item-with-tooltip">
                                  <div className="text-wrapper-2">
                                    {dictionaryModal['MODAL-title66']}:
                                  </div>
                                  <div
                                    className="tooltip-info tooltip-info-normal tooltip-info-normal-inside"
                                    style={{ right: 0, top: 4 }}
                                  >
                                    <AiOutlineInfoCircle className="tooltip-info-icon" />
                                    <div
                                      className="tooltip-info-text"
                                      style={{ left: 0 }}
                                    >
                                      <span>
                                        {dictionaryModal['MODAL-title65']}
                                      </span>
                                    </div>
                                  </div>
                                </div>
                                <div className="text-wrapper-3">
                                  $
                                  {numberWithCommas(
                                    claimAmount *
                                      (islandsDetailData?.withdraw_fee / 100)
                                  )}
                                </div>
                              </div>
                              <div className="payment-detail">
                                <div className="text-wrapper-2">
                                  Received Amount
                                </div>
                                <div className="text-wrapper-3">
                                  $
                                  {numberWithCommas(
                                    Number(claimAmount) -
                                      claimAmount *
                                        (islandsDetailData?.withdraw_fee / 100)
                                  )}
                                </div>
                              </div>
                              <div className="payment-detail">
                                <p className="text-wrapper-2">
                                  Your BOS Balance will be
                                </p>
                                <div className="text-wrapper-3">
                                  $
                                  {numberWithCommas(
                                    Number(userInfo?.total_balance) +
                                      (Number(claimAmount) -
                                        claimAmount *
                                          (islandsDetailData?.withdraw_fee /
                                            100))
                                  )}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="frame-2">
                        <input
                          className="checkbox-size"
                          type="checkbox"
                          id="horns"
                          name="horns"
                          onChange={(e) => setTermsCheck(e.target.checked)}
                        />
                        <label htmlFor="horns">
                          <p className="p">
                            I have reviewed and agree to the{' '}
                            <Link
                              to={`/island-page?id=${currentIslandId}&tab=conditions`}
                              target="_blank"
                            >
                              Island Conditions.
                            </Link>
                          </p>
                        </label>
                      </div>
                      <div className="overlap-wrapper">
                        <Flex direction="col" h="unset">
                          <div
                            className="overlap-2 pointer"
                            style={
                              isTermsCheck
                                ? {}
                                : { opacity: 0.3, cursor: 'not-allowed' }
                            }
                            onClick={() => {
                              if (isTermsCheck) {
                                if (islandsDetailData) {
                                  if (claimAmount > 0) {
                                    if (
                                      claimAmount >=
                                      Number(
                                        islandsDetailData?.minimum_withdraw
                                      )
                                    ) {
                                      islandClaimWindow?.island_type === 3
                                        ? claimHandle(
                                            claimAmount,
                                            currentIslandId
                                          )
                                        : claimHandleForType2(
                                            claimAmount,
                                            currentIslandId
                                          );
                                    } else {
                                      toast.error(
                                        `${dictionaryModal['MODAL-title67']} $${islandsDetailData?.minimum_withdraw}`
                                      );
                                    }
                                  } else {
                                    toast.error(dictionary['TREASURE-title32']);
                                  }
                                }
                              }
                            }}
                          >
                            <div className="text-wrapper-13">Submit</div>
                          </div>
                        </Flex>
                      </div>
                    </div>
                    <img
                      className="line-2"
                      alt="Line"
                      src="https://anima-uploads.s3.amazonaws.com/projects/64b77eb848521524cc106dda/releases/64d7361b8264fdbeecae76b1/img/line-5@2x.png"
                    />
                  </div>
                </>
              )}
            </div>
            <div
              className="hr"
              style={{
                width:
                  screenWidth < 1037
                    ? '565px'
                    : screenWidth >= 1037
                    ? '1037px'
                    : undefined
              }}
            />
            <div
              className="header-2"
              style={{
                width:
                  screenWidth < 1037
                    ? '516px'
                    : screenWidth >= 1037
                    ? '993px'
                    : undefined
              }}
            >
              <div className="text-wrapper-14">Claim Window</div>
              <div className="close-btn" onClick={() => setModalClaim(false)}>
                <img
                  className="union"
                  alt="Union"
                  src={
                    (screenWidth >= 564 && screenWidth < 1037) ||
                    screenWidth < 564
                      ? 'https://anima-uploads.s3.amazonaws.com/projects/64b77eb848521524cc106dda/releases/64d7420f1ba4c21dede3e26a/img/union-1.svg'
                      : screenWidth >= 1037
                      ? 'https://anima-uploads.s3.amazonaws.com/projects/64b77eb848521524cc106dda/releases/64d7420f1ba4c21dede3e26a/img/union.svg'
                      : undefined
                  }
                />
              </div>
            </div>
          </div>
        </div>
      )}

      {modalClaim && claimSuccessData && (
        <SuccessModal
          requestedAmount={claimSuccessData?.requestedAmount}
          txID={claimSuccessData?.txID}
          method={
            islandClaimWindow?.island_type === 3
              ? ModalMethods.Claim1
              : ModalMethods.Claim2
          }
          time={claimSuccessData?.time}
          fee={claimSuccessData?.fee}
          totalClaim={claimSuccessData?.finalAmount}
          closeState={setModalClaim}
        />
      )}
    </>
  );
};
