import React, { useContext } from "react";
import DatePicker from "react-datepicker";
import WizardContext from "../../../../context/private/wizardContext";
import { Flex } from "../../../../styles/Styles";

export const calculateAge = (birthDate) => {
    const today = new Date();
    const birth = new Date(birthDate);
    let age = today.getFullYear() - birth.getFullYear();
    const monthDifference = today.getMonth() - birth.getMonth();

    if (monthDifference < 0 || (monthDifference === 0 && today.getDate() < birth.getDate())) {
        age--;
    }

    return age;
}

const DickPickerInput = () => {
    const { datePickerRegisterPending, setDatePickerRegisterPending, age, setAge } = useContext(WizardContext)



    return (
        <>
            <div className="w-full flex gap-2 justify-center items-center">
            <span className="date-picker-title">Date of birth</span>
                <DatePicker
                    className="appf-form-input"
                    selected={datePickerRegisterPending}
                    onChange={(date) => {
                        setDatePickerRegisterPending(date)
                        setAge(calculateAge(date))
                    }}
                    placeholder="Date of birth"
                    peekNextMonth
                    showMonthDropdown
                    showYearDropdown
                    dropdownMode="select"
                />
            </div>

            {
                age > -1 && age < 18 ? (
                    <p style={{ color: 'red', margin: '0 0 1rem 0' }}>
                        You must be at least 18 years old.
                    </p>
                ) : ''
            }
        </>
    );
};

export default DickPickerInput